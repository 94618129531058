import React from "react";

import EmptyData from "../../../StyledComponents/EmptyData";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  CardFooter,
  CardContent,
  CardHeader,
  CardView,
} from "../../../StyledComponents";
import getFilePath from "../../../../Config/path";
import { Div } from "../../Styles";
import {
  AvatarFrame,
  AvatarTxt,
  PaymentStatus,
  TxtSpan,
  StyledImage,
} from "../styles";
import moment from "moment";

const PaymentHistory = ({ PaymentLists }) => {
  const FileURLProfile = getFilePath();

  return (
    <Div>
      <Table>
        <TableHead>
          <TableRow background="#F7F7F7">
            <TableHeadCell>Process Date</TableHeadCell>
            <TableHeadCell>Amount</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {PaymentLists.length !== 0 ? (
            <>
              {PaymentLists.map((row, ind) => (
                <TableRow key={ind}>
                  <TableBodyCell bodered>
                    <TxtSpan fontWeight="500">
                      {row.PaymentDate
                        ? moment(row.PaymentDate).format("MM/DD/YYYY")
                        : ""}
                    </TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell bodered>
                    <TxtSpan color="#407BFF">{`$ ${parseInt(
                      row.pay_total
                    )}`}</TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell bodered>
                    <PaymentStatus>{`Paid`}</PaymentStatus>
                  </TableBodyCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableBodyCell colSpan={3} textAlign="center">
                <EmptyData />
              </TableBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* Card View (Mobile) */}

      {PaymentLists.length > 0 ? (
        PaymentLists.map((row, ind) => (
          <CardView key={ind}>
            <CardHeader>{row.vendor}</CardHeader>
            <CardContent>
              <Div display="flex" justifyContent="space-between">
                <Div display="flex">
                  <Div display="flex" alignItems="center">
                    <AvatarFrame key={ind}>
                      {row.img_url ? (
                        <StyledImage
                          src={`${FileURLProfile}${row.img_url}`}
                          alt={`Avatar ${ind}`}
                          width="40px"
                          height="40px"
                          borderRadius="10px"
                        />
                      ) : (
                        <AvatarTxt>
                          {row.description
                            ? row.description.charAt(0).toUpperCase()
                            : ""}
                        </AvatarTxt>
                      )}
                    </AvatarFrame>
                  </Div>

                  <Div>
                    <TxtSpan fontWeight="600">{row.description}</TxtSpan>

                    <Div marginTop="5px">
                      <TxtSpan fontWeight="500">
                        {row.PaymentDate
                          ? moment(row.PaymentDate).format("MM/DD/YYYY")
                          : ""}
                      </TxtSpan>
                    </Div>
                  </Div>
                </Div>
                <Div className="mobPayHisRight">
                  <TxtSpan>
                    <TxtSpan color="#407BFF">{`$ ${parseInt(
                      row.pay_total
                    )}`}</TxtSpan>
                  </TxtSpan>
                  <Div marginTop="5px">
                    {" "}
                    <PaymentStatus className="mobPayHisFont">{`Paid`}</PaymentStatus>
                  </Div>
                </Div>
              </Div>
              <Div></Div>
            </CardContent>
          </CardView>
        ))
      ) : window.innerWidth < 800 ? (
        <EmptyData isMob={true} />
      ) : null}
    </Div>
  );
};

export default PaymentHistory;
