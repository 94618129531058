import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetWelcome } from "../../../StateManagement/Reducers/DashboardState";
import SignUp from "../../../Assets/images/SignUp.png";
import {
  Div,
  Image,
  Button,
  Text,
  ModalContainer,
  StyledModal,
  ModalDialog,
} from "../../StyledComponents";

const Welcome = (props) => {
  const dispatch = useDispatch();
  const PatName = useSelector((state) => state.TopNavbar.userDetails.patname);
  const [ModalAnimat] = useState(true);
  // const Welcomepopup = useSelector((state) => state.Dashboard.Welcome);

  useEffect(() => {
    dispatch(GetWelcome());
  }, []);
  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.show]);
  return (
    <StyledModal show={props.show}>
      <ModalContainer justifyContent="center">
        <ModalDialog animation={ModalAnimat}>
          <Div textAlign="center" justifyContent="center">
            <Image src={SignUp} height="185px" width="200px" />
            <Text
              fontSize="32px"
              fontWeight="600"
              color="#2C7BE5"
              fontFamily="Inter"
              lineHeight="unset"
              mt="16px"
            >
              Welcome
            </Text>
            <Text
              fontSize="16px"
              fontWeight="600"
              color="#2D3748"
              fontFamily="Inter"
              textAlign="centre"
              mt="8px"
              whiteSpace="normal"
              wordBreak="break-word"
            >
              Welcome to Patient Portal, {PatName}!
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#2D3748"
              fontFamily="Inter"
              textAlign="center"
              lineHeight="1.5"
              mt="8px"
              whiteSpace="normal"
              wordBreak="break-word"
            >
              {/* {Welcomepopup}  */}
              Your portal offers secure health records, appointment scheduling,
              and healthcare tools. Explore now!
            </Text>

            <Button
              background="#407BFF"
              borderRadius="3px"
              padding="8px 38px 8px 38px"
              fontSize="14px"
              fontWeight="400"
              lineHeight="16px"
              fontFamily="Inter"
              marginTop="24px"
              marginBottom="16px"
              onClick={props.ModalHide}
            >
              Continue
            </Button>
          </Div>
        </ModalDialog>
      </ModalContainer>
    </StyledModal>
  );
};

export default Welcome;
