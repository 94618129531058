import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Div, Form, Text } from "../../StyledComponents";
import { Ptag, Label, InputBox, Break } from "./styles";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import TopNav from "../../TopNav";
import {
  GetCheckPortalPassword,
  GetResetPassword,
  SetResetPassword,
} from "../../../StateManagement/Reducers/SettingState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import Error from "../../StyledComponents/Toastify/Error";
import Success from "../../StyledComponents/Toastify/Success";
const ChangePassword = () => {
  // ------------------------------ State and Variables Start---------------------------------------------------  //

  const [curPass, setCurPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isConfirmPass, setIsConfirmPass] = useState(true);
  const [isNewPass, setIsNewPass] = useState(true);
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState({
    misPassErr: false,
    samePassErr: false,
    prevPassErr: false,
    newPassEmp: false,
    confirmPassEmp: false,
    curPassEmp: false,
    regexNewPassErr: false,
  });

  const dispatch = useDispatch();
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-\=[\]{};':"|,.<>/?]).{8,}$/;
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  const CheckPassword = useSelector(
    (state) => state.Setting.CheckPortalPassword
  );
  const ResetPassword = useSelector((state) => state.Setting.ResetPassword);
  // ------------------------------ State and Variables End---------------------------------------------------  //

  //-------------------------------- Function Start ------------------------------------------------------//

  const handleCurPass = (e) => {
    setCurPass(e.target.value);
    setErr((prevErr) => ({
      ...prevErr,
      prevPassErr: false,
      samePassErr: false,
      curPassEmp: false,
    }));
  };
  const handleNewPass = (e) => {
    setErr((prevErr) => ({
      ...prevErr,
      prevPassErr: false,
      newPassEmp: false,
      regexNewPassErr: false,
    }));
    setNewPass(e.target.value);
    dispatch(SetResetPassword(""));
  };

  const handleConfirmPass = (e) => {
    setErr((prevErr) => ({
      ...prevErr,
      confirmPassEmp: false,
      misPassErr: false,
    }));

    setConfirmPass(e.target.value);
    dispatch(SetResetPassword(""));
  };

  const handleIsNewPass = (e) => {
    setIsNewPass(false);
  };

  const handleIsConfirmPass = (e) => {
    setIsConfirmPass(false);
  };

  const handleIsNewPass1 = (e) => {
    setIsNewPass(true);
  };

  const handleIsConfirmPass1 = (e) => {
    setIsConfirmPass(true);
  };

  const iconStyle = {
    position: "relative",
    top: "4px",
    cursor: "pointer",
    right: "30",
  };
  const handleResetPass = (e) => {
    e.preventDefault();
    if (curPass === "") {
      setErr((prevErr) => ({
        ...prevErr,
        curPassEmp: true,
      }));
      toast(<Error msg="Please Enter Current Password" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    } else if (CheckPassword === "Both Passwords are not same") {
      setErr((prevErr) => ({
        ...prevErr,
        samePassErr: true,
      }));
      toast(
        <Error msg="Current password should be same as Existing Password" />,
        {
          containerId: "B",
          className: "error_badge",
        }
      );
      return false;
    } else if (newPass === "") {
      setErr((prevErr) => ({
        ...prevErr,
        newPassEmp: true,
      }));
      toast(<Error msg="Please Enter New Password" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    } else if (!regex.test(newPass)) {
      setErr((prevErr) => ({
        ...prevErr,
        regexNewPassErr: true,
      }));
      toast(<Error msg="Please Enter Valid New Password" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    } else if (
      ResetPassword === "The password you entered must not be an old password"
    ) {
      setErr((prevErr) => ({
        ...prevErr,
        prevPassErr: true,
      }));
      toast(
        <Error msg="The password you entered must not be an old password" />,
        {
          containerId: "B",
          className: "error_badge",
        }
      );
      return false;
    } else if (confirmPass === "") {
      setErr((prevErr) => ({
        ...prevErr,
        confirmPassEmp: true,
      }));
      toast(<Error msg="Please Enter Confirm Password" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    } else if (newPass !== confirmPass) {
      setErr((prevErr) => ({
        ...prevErr,
        misPassErr: true,
      }));
      toast(<Error msg="New Password and Confirm Password should be same" />, {
        containerId: "B",
        className: "error_badge",
      });
      return false;
    }

    const article = {
      pid: Pat_ID,
      curPass: curPass,
      newPass: newPass,
      confirmPass: confirmPass,
    };
    dispatch(GetResetPassword(article));
  };
  //-----------------------------Function End-------------------------------------------------//

  //-----------------------------UseEffect Start----------------------------------------------//
  useEffect(() => {
    if (curPass) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(GetCheckPortalPassword({ pid: Pat_ID, curPass: curPass }));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [curPass]);

  useEffect(() => {
    if (ResetPassword === "Success") {
      toast(<Success msg="Password Changed Successfully" />, {
        containerId: "A",
      });
      setCurPass("");
      setNewPass("");
      setConfirmPass("");
      setTimeout(() => {
        dispatch(SetResetPassword(""));
      }, 1500);
    } else if (
      ResetPassword === "The password you entered must not be an old password"
    ) {
      setErr((prevErr) => ({
        ...prevErr,
        prevPassErr: true,
      }));
      toast(
        <Error msg="The password you entered must not be an old password" />,
        {
          containerId: "B",
          className: "error_badge",
        }
      );
      setTimeout(() => {
        dispatch(SetResetPassword(""));
      }, 1500);
    }
  }, [ResetPassword]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  //-----------------------------UseEffect End----------------------------------------------//

  return (
    <>
      <TopNav title="Change Password" />
      <Div
        marginLeft="40px"
        marginTop={open ? "40px" : "64px"}
        className="changepassword_container_responsive"
      >
        <Form onSubmit={handleResetPass}>
          <Div marginBottom="16px">
            <Label>Current Password</Label>
            <Break />

            <InputBox
              type="password"
              autoComplete="off"
              paddingRight="10px"
              value={curPass}
              onChange={handleCurPass}
              style={
                err.samePassErr || err.curPassEmp || err.prevPassErr
                  ? { border: "1px solid red" }
                  : {}
              }
            />
          </Div>
          <Div marginBottom="16px" position="relative">
            <Label>New Password</Label> <Break />
            <InputBox
              paddingRight="35px"
              autoComplete="off"
              type={isNewPass ? "password" : "text"}
              value={newPass}
              onChange={handleNewPass}
              style={
                err.prevPassErr || err.newPassEmp || err.regexNewPassErr
                  ? { border: "1px solid red" }
                  : {}
              }
            />
            {isNewPass ? (
              <FaRegEyeSlash style={iconStyle} onClick={handleIsNewPass} />
            ) : (
              <FaRegEye style={iconStyle} onClick={handleIsNewPass1} />
            )}
            <Break />
            <Text
              className="changepassword_width"
              fontSize="10px"
              textAlign="left"
              width="320px"
            >
              The password must be at least 8 characters long and contain at
              least one number, one uppercase letter, one lowercase letter, and
              one special character
            </Text>
          </Div>
          <Div marginBottom="24px" position="relative">
            <Label>Confirm Password</Label> <Break />
            <InputBox
              autoComplete="off"
              paddingRight="35px"
              type={isConfirmPass ? "password" : "text"}
              value={confirmPass}
              onChange={handleConfirmPass}
              style={
                err.misPassErr || err.confirmPassEmp
                  ? { border: "1px solid red" }
                  : {}
              }
            />
            {isConfirmPass ? (
              <FaRegEyeSlash style={iconStyle} onClick={handleIsConfirmPass} />
            ) : (
              <FaRegEye style={iconStyle} onClick={handleIsConfirmPass1} />
            )}
            <Break />
            <Text
              fontSize="10px"
              textAlign="left"
              className="changepassword_width"
            >
              Both password must match
            </Text>
          </Div>
          <Button
            padding="10px 112px 10px 112px"
            height="42px"
            background="#407BFF"
            color="#FFF"
            className="change_password_button_responsive"
          >
            <Ptag>Reset Password</Ptag>
          </Button>
        </Form>
      </Div>
    </>
  );
};

export default ChangePassword;
