import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  changeAppointmentData,
  GetUpcomingAppointment,
  GetPastAppt,
  getProviderAvailablity,
} from "../../StateManagement/Reducers/AppointmentsState";

import PastAppointment from "./PastAppointment";
import TopNav from "../TopNav";
import AddAppointment from "./AddAppointment";
import UpcomingAppointments from "./UpcomingAppointments";
import MessageModal from "./AddAppointment/MessageModal";
import Loading from "../StyledComponents/Loading";
import { RespSubTitle } from "./UpcomingAppointments/styles";

import { Div, BodyContainer, PastApptBodycont } from "../StyledComponents";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

const Appointment = () => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");
  const appointmentData = useSelector(
    (state) => state.Appointments.appointmentData
  );
  const addLoader = useSelector((state) => state.Appointments.loadingSave);
  const upLoader = useSelector((state) => state.Appointments.loadCounter);
  const pastLoader = useSelector((state) => state.Appointments.pastApptLoading);
  const refresh = useSelector((state) => state.Dashboard.RefreshAppt);
  const [mobileview, setmobileview] = useState(false);

  // ------------------------------ State and Variables End--------------------------------------------------- //

  useEffect(() => {
    dispatch(GetUpcomingAppointment("show"));
  }, [refresh, params.selector]);

  useEffect(() => {
    dispatch(GetPastAppt());
  }, [params.selector]);

  useEffect(() => {
    dispatch(
      getProviderAvailablity({
        provider_id: appointmentData["provider"],
        facility_id: appointmentData["office"],
        date: appointmentData["aptdate"],
        pid: Pat_ID,
      })
    );
  }, [
    dispatch,
    appointmentData["provider"],
    appointmentData["aptdate"],
    appointmentData["office"],
  ]);
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;

      const isMobile = currentWidth <= 800;

      isMobile ? setmobileview(true) : setmobileview(false);
    };
    // Initialize on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <TopNav
        title="Appointment List"
        TitleDescription="Manage Your Appointment"
      />
      {addLoader || upLoader || pastLoader ? (
        <>
          <Loading />
        </>
      ) : (
        <PastApptBodycont
          marginTop={open ? "0px" : "79px"}
          display="flex"
          height="calc(100% - 111px)"
        >
          {!mobileview ? (
            <>
              <Div width="calc(37% + 1px)" height="100%">
                <UpcomingAppointments />
              </Div>

              <Div height="100%" width="63%">
                {params.selector === "add_appointment" ? (
                  <AddAppointment />
                ) : (
                  <PastAppointment />
                )}
              </Div>
            </>
          ) : (
            <>
              <PastApptBodycont className="Appoinmentlist_responsive">
                <Div height="100%" className="Appoinment_container_responsive">
                  <RespSubTitle
                    className="app_list_responsive"
                    fontWeight="500"
                    fontSize="20px"
                    lineHeight="25px"
                    color="#000000"
                    marginLeft="16px"
                  >
                    Appointment List
                  </RespSubTitle>

                  <Div width="100%">
                    <UpcomingAppointments />
                    {params.selector === "add_appointment" && (
                      <AddAppointment mobileview={mobileview} />
                    )}
                  </Div>
                </Div>
                <Div height="100%" width="100%">
                  <PastAppointment mobileview={mobileview} />
                </Div>
              </PastApptBodycont>
            </>
          )}
        </PastApptBodycont>
      )}

      <MessageModal
        active={appointmentData["showMessage"]}
        color={appointmentData["messageType"]}
        onHide={() =>
          dispatch(changeAppointmentData({ name: "showMessage", value: false }))
        }
      />
    </>
  );
};

export default Appointment;
