import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { getCredentials } from "../../../StateManagement/Reducers/LoginState";
import { NormalImage, Div, SpanCont } from "../../StyledComponents";
import { Container } from "../StyledComponents";
import getFilePath from "../../../Config/path";

import {
  TitleBlock,
  Title,
  SubTitle,
  ClientButton,
  Status,
  GoogleContainer,
} from "./Styles";
import { jwtDecode } from "jwt-decode";
import Login from "../../../Assets/images/login.png";
import Sign from "../../../Assets/images/SignUp.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import Error from "../../../Modules/StyledComponents/Toastify/Error";
import { ImageContainer, Image } from "../StyledComponents";
import vozoLogo from "../../../Assets/images/Logo.png";
import { useNavigate } from "react-router-dom";

const WelcomeRight = () => {
  const [open, setOpen] = useState(false);
  const [openwidth, setOpenwidth] = useState(false);

  const dispatch = useDispatch();
  const text = useSelector((state) => state.PreLogin.logo);
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const navigate = useNavigate();
  const File_URL = getFilePath();

  const handleMode = (a) => {
    localStorage.removeItem("Mode");
    if (a === "1") {
      let mode = "new";
      dispatch(setMode("new"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    } else {
      let mode = "old";
      dispatch(setMode("old"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };
  const handleSuccess = (response) => {
    // Decode the ID token
    const userObject = jwtDecode(response.credential);
    dispatch(
      getCredentials({
        UserName: userObject.email,
        Password: "",
        source: "googlelogin",
        firstName: userObject.given_name,
        lastName: userObject.family_name ? userObject.family_name : "",
      })
    );
  };

  const handleError = () => {
    toast(<Error msg="Password is incorrect!." />, {
      containerId: "B",
      className: "error_badge",
    });
  };
  useEffect(() => {
    if (LoginResponse.status === "LOGIN_SUCCESS") {
      navigate("/login");
    } else if (LoginResponse.status === "Signup failed") {
      toast(<Error msg="Signup failed!." />, {
        containerId: "B",
        className: "error_badge",
      });
    }
  }, [LoginResponse]);
  useEffect(() => {
    const handleMediaChange = () => {
      const width = window.innerWidth;

      if (width < 500) {
        setOpen(false);
        setOpenwidth(false);
      } else if (width >= 500 && width <= 800) {
        setOpen(false);
        setOpenwidth(true);
      } else {
        setOpen(true);
        setOpenwidth(true);
      }
    };
    window.addEventListener("resize", handleMediaChange);

    handleMediaChange();
    return () => {
      window.removeEventListener("resize", handleMediaChange);
    };
  }, []);
  return (
    <Container width={open ? "100%" : "100%"}>
      <Div
        width={openwidth ? "80%" : open ? "50%" : "83%"}
        marginTop={open ? "16%" : "5%"}
        margin="auto"
        textAlign="center"
      >
        {open ? (
          <>
            {text.length !== 0 && open && (
              <ImageContainer marginTop="72px" marginBottom="20px">
                <Image
                  loading="lazy"
                  src={text !== "failed" ? File_URL + text : vozoLogo}
                  height="41px"
                  width="173px"
                  objectFit="contain"
                />
              </ImageContainer>
            )}
          </>
        ) : (
          ""
        )}
        <TitleBlock>
          {open ? (
            <>
              <Title fontFamily="'DM Sans', sans-serif" color="#245C66">
                Welcome back!&nbsp;
              </Title>
              <Title fontFamily="'DM Sans', sans-serif" color="#000000">
                Nice to see you again
              </Title>
            </>
          ) : (
            <Div>
              <Title
                fontFamily="'DM Sans', sans-serif"
                color="#245C66"
                fontSize="24px !important"
              >
                Welcome back!&nbsp;
              </Title>
              <Title
                fontFamily="'DM Sans', sans-serif"
                color="#000000"
                fontSize="24px !important"
              >
                Nice to see you again
              </Title>
            </Div>
          )}
        </TitleBlock>
        {open ? (
          <SubTitle fontSize="20px" fontFamily="'DM Sans', sans-serif">
            Use the email your practitioner has on file to sign in—no password
            necessary.
          </SubTitle>
        ) : (
          <SubTitle
            fontSize="14px"
            marginTop="0px"
            textAlign="center"
            fontFamily="'DM Sans', sans-serif"
          >
            Use the email your practitioner has on file to sign in—no password
            necessary.
          </SubTitle>
        )}
        <Div
          display="flex"
          justifyContent={open ? "center" : "center"}
          marginTop="10px"
        >
          <ClientButton
            hoverborder="1px solid #208241"
            marginright={open ? "40px" : "24px"}
            width={open ? "200px" : "146px"}
            height={open ? "200px" : "146px"}
            to="/InstantSignIn/New"
            exact="true"
            onClick={() => handleMode("1")}
          >
            <NormalImage
              loading="lazy"
              src={Sign}
              alt="Sign-Up Image"
              width={open ? "186px" : "146px"}
              height={open ? "138px" : "100px"}
            ></NormalImage>
            <Status
              fontFamily="'DM Sans', sans-serif"
              fontSize={open ? "18px" : "13px"}
            >
              I’M A NEW CLIENT
            </Status>
          </ClientButton>
          <ClientButton
            hoverborder="1px solid #208241"
            width={open ? "200px" : "146px"}
            height={open ? "200px" : "146px"}
            to="/InstantSignIn/Old"
            exact="true"
            onClick={() => handleMode("2")}
          >
            <NormalImage
              loading="lazy"
              src={Login}
              alt="Login-Image"
              width={open ? "186px" : "146px"}
              height={open ? "138px" : "100px"}
              id="wel_img-ext"
            />
            <Status
              fontFamily="'DM Sans', sans-serif"
              fontSize={open ? "18px" : "13px"}
            >
              EXISTING CLIENT
            </Status>
          </ClientButton>
        </Div>
        <Div marginTop="10px" marginBottom="10px">
          or
        </Div>
        <GoogleContainer>
          <GoogleLogin
            theme="filled_blue" // Change theme to "filled_blue", "outline", etc.
            text="signin_with"
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </GoogleContainer>
      </Div>
    </Container>
  );
};

export default WelcomeRight;
