import styled from "styled-components";

export const ModalDialog = styled.div`
  padding: 32px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  width: 414px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
  &.document_popup {
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;

export const Childdiv = styled.div`
  height: 100%;
  width: 80%;
  boxshadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  text-align: center;
  display: flex;
`;

export const DragDrop = styled.div`
  background: #fafafa;
  border: 1px dashed #e5e5e5;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
`;

export const FileInput = styled.input``;

export const Parentdiv = styled.div`
  height: 48px;
  width: 100%;
  background: rgba(193, 214, 255, 0.3);
  boxshadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ProgressLabel = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: ${({ file }) => (!file ? `#fafafa` : `#407BFF`)};
  /* margin-top: 15px; */
  display: flex;
  text-align: center;
`;
export const Docdiv = styled.div`
  height: 48px;
  width: 100%;
  background: #faebd7;
  boxshadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
