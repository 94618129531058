import styled from "styled-components";

export const CancelApptInput = styled.textarea`
  width: 327px;
  height: 91px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 12px !important;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #7e7c7c !important;
    border: none;
  }
  outline: 0px;
   &.appoinmentcancel_responsive{

    @media (max-width: 800px) {
    width: 100%;

    
    }

`;

export const ModalDialog = styled.div`
  width: ${(p) => (p.width ? p.width : "544px")};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animattion ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
  &.appoinmentcancel_responsive {
    padding: 20px;
    width: 367px;
    @media (max-width: 800px) {
      width: 80%;
    }
  }
`;
