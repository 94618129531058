import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetPortalEnable } from "../../../StateManagement/Reducers/PreLoginState";
import WelcomeRight from "../WelcomeRight";
import AccessDenied from "../AccessDenied";
import { Div } from "../Styles";
import { Container } from "../StyledComponents";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";
import { ImageContainer, Image } from "../StyledComponents";
import vozoLogo from "../../../Assets/images/Vozo_Icon.png";
import getFilePath from "../../../Config/path";

const Welcome = () => {
  const dispatch = useDispatch();
  const File_URL = getFilePath();

  const [open, setOpen] = useState(false);

  const PortalEnable = useSelector((state) => state.PreLogin.PortalEnable);
  const text = useSelector((state) => state.PreLogin.logo);
  useEffect(() => {
    dispatch(GetPortalEnable());
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height="100vh"
      minWidth={open ? "1024px" : "350px"}
    >
      {PortalEnable === 1 ? (
        <>
          {open ? (
            <>
              <Div width="50%" bg_color="#F8FAFB" position="relative">
                <img
                  src={patient_portal_front}
                  alt="menu-icon"
                  left="20px"
                  height="100%"
                  width="100%"
                ></img>
              </Div>
              <Div width="50%" bg_color="#FFFFFF">
                <WelcomeRight />
              </Div>
            </>
          ) : (
            <Div marginTop="7%" height={open ? "" : "175vh"}>
              {text.length !== 0 && (
                <ImageContainer marginTop="20px" marginBottom="20px">
                  <Image
                    loading="lazy"
                    src={text !== "failed" ? File_URL + text : vozoLogo}
                    height="41px"
                    width="173px"
                    objectFit="contain"
                  />
                </ImageContainer>
              )}
              <Div bg_color="#F8FAFB" position="relative">
                <img
                  src={patient_portal_front}
                  alt="menu-icon"
                  top="20px"
                  left="20px"
                  height="100%"
                  width="100%"
                ></img>
              </Div>
              <Div marginTop="30px" bg_color="#FFFFFF">
                <WelcomeRight open={open} setOpen={setOpen} />
              </Div>
            </Div>
          )}
        </>
      ) : (
        PortalEnable === 0 && <AccessDenied />
      )}
    </Container>
  );
};

export default Welcome;
