import styled from "styled-components";

export const ModalDialog = styled.div`
  width: 416px;
  background: #ffffff;
  position: relative;
  height: 100%;
  animation: ${(p) =>
    p.animation ? "show 1.5s ease-in-out" : "show_2 1.5s ease-in-out"};
  @keyframes show {
    from {
      transform: translateX(450px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(450px);
    }
  }
`;

export const CloseButton = styled.div`
  background: #f4f9fd;
  border-radius: 4px;
  padding: 8px 6px 8px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
`;

export const CardBorder = styled.div`
  padding: 16px;
  border: 1px solid #d8e0f0;
  border-radius: 8px;
`;

export const CardTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
`;

export const EditCard = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #407bff;
  cursor: pointer;
`;
export const RemoveCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 4px 0px;
  margin: 25px -16px 0px -16px;
  border-top: 1px solid #d8e0f0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.8px;
  color: #f65160;
  cursor: pointer;
`;

export const Input = styled.input`
  background: ${(p) => (p.background ? p.background : "#ffffff")};
  border: ${(p) => (p.error ? "1px solid #dc3545" : "1px solid #dbdbdb")};
  border-radius: 6px;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding: 8px 0px 8px 15px;
  width: 134%;
  &:hover {
    cursor: ${(p) => p.cursor && p.cursor};
  }
  &:focus {
    outline: none;
  }
  &.editcard_input_responsivness {
    @media (max-width: 800px) {
      width: 110%;
    }
  }
`;

export const Span = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2d3748;
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
  // margin-left: 20px;
`;
