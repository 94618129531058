import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetSignUp } from "../../../StateManagement/Reducers/PreLoginState";
import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { LinK } from "../../StyledComponents";
import { Span } from "../StyledComponents";
import vozoLogo from "../../../Assets/images/Logo.png";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
} from "../../StyledComponents";
import { Container, Input, ImageContainer } from "../StyledComponents";
import { Title, SubTitle, FormGroupInline } from "./Styles";
import getFilePath from "../../../Config/path";

const SignUpRight = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [fnErr, setfnErr] = useState(false);
  const [lnErr, setLnErr] = useState(false);
  const [p1Err, setP1Err] = useState(false);
  const [p2Err, setP2Err] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [email] = useState(state ? state.type.email : "");
  const [Password, setPassword] = useState("");
  const [Password1, setPassword1] = useState("");
  const [open, setOpen] = useState(false);

  const File_URL = getFilePath();

  const Signup = useSelector((state) => state.PreLogin.signup);
  const text = useSelector((state) => state.PreLogin.logo);

  const EmailValidation = (e) => {
    setPassword(e.target.value);
    setP2Err(false);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP1Err(false);
    } else {
      setP1Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformEmail = (e) => {
    setPassword1(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP2Err(false);
    } else {
      setP2Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const handleMode = (a) => {
    if (a === "2") {
      dispatch(setMode("old"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  const handleCreate = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    var err = 0;
    if (Password === "") {
      setP1Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
      err = 2;
    }
    if (!strongRegex.test(Password)) {
      setP2Err(true);
      err = 2;
    }
    if (!strongRegex.test(Password1)) {
      setP2Err(true);
      err = 2;
    }
    if (firstName === "") {
      setfnErr(true);
      err = 2;
    }
    if (lastName === "") {
      setLnErr(true);
      err = 2;
    }
    if (email === "") {
      return false;
    }
    if (Password === "") {
      setP1Err(true);
      err = 2;
    }
    if (Password1 === "") {
      setP2Err(true);
      err = 2;
    }
    if (Password !== Password1) {
      setErrMsg("PassWord Mismatch");
      setP2Err(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      dispatch(
        GetSignUp({
          fname: firstName,
          lname: lastName,
          email: email,
          new_password: Password,
          con_password: Password1,
        })
      );
    }
  };

  useEffect(() => {
    if (Signup === "Signup_success") {
      setErr(false);
      Navigate("/Login");
    } else {
      if (Signup.length !== 0) {
        setErr(true);
        setErrMsg("signup-failed");
      }
    }
  }, [Signup]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container>
      {open ? (
        <Div width="500px" margin="auto" resWidth="420px">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41"
                width="173px"
              />
            </ImageContainer>
          )}

          <Title marginTop="10%">Create new account</Title>
          <SubTitle>Welcome back! Nice to see you again</SubTitle>

          <Form>
            <FormGroupInline>
              <FormGroup marginBottom="24px" display="grid">
                <FormLabel
                  marginBottom="8px"
                  color="#151515"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  First Name
                </FormLabel>
                <Input
                  fontFamily="'DM Sans', sans-serif"
                  borderRadius="100px"
                  placeholder="Enter Name"
                  value={firstName}
                  type="text"
                  color="#7D8592"
                  onChange={(e) => {
                    if (isNaN(e.target.value) || e.target.value === "") {
                      setfnErr(false);
                      setFirstname(e.target.value);
                    }
                  }}
                />
                {fnErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    First Name cannot be Empty
                  </FormLabel>
                )}
                {lnErr && <>&nbsp;</>}
              </FormGroup>
              <FormGroup marginBottom="24px" display="grid">
                <FormLabel
                  marginBottom="8px"
                  color="#151515"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Last Name
                </FormLabel>
                <Input
                  fontFamily="'DM Sans', sans-serif"
                  borderRadius="100px"
                  placeholder="Last Name"
                  value={lastName}
                  color="#7D8592"
                  type="text"
                  onChange={(e) => {
                    if (isNaN(e.target.value) || e.target.value === "") {
                      setLastname(e.target.value);
                      setLnErr(false);
                    }
                  }}
                />
                {lnErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    Last Name cannot be Empty
                  </FormLabel>
                )}
                {fnErr && <>&nbsp;</>}
              </FormGroup>
            </FormGroupInline>

            <FormGroup marginBottom="24px" display="grid" width="100%">
              <FormLabel
                marginBottom="8px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Email address
              </FormLabel>
              <Input
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
                placeholder="Enter email"
                disabled
                value={email}
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
                color="#667085"
                textAlign="left"
                padding="0px 10px 0px 40px"
              />
              <FaRegUser
                style={{
                  position: "absolute",
                  left: 15,
                  top: "45px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
              />
            </FormGroup>
            <FormGroup marginBottom="32px" display="grid" width="100%">
              <FormLabel
                marginBottom="8px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Password
              </FormLabel>
              <Input
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
                placeholder="Enter password"
                type={PasswordShow ? "password" : "text"}
                value={Password}
                color="#7D8592"
                onChange={(e) => EmailValidation(e)}
                autoComplete="off"
              />
              {PasswordShow ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              )}
              {p1Err && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>
            <FormGroup marginBottom="32px" display="grid" width="100%">
              <FormLabel
                marginBottom="8px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Confirm Password
              </FormLabel>
              <Input
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
                placeholder="Re-Enter password"
                type={PasswordShow1 ? "password" : "text"}
                value={Password1}
                color="#7D8592"
                onChange={(e) => ConformEmail(e)}
                autoComplete="off"
              />
              {PasswordShow1 ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              )}
              {p2Err && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>

            <FormGroup marginBottom="32px" display="grid" width="100%">
              <Button
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
                background="#245C66"
                hoverBackColor="#16424A"
                padding="18px 140px 18px 140px"
                fontWeight="600"
                fontSize="16px"
                lineHeight="22px"
                letterSpacing="1.1px"
                disabled={btndis}
                onClick={(e) => handleCreate(e)}
              >
                Create Account
              </Button>
            </FormGroup>
            {err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
            <Span
              fontFamily="'DM Sans', sans-serif"
              marginTop="32px"
              color="#667085"
              fontSize="18px"
              lineHeight="26px"
              textAlign="center"
            >
              Do you have an account?
              <LinK
                to="/InstantSignIn/Old"
                fontSize="18px"
                lineheight="26px"
                fontFamily="'DM Sans', sans-serif"
                onClick={() => handleMode("2")}
              >
                Log in
              </LinK>
            </Span>
          </Form>
        </Div>
      ) : (
        <Div margin="auto" resWidth="100%" respadding="0 20px 0 20px">
          <Title marginTop="3%" fontSize="24px" marginBottom="3px">
            Create new account
          </Title>
          <SubTitle fontSize="14px" marginBottom="16px">
            Welcome back! Nice to see you again
          </SubTitle>

          <Form>
            <FormGroupInline>
              <FormGroup marginBottom="24px" display="grid">
                <FormLabel
                  marginBottom="8px"
                  color="#151515"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  First Name
                </FormLabel>
                <Input
                  fontFamily="'DM Sans', sans-serif"
                  borderRadius="100px"
                  height="40px"
                  placeholder="Enter Name"
                  value={firstName}
                  type="text"
                  color="#7D8592"
                  onChange={(e) => {
                    if (isNaN(e.target.value) || e.target.value === "") {
                      setfnErr(false);
                      setFirstname(e.target.value);
                    }
                  }}
                />
                {fnErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    First Name cannot be Empty
                  </FormLabel>
                )}
                {lnErr && <>&nbsp;</>}
              </FormGroup>
              <FormGroup marginBottom="24px" display="grid">
                <FormLabel
                  marginBottom="8px"
                  color="#151515"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Last Name
                </FormLabel>
                <Input
                  fontFamily="'DM Sans', sans-serif"
                  borderRadius="100px"
                  height="40px"
                  placeholder="Last Name"
                  value={lastName}
                  color="#7D8592"
                  type="text"
                  onChange={(e) => {
                    if (isNaN(e.target.value) || e.target.value === "") {
                      setLastname(e.target.value);
                      setLnErr(false);
                    }
                  }}
                />
                {lnErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    Last Name cannot be Empty
                  </FormLabel>
                )}
                {fnErr && <>&nbsp;</>}
              </FormGroup>
            </FormGroupInline>

            <FormGroup marginBottom="24px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Email address
              </FormLabel>
              <Input
                height="40px"
                placeholder="Enter email"
                disabled
                value={email}
                fontSize="14px"
                fontWeight="500"
                lineHeight="30px"
                color="#667085"
                textAlign="left"
                padding="0px 10px 0px 40px"
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
              />
              <FaRegUser
                style={{
                  position: "absolute",
                  left: 15,
                  top: "38px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
              />
            </FormGroup>
            <FormGroup marginBottom="16px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Password
              </FormLabel>
              <Input
                height="40px"
                placeholder="Enter password"
                type={PasswordShow ? "password" : "text"}
                value={Password}
                color="#7D8592"
                onChange={(e) => EmailValidation(e)}
                autoComplete="off"
                fontFamily="'DM Sans', sans-serif"
                borderRadius="100px"
              />
              {PasswordShow ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "40px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "40px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              )}
              {p1Err && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>
            <FormGroup marginBottom="16px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                color="#151515"
                fontWeight="400"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
                fontFamily="'DM Sans', sans-serif"
              >
                Confirm Password
              </FormLabel>
              <Input
                height="40px"
                borderRadius="100px"
                placeholder="Re-Enter password"
                type={PasswordShow1 ? "password" : "text"}
                value={Password1}
                color="#7D8592"
                onChange={(e) => ConformEmail(e)}
                autoComplete="off"
              />
              {PasswordShow1 ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "40px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "40px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              )}
              {p2Err && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>

            <FormGroup marginBottom="16px" display="grid" width="100%">
              <Button
                borderRadius="100px"
                background="#245C66"
                hoverBackColor="#16424A"
                inset
                // className="save_active"
                padding="9px 80px 9px 80px"
                fontWeight="400"
                fontSize="14px"
                lineHeight="22px"
                letterSpacing="1.1px"
                disabled={btndis}
                onClick={(e) => handleCreate(e)}
              >
                Create Account
              </Button>
            </FormGroup>
            {err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
            <Span
              marginTop="16px"
              color="#667085"
              fontSize="14px"
              lineHeight="26px"
              textAlign="center"
            >
              Do you have an account?
              <LinK
                to="/InstantSignIn/Old"
                fontSize="14px"
                lineheight="26px"
                fontFamily="'manrope'"
                onClick={() => handleMode("2")}
              >
                Log in
              </LinK>
            </Span>
          </Form>
        </Div>
      )}
    </Container>
  );
};

export default SignUpRight;
