import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getMessagerList,
  getMessagerSend,
  GetMsgList,
  getPreviewDownload,
} from "../../StateManagement/Reducers/MessengerState";
import { Encrypt_Value, Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";

import ChatBody from "./ChatBody";
import ProviderList from "./ProviderList";
import HeadNav from "./HeadNav";
import TopNav from "../TopNav";
import Loading from "../StyledComponents/Loading";

import SendIcon from "../../Assets/images/SendButton.png";
import DocType from "../../Assets/images/docType.png";
import { toast } from "react-toastify";
import {
  CloseIcon,
  FlexBetween,
  Div,
  Text,
  Description,
  Input,
} from "../StyledComponents";
import { Images } from "./StyledComponents";
import {
  AtSymbol,
  Attach,
  Close,
  DowloadBtn,
  MessageContiner,
  TypeText,
  ViewImage,
  Image,
  Div2,
} from "./Styles";
import Error from "../StyledComponents/Toastify/Error";
import { GetProvStatus } from "../../StateManagement/Reducers/PreLoginState";
import Microphone_Start from "../../Assets/images/Microphone_Start.png";
import Microphone_Pause from "../../Assets/images/MicroPhone_Pause.png";
import Microphone_End from "../../Assets/images/MicroPhone_End.png";
import audioPreviewButton from "../../Assets/images/Circled Play.png";
import AudioPreview from "./audioPreview";
import { FiTrash2 } from "react-icons/fi";
import webmFixDuration from "webm-duration-fix";

const Messenger = () => {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

  const revId =
    localStorage.getItem("Prov_Id") &&
    Decrypt_Value(localStorage.getItem("Prov_Id"), "vozoportal");

  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const [sessioVal, setSessionVal] = useState(null);
  const [session, setSession] = useState("");
  const [headData, setHeadData] = useState(null);
  const [viewType, setViewType] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [imgPre, setImgPre] = useState(false);
  const [ImgUrl, setImgUrl] = useState("");
  const [fileName, setFileName] = useState(null);
  const [searchText, setsearchText] = useState("");
  const [searchDir, setSearchDir] = useState("");
  const [step, setStep] = useState(0);
  const [step1, setStep1] = useState(0);
  const [togg, setTogg] = useState(false);
  const userList = useSelector((state) => state.Messenger.ProviderList);
  const MessageList = useSelector((state) => state.Messenger.MessageList);
  const MessangerLoading = useSelector((state) => state.Messenger.Loading);
  const PreviewDownload = useSelector(
    (state) => state.Messenger.previewdownload
  );
  const ProvChatStatus = useSelector((state) => state.PreLogin.ProvStatus);

  const [isRecording, setIsRecording] = useState(false);
  const [audioURLs, setAudioURLs] = useState([]);
  const audioContext = useRef(null);
  const audioContext1 = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaRecorder1 = useRef(null);
  const audioChunks = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [isChat, setIsChat] = useState("both");
  const audioChunks1 = useRef([]);
  const audioStream = useRef(null);
  const audioStream1 = useRef(null);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [audioURLs1, setAudioURLs1] = useState([]);
  const [recordingKey, setRecordingKey] = useState(0);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(true);
  const [three, setThree] = useState(false);
  const [record, compRecord] = useState(false);
  const [delClick, setDelClick] = useState(false);
  const [headDataid, setHeadDataid] = useState(null);
  const [open, setOpen] = useState(false);
  const startRecording = async () => {
    try {
      compRecord(false);
      if (!audioStream.current) {
        audioStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      setRecordingKey((prevKey) => prevKey + 1);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      const audioBlobs = [];
      mediaRecorder.current.onstop = async () => {
        const duration = Date.now();
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioBlobs.push(audioBlob);
        const fixedBlob = await webmFixDuration(audioBlob, duration);
        setAudioURLs1(URL.createObjectURL(audioBlob));

        audioChunks.current = [];
        handleOnSend(fixedBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startRecording1 = async () => {
    try {
      if (!audioStream1.current) {
        audioStream1.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      audioContext1.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder1.current = new MediaRecorder(audioStream1.current);

      mediaRecorder1.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks1.current.push(event.data);
        }
      };

      mediaRecorder1.current.onstop = () => {
        const audioBlob = new Blob(audioChunks1.current, { type: "audio/wav" });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLs((prevAudioURLs) => [...prevAudioURLs, audioURL]);

        audioChunks1.current = [];
      };

      mediaRecorder1.current.start();

      setThree(false);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(true);
    }
  };

  const pauseRecording1 = () => {
    if (
      mediaRecorder1.current &&
      mediaRecorder1.current.state === "recording"
    ) {
      mediaRecorder1.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsRecording(true);
      setIsRecordingPaused(false);
    }
  };

  const resumeRecording1 = () => {
    if (mediaRecorder1.current && mediaRecorder1.current.state === "paused") {
      mediaRecorder1.current.resume();
    }
  };

  const stopRecording1 = () => {
    setThree(true);
    setOne(true);
    compRecord(true);
    if (
      mediaRecorder1.current &&
      (mediaRecorder1.current.state === "recording" ||
        mediaRecorder1.current.state === "paused")
    ) {
      mediaRecorder1.current.stop();
      audioStream1.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStream1.current = null;
      audioContext1.current.close();
    }
  };

  const stopRecording = () => {
    compRecord(true);
    if (three && audioURLs.length === 0) {
      return;
    }

    if (delClick) {
      audioStream.current = null;
    }
    if (
      mediaRecorder.current &&
      (mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused")
    ) {
      mediaRecorder.current.stop();
      audioStream.current !== null &&
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      audioStream.current = null;
      audioContext.current.close();
      setIsRecording(false);
      setIsRecordingPaused(false);
    }
    setTimeout(() => {
      // setAudioURLs([]);
      setOne(false);
    }, 1500);
  };

  const OnChangeSearch = (temp) => {
    setSearch(temp);
  };
  function getAudioBlobDuration(audioBlob) {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const arrayBuffer = event.target.result;
        audioContext.decodeAudioData(arrayBuffer, function (buffer) {
          const duration = Math.floor(buffer.duration);
          resolve(duration);
        });
      };
      if (audioBlob instanceof Blob) {
        fileReader.readAsArrayBuffer(audioBlob);
      } else {
        reject("Not a Blob");
      }
    });
  }
  const handleClick = (val, item) => {
    //sd
    if (isMobile) setIsChat("msgList");
    //sd
    dispatch(GetProvStatus(item.id));
    localStorage.setItem("Prov_Id", Encrypt_Value(item.id, "vozoportal"));
    setValue("");
    setHeadData(item);
    setHeadDataid(item.id);
    setSession(item.ProviderName);
    setSessionVal(val);
    const credential = { Pid: Pat_ID, Provider_Id: item.id };
    dispatch(getMessagerList(credential));
    setImgPre(false);
    setSelectedFile(null);
    if (sessioVal !== val) {
      setTogg(false);
      setsearchText("");
    }
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "bmp", "pdf"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleChange = (event) => {
    if (event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    if (isValidFileUploaded(file)) {
      let check = file.type.split("/")[1];
      if (
        check === "pdf" ||
        check === "jpeg" ||
        check === "png" ||
        check === "jpg"
      ) {
        setSelectedFile(file);
      } else {
        toast(<Error msg="File not supported......" />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    }
  };

  const handlePreview = (item) => {
    setImgPre(true);
    setImgUrl(item);
    let getName = item.split("/");
    setFileName(getName[getName.length - 1]);
  };

  const handleOnSend = async (audioBlob) => {
    try {
      let duration1 = null;

      if (audioBlob !== undefined && audioBlob.size > 0) {
        duration1 = await getAudioBlobDuration(audioBlob);
      }

      if (!record) {
        if (three && audioURLs.length === 0) {
          return;
        }

        if (delClick) {
          audioStream.current = null;
        }
        if (
          mediaRecorder.current &&
          (mediaRecorder.current.state === "recording" ||
            mediaRecorder.current.state === "paused")
        ) {
          mediaRecorder.current.stop();
          audioStream.current !== null &&
            audioStream.current.getTracks().forEach((track) => {
              track.stop();
            });
          audioStream.current = null;
          audioContext.current.close();
          setIsRecording(false);
          setIsRecordingPaused(false);
        }
      }

      const filedata = new FormData();
      let article = {
        message: value,
        ipAddress: "127.0.0.1",
        sender_id: Pat_ID,
        sender: "Patient",
        recip_id: session,
        receive_id: revId,
        status: 1,
        message_type: "text",
        timerVal: duration1,
      };

      let enc = Encrypt_Value(article, "vozo_portal");
      filedata.append("message", enc);

      if (audioBlob !== undefined && audioBlob.size > 0) {
        filedata.append("audio", audioBlob, "recorded_audio.wav");
      } else if (selectedFile) {
        filedata.append("fileupload", selectedFile);
      } else if (value === "") {
        return false;
      }
      if (
        selectedFile ||
        value !== "" ||
        (audioBlob !== undefined && audioBlob.size > 0)
      ) {
        dispatch(getMessagerSend(filedata));
      }
      setValue("");
      setSelectedFile(null);
      setAudioURLs([]);
      setAudioURLs1([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDownload = () => {
    dispatch(getPreviewDownload({ file: ImgUrl }));
  };

  const handleTextSearch = (e) => {
    setsearchText(e);
  };
  const handleUpward = () => {
    setSearchDir("up");
    setStep(step + 1);
  };

  const handleDownward = () => {
    setSearchDir("down");
    setStep1(step1 + 1);
  };
  const handleTogg = () => {
    setTogg(!togg);
    if (togg) {
      setsearchText("");
    }
  };

  const toggleImg = () => {
    setTwo(!two);
  };

  const toDelete = () => {
    alert("are u sure want to delete?");
    setAudioURLs([]);
    setAudioURLs1([]);
    setOne(false);
    setTwo(true);
    setIsRecording(false);
    setIsRecordingPaused(false);
    setDelClick(true);
  };
  const handleCloseMsg = () => {
    setIsChat("chatList");
  };

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
  }, [PreviewDownload]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    setFileName(selectedFile.name);
    var checkType = selectedFile.type.split("/");
    if (checkType[0] === "image") {
      setViewType("image");
    } else {
      setViewType("doc");
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    dispatch(GetMsgList());
  }, [dispatch]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 800) {
      setIsChat("chatList");
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <>
      <TopNav title="Messenger" TitleDescription="Welcome!" />
      {MessangerLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <MessageContiner className="mobMsgMar">
            {isChat !== "msgList" && (
              <ProviderList
                list={userList}
                sessioVal={sessioVal}
                search={search}
                OnChangeSearch={OnChangeSearch}
                handleClick={handleClick}
                isMobile={isMobile}
                setIsChat={setIsChat}
              />
            )}
            {sessioVal || sessioVal === 0
              ? isChat !== "chatList" && (
                  <Div
                    width={isMobile ? "100%" : "63%"}
                    height="100%"
                    background="#f4f4f4"
                    position="relative"
                    className="mobMsgBoxMarTop"
                  >
                    {/* some changes
                     */}
                    <HeadNav
                      data={headData}
                      chatData={MessageList}
                      provdataid={headDataid}
                      status={ProvChatStatus === true ? "Online" : "Offline"}
                      togg={togg}
                      handleChatSearch={handleTextSearch}
                      handleTogg={handleTogg}
                      handleUpward={handleUpward}
                      handleDownward={handleDownward}
                      list={userList}
                      session={session}
                      handleCloseMsg={handleCloseMsg}
                      isMobile={isMobile}
                    />
                    <ChatBody
                      data={MessageList}
                      sessioVal={sessioVal}
                      userData={headData}
                      hightlightText={searchText}
                      navigateSearch={searchDir}
                      steps={step}
                      steps1={step1}
                      handlePreview={handlePreview}
                      setViewType={setViewType}
                      setSelectedFile={setSelectedFile}
                      isValidFileUploaded={isValidFileUploaded}
                    />
                    {selectedFile && (
                      <Div
                        className="imageContainer "
                        position="absolute"
                        top="0"
                        width="99.7%"
                        height="73.5%"
                        backColor="rgba(244, 244, 244, 1)"
                        display="flex"
                        flexDirection="column"
                        padding="20px"
                        borderRadius="4px"
                        border="2px dotted #2C7BE5"
                        marginTop="85px"
                      >
                        <FlexBetween>
                          <Description
                            fontSize="13px"
                            lineHeight="18px"
                            color="#91929e"
                          >
                            {fileName}
                          </Description>
                          <Close onClick={() => setSelectedFile(null)} />
                        </FlexBetween>
                        {viewType === "image" && (
                          <Images
                            className="imageWarp"
                            src={preview}
                            MaxWidth="400px"
                            MaxHeight="320px"
                            margin="auto"
                          />
                        )}
                        {viewType === "doc" && (
                          <Images
                            src={DocType}
                            MaxWidth="250px"
                            MaxHeight="250px"
                            margin="auto"
                          />
                        )}
                      </Div>
                    )}
                    {imgPre && (
                      <Div
                        position="absolute"
                        top="0"
                        width="100%"
                        height="100%"
                        backColor="rgba(244, 244, 244, 1)"
                        display="flex"
                        flexDirection="column"
                        padding="20px"
                        borderRadius="4px"
                      >
                        <FlexBetween>
                          <CloseIcon>
                            <Description
                              fontSize="13px"
                              lineHeight="18px"
                              color="#91929e"
                            >
                              {fileName}
                            </Description>
                            <DowloadBtn onClick={handleDownload} />
                          </CloseIcon>
                          <CloseIcon padding="10px">
                            <Close onClick={() => setImgPre(false)} />
                          </CloseIcon>
                        </FlexBetween>
                        {viewType === "image" && (
                          <ViewImage>
                            <Images
                              src={ImgUrl}
                              MaxWidth="500px"
                              MaxHeight="400px"
                              Width="100%"
                              Height="100%"
                            />
                          </ViewImage>
                        )}
                        {viewType === "doc" && (
                          <iframe src={ImgUrl} Width="100%" Height="100%" />
                        )}
                      </Div>
                    )}

                    {!imgPre && (
                      <Div
                        position="relative"
                        height="56px"
                        margin="0px 10px 16px 20px"
                        background="#ffffff"
                        border="1px solid #d8e0f0"
                        boxShadow="0px 1px 2px rgba(184, 200, 224, 0.222055)"
                        borderRadius="14px"
                        marginLeft="20px"
                        display="flex"
                        maxHeight="fit-content"
                        padding="0px 11px 0px 30px"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        className="mobMsgRemovePadMar"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {/* {!isMobile && */}
                        <Input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={(e) => handleChange(e)}
                          onClick={(e) => e.stopPropagation()}
                          style={{ display: "none" }}
                          accept=".png, .pdf, .jpg, .jpeg, .gif"
                        />
                        {/* } */}

                        {!isRecording && !isRecordingPaused ? (
                          <>
                            <Attach
                              onClick={() => {
                                hiddenFileInput.current.click();
                                setValue("");
                              }}
                            />
                            <AtSymbol onClick={() => setValue(value + "@")} />
                            <TypeText
                              placeholder="Type your message here…"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              onKeyPress={(e) =>
                                e.key === "Enter" && handleOnSend()
                              }
                              id="msg_inp-typMsg"
                            />
                          </>
                        ) : (
                          <Div marginRight="20px" width="100%">
                            <RecordingStatus
                              isRecording={isRecording}
                              isRecordingPaused={isRecordingPaused}
                              audioURLs1={audioURLs1}
                              audioURLs={audioURLs}
                              recordingKey={recordingKey}
                              one={one}
                            />
                          </Div>
                        )}
                        <Div display="flex" alignItems="center">
                          {!isRecording && !isRecordingPaused ? (
                            <Image
                              mr="10px"
                              ml="10px"
                              width="16px"
                              height="22px"
                              color="#fdc748"
                              cursor="pointer"
                              src={Microphone_Start}
                              alt="mike"
                              onClick={() => {
                                startRecording1();
                                startRecording();
                              }}
                            />
                          ) : isRecording && !isRecordingPaused ? (
                            <Div display="flex" alignItems="center">
                              {" "}
                              <Image
                                mr="7px"
                                mt="2px"
                                width="25px"
                                height="25px"
                                color="#fdc748"
                                cursor="pointer"
                                src={Microphone_Pause}
                                alt="mike"
                                onClick={() => {
                                  pauseRecording();
                                  pauseRecording1();
                                }}
                              />
                              <Images
                                margin="9px 5px 2px auto"
                                width="56px"
                                height="44px"
                                color="#fdc748"
                                cursor="pointer"
                                src={SendIcon}
                                onClick={handleOnSend}
                              />
                            </Div>
                          ) : (
                            !isRecording &&
                            isRecordingPaused && (
                              <Div display="flex" alignItems="center">
                                {!one && (
                                  <Image
                                    mr="12px"
                                    mt="2px"
                                    width="16px"
                                    height="20px"
                                    color="#fdc748"
                                    cursor="pointer"
                                    src={Microphone_End}
                                    alt="mike"
                                    onClick={() => {
                                      resumeRecording();
                                      resumeRecording1();
                                    }}
                                  />
                                )}
                                <Div
                                  onClick={toggleImg}
                                  display="flex"
                                  alignItems="center"
                                >
                                  {!isRecording && isRecordingPaused && two ? (
                                    <Image
                                      mr="2px"
                                      width="30px"
                                      height="30px"
                                      color="#fdc748"
                                      cursor="pointer"
                                      src={audioPreviewButton}
                                      onClick={stopRecording1}
                                    />
                                  ) : (
                                    <FiTrash2
                                      onClick={toDelete}
                                      style={{
                                        color: "red",
                                        marginRight: "10px",
                                        height: "23px",
                                        width: "23px",
                                      }}
                                    />
                                  )}

                                  <Images
                                    margin="9px 5px 2px auto"
                                    width="56px"
                                    height="44px"
                                    color="#fdc748"
                                    cursor="pointer"
                                    src={SendIcon}
                                    onClick={stopRecording}
                                  />
                                </Div>
                              </Div>
                            )
                          )}
                        </Div>
                        {!isRecording && !isRecordingPaused && (
                          <Images
                            margin="9px 5px 2px auto"
                            width="56px"
                            height="44px"
                            color="#fdc748"
                            cursor="pointer"
                            src={SendIcon}
                            onClick={handleOnSend}
                          />
                        )}
                      </Div>
                    )}
                  </Div>
                )
              : isChat !== "chatList" && (
                  <Div
                    width={isMobile ? "100%" : "63%"}
                    height="100%"
                    background="#f4f4f4"
                    position="relative"
                    padding="45px 0px 0px 35px"
                    FlexCenter
                  >
                    <Text
                      fontFamily="Inter"
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="17px"
                      color="#8a969f"
                      textAlign="unset"
                    >
                      Please select chat list
                    </Text>
                  </Div>
                )}
          </MessageContiner>
        </>
      )}
    </>
  );
};
const RecordingStatus = ({
  isRecording,
  isRecordingPaused,
  audioURLs,
  recordingKey,
  one,
}) => {
  const [timer, setTimer] = useState(0);
  const [getUrls, SetUrls] = useState([]);
  useEffect(() => {
    let interval;

    if (isRecording && !isRecordingPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRecordingPaused) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRecording, isRecordingPaused]);

  useEffect(() => {
    SetUrls(audioURLs);
  }, [audioURLs]);

  return (
    <Div2 width="100%">
      {isRecording ? (
        <Div2 display="flex" alignItems="center">
          <Div2>{timer}s</Div2>
          <Div2
            style={{ marginLeft: "12px" }}
            className="voice-note-animation"
          ></Div2>
        </Div2>
      ) : (
        !one && (
          <Div2 display="flex" alignItems="center">
            <Div2>{timer}s</Div2>
            <Div2
              marginLeft="20px"
              className="voice-note-animation paused-animation"
            ></Div2>
          </Div2>
        )
      )}
      {isRecordingPaused && (
        <Div2
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
        >
          <Div2 width="100%">
            {getUrls && <AudioPreview key={recordingKey} audioBlob={getUrls} />}
          </Div2>
        </Div2>
      )}
    </Div2>
  );
};

export default Messenger;
