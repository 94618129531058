import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrowIcon from "../../../Assets/images/arrowIcon.png";

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  width: 130%;
  height: 32px;
  padding: 6px 10px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
  background-color: #ffffff;
  cursor: pointer;
  &.edit_dropdown_responsive {
    @media (max-width: 800px) {
      width: 96%;
    }
  }
`;

const Icon = styled.img`
  width: 10px;
  height: 6px;
  margin-left: auto;
  margin-top: 4px;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 130%;
  top: 100%;
  left: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  background-color: #ffffff;
`;

const DropdownItem = styled.li`
  padding: 8px 10px;
  font-size: 14px;
  color: #747474;
  cursor: pointer;

  background-color: ${({ disabled }) => (disabled ? "#eee" : "transparent")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#eee" : "#2c7be5 ")};
    color: #fff;
  }
`;

const Dropdown = ({ value, options, onSelect, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedOption(value);
    onSelect(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const isOptionDisabled = (optionValue) => {
    const hello =
      selectedOptions &&
      selectedOptions.some(
        (selectedValue) => selectedValue.type === optionValue
      );
    return hello;
  };

  const updatedOptions = options.map((option) => ({
    ...option,
    disabled: isOptionDisabled(option.value),
  }));

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSelectedOption(value || "");
  }, [value]);
  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader
        onClick={toggleDropdown}
        isOpen={isOpen}
        className="edit_dropdown_responsive"
      >
        {selectedOption || "Select"}
        <Icon src={arrowIcon} alt="Dropdown Icon" />
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {updatedOptions.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleSelect(option.value)}
              disabled={option.disabled}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
