import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdArrowBackIos } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";

import {
  GetCardDetails,
  EditCardDetails,
  RemoveCardDetail,
} from "../../../StateManagement/Reducers/BillingState";

import {
  Form,
  FormGroup,
  FormLabel,
  Slider,
  Switch,
  Toggle,
  Modal,
  ModalContainer,
  Image,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../StyledComponents";
import {
  Div,
  CardContainer,
  CardNum,
  CardHoldTxt,
  HoldName,
  CardBrand,
  Curcle,
  InnerCurcle,
  Tooltip,
  TooltipText,
} from "../../Billing/MyCards/Styles";
import {
  ModalDialog,
  CloseButton,
  CardBorder,
  CardTitle,
  EditCard,
  RemoveCard,
  Input,
  Span,
  ErrorMessage,
} from "./Styles";

import ChipPin from "../../../Assets/images/Group.png";
import StripeAlert from "../StripeAlert";

const CardDetails = ({ show, row, HandleClose }) => {
  //------------------------------------------State and Variables Start--------------------------------------//

  const dispatch = useDispatch();

  const [EditShow, setEditShow] = useState(false);
  const [HolderName, setHolderName] = useState("");
  const [ExpDate, setExpDate] = useState("");
  const [DefaultCard, setDefaultCard] = useState(false);
  const [DefaultCardVal, setDefaultCardVal] = useState(0);
  const [btnDisable, setBtnDis] = useState(false);

  const [ModalAnimat, setModalAnimat] = useState(true);
  const [NameErr, setNameErr] = useState(false);
  const [DateErr, setDateErr] = useState(false);

  const Refresh = useSelector((state) => state.Billing.Refresh);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  //------------------------------------------State and Variables End----------------------------------------//

  //------------------------------------------Functions Start------------------------------------------------//

  const handleModalClick = () => {
    setModalAlerShow(false);
  };
  const NameChange = (value) => {
    const re = /^[A-Za-z ]+$/;
    if (re.test(value)) {
      setHolderName(value);
      setNameErr(false);
    } else if (value === "") {
      setHolderName(value);
      setNameErr(true);
    }
  };

  const ExpDateChange = (e) => {
    let expiry = e.target.value;

    expiry = expiry.replace(/^(\d\d)(\d)$/g, "$1/$2");
    expiry = expiry.replace(/[^0-9/]/g, "");
    if (!isNaN(expiry) || (expiry.charAt(2) === "/" && expiry.length <= 5))
      setExpDate(expiry);

    const expiryMonth = Number(expiry.substr(0, 2));
    const expiryYear = Number(expiry.substr(3, 2));

    let currentYear = Number(new Date().getFullYear().toString().substr(-2));
    let currentMonth = Number(new Date().getMonth() + 1);
    if (expiryYear < currentYear) {
      setDateErr(true);
    } else if (currentYear === expiryYear && currentMonth > expiryMonth) {
      setDateErr(true);
    } else if (expiryMonth > 12) {
      setDateErr(true);
    } else {
      setDateErr(false);
    }
  };

  const RemoveCards = (cardid) => {
    if (stripeAllowed === false) {
      setModalAlerShow(true);
    } else {
      let confirm = window.confirm("Are you sure  want to delete this card ?");
      if (confirm === true) {
        dispatch(RemoveCardDetail(cardid));
      }
    }
  };

  const EditCardDetail = () => {
    setBtnDis(true);
    setTimeout(() => {
      setBtnDis(false);
    }, 1500);
    let err = 0;
    if (DateErr || NameErr) {
      err = 2;
    }
    if (err > 0) {
      return false;
    }

    let exp = ExpDate.split("/");

    let article = {
      row_id: row.id,
      card_holder: HolderName,
      card_no: row.number,
      month: exp[0],
      year: exp[1],
      cvv: row.cvv,
      def: DefaultCardVal,
    };
    dispatch(EditCardDetails(article));
  };

  const HandleToggle = (e) => {
    if (e.target.checked) {
      setDefaultCard(true);
      setDefaultCardVal(1);
    } else {
      setDefaultCard(false);
      setDefaultCardVal(0);
    }
  };

  const ClearCardDetails = () => {
    setDateErr("");
    setNameErr("");
    if (EditShow) {
      setEditShow(false);
    } else {
      setModalAnimat(false);
      setTimeout(() => HandleClose(), 999);
      setTimeout(() => setModalAnimat(true), 999);
    }
  };

  //------------------------------------------Functions End--------------------------------------------------//

  //------------------------------------------UseEffect Start-----------------------------------------------//

  useEffect(() => {
    if (Refresh !== "") {
      dispatch(GetCardDetails());
      HandleClose();
      setEditShow(false);
    }
    setHolderName(row.name);
    setExpDate(row.valid);
    if (row.def === "1") {
      setDefaultCard(true);
      setDefaultCardVal(1);
    } else {
      setDefaultCard(false);
      setDefaultCardVal(0);
    }
  }, [dispatch, Refresh, row, EditShow]);
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);
  //------------------------------------------UseEffect End-------------------------------------------------//

  return (
    <>
      <Modal show={show} height="100%" width="100%">
        <ModalContainer justifyContent="flex-end">
          <ModalDialog show={show} animation={ModalAnimat}>
            <ModalHeader padding="40px 20px">
              <CloseButton onClick={() => ClearCardDetails()}>
                <MdArrowBackIos />
              </CloseButton>
              <ModalTitle>Card Details</ModalTitle>
            </ModalHeader>

            <ModalBody
              padding="18px 24px"
              className="editmycard_responsiveness"
            >
              {EditShow ? (
                <>
                  <Div>
                    <CardBorder>
                      <ModalHeader
                        padding="0px 0px 16px 16px"
                        margin="0px -16px 0px -16px"
                      >
                        <ModalTitle
                          color="#0A1629"
                          lineHight="24px"
                          fontSize="16px"
                        >
                          Edit My Card
                        </ModalTitle>
                      </ModalHeader>
                      <ModalBody>
                        <Div padding="16px 0px 16px 0px">
                          <Form>
                            <FormGroup
                              display="flex"
                              alignItems="center"
                              marginBottom="8px"
                            >
                              <FormLabel
                                color="#191919"
                                fontFamily="'Inter',sans-serif"
                                fontSize="13px"
                                lineHight="16px"
                                marginRight="19px"
                              >
                                Name On Card
                              </FormLabel>
                              <FormGroup display="flex" flexDirection="column">
                                <Input
                                  onChange={(e) => NameChange(e.target.value)}
                                  error={NameErr}
                                  value={HolderName}
                                  className="editcard_input_responsivness"
                                />
                                {NameErr && (
                                  <ErrorMessage>
                                    Enter a valid name
                                  </ErrorMessage>
                                )}
                              </FormGroup>
                            </FormGroup>
                            <FormGroup
                              display="flex"
                              alignItems="center"
                              marginBottom="8px"
                            >
                              <FormLabel
                                color="#191919"
                                fontFamily="'Inter',sans-serif"
                                fontSize="13px"
                                lineHight="16px"
                                marginRight="26px"
                              >
                                Card Number
                              </FormLabel>
                              <FormGroup display="flex" flexDirection="column">
                                <Input
                                  value={row.number}
                                  cursor="not-allowed"
                                  disabled
                                  background="#F0F0F0"
                                  className="editcard_input_responsivness"
                                />
                              </FormGroup>
                            </FormGroup>
                            <FormGroup
                              display="flex"
                              alignItems="center"
                              marginBottom="8px"
                            >
                              <FormLabel
                                color="#191919"
                                fontFamily="'Inter',sans-serif"
                                fontSize="13px"
                                lineHight="16px"
                                marginRight="47px"
                              >
                                Expiration
                              </FormLabel>
                              <FormGroup display="flex" flexDirection="column">
                                <Input
                                  value={ExpDate}
                                  error={DateErr}
                                  onChange={(e) => ExpDateChange(e)}
                                  className="editcard_input_responsivness"
                                />
                                {DateErr && (
                                  <ErrorMessage>
                                    Enter a valid expiry date
                                  </ErrorMessage>
                                )}
                              </FormGroup>
                            </FormGroup>
                          </Form>
                        </Div>
                      </ModalBody>
                      <ModalFooter
                        padding="16px 16px 0px 0px"
                        margin="0px -16px 0px -16px"
                      >
                        <Div
                          display="flex"
                          justify="flex-end"
                          alignItems="center"
                        >
                          <Button
                            color="#2C7BE5"
                            background="#FFF"
                            hoverBackColor="rgba(244, 246, 249, 0.75)"
                            border="1px solid #2C7BE5"
                            borderRadius="6px"
                            padding="8px 32px"
                            fontWeight="500"
                            marginRight="16px"
                            fontFamily="'Work Sans', sans-serif"
                            className="close_active"
                            onClick={() => {
                              setEditShow(false);
                              setNameErr(false);
                              setDateErr(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="#FFFFFF"
                            background="#2C7BE5"
                            hoverBackColor="#005FB2"
                            borderRadius="4px"
                            padding="8px 38.5px"
                            fontWeight="500"
                            fontFamily="'Work Sans', sans-serif"
                            className="save_active"
                            disabled={btnDisable}
                            onClick={() => EditCardDetail()}
                            id="crDet_sve-btn"
                          >
                            Save
                          </Button>
                        </Div>
                      </ModalFooter>
                    </CardBorder>
                    <Div
                      display="flex"
                      justify="space-between"
                      alignItems="center"
                      padding="16px"
                      border="1px solid #D8E0F0"
                      borderRadius="8px"
                      margin="20px 0px 0px 0px"
                    >
                      <Span>Set as primary</Span>
                      <Switch>
                        <Toggle
                          checked={DefaultCard}
                          disabled={row.def === "1" ? true : false}
                          cursor={row.def === "1" ? "true" : "false"}
                          type="checkbox"
                          onClick={(e) => HandleToggle(e)}
                        />
                        <Slider />
                      </Switch>
                    </Div>
                  </Div>
                </>
              ) : (
                <CardBorder>
                  <Div
                    display="flex"
                    justify="space-between"
                    margin="0px 0px 20px 0px"
                  >
                    <CardTitle>My Card</CardTitle>
                    <EditCard
                      onClick={() => {
                        if (stripeAllowed === false) {
                          setModalAlerShow(true);
                        } else {
                          setEditShow((prevState) => !prevState);
                        }
                      }}
                      id="dbrd_cdet-spn_edt"
                    >
                      Edit
                    </EditCard>
                  </Div>
                  <CardContainer
                    selected={row.def === "1" ? false : true}
                    marginRight="auto"
                    marginLeft="auto"
                    boxShadow="linear-gradient(104deg, #194BFB 6.07%, #3A49F9 93.22%)"
                  >
                    <Div
                      margin="0px 0px 27px 0px"
                      display="flex"
                      alignItems="center"
                    >
                      <Image loading="lazy" src={ChipPin} />
                    </Div>

                    <CardNum selected={row.def === "1" && 0}>
                      {row.number}
                    </CardNum>

                    <Div
                      display="flex"
                      margin="20px 0px 0px 0px"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Div justify="space-between" width="100px">
                        <CardHoldTxt selected={row.def === "1" && 0}>
                          Card Holder
                        </CardHoldTxt>
                        <HoldName
                          selected={row.def === "1" ? 0 : 1}
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                        >
                          {row.name && row.name.length > 12 ? (
                            <Tooltip>
                              {row.name}
                              <TooltipText className="TooltipText">
                                {row.name}
                              </TooltipText>
                            </Tooltip>
                          ) : (
                            row.name
                          )}
                        </HoldName>
                      </Div>

                      <Div margin="0px 0px 0px 16px">
                        <CardHoldTxt selected={row.def === "1" && 0}>
                          Valid
                        </CardHoldTxt>
                        <HoldName selected={row.def === "1" ? 0 : 1}>
                          {row.valid}
                        </HoldName>
                      </Div>

                      <CardBrand
                        img={row.cardType ? row.cardType : ""}
                        selected={row.def === "1" && 0}
                      />
                    </Div>
                    <Curcle selected={row.def === "1" ? 0 : 1} />
                    <InnerCurcle selected={row.def === "1" ? 0 : 1} />
                  </CardContainer>
                  <RemoveCard
                    display="flex"
                    justify="center"
                    alignItems="center"
                    margin="25px 0px 0px 0px"
                    padding="20px 0px"
                    onClick={() => RemoveCards(row.id)}
                  >
                    <IoCloseCircleOutline
                      style={{ fontSize: 22, marginRight: 10 }}
                    />
                    Remove Card
                  </RemoveCard>
                </CardBorder>
              )}
            </ModalBody>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};

export default CardDetails;
