import styled from "styled-components";
import { Link } from "react-router-dom";

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlexJustCenter = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin && margin};
  width: ${({ width }) => width && width};
  overflow: ${({ overflow }) => overflow && overflow};
  white-space: ${({ whiteSpace }) => whiteSpace && whiteSpace};
  text-overflow: ${({ textOverflow }) => textOverflow && textOverflow};
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NormalImage = styled.img`
  height: ${(p) => p.height || "20px"};
  width: ${(p) => p.width || "20px"};
  ${(p) => p.display && `display : ${p.display}`};
  ${(p) => p.repeat && `background-repeat : ${p.repeat}`};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  ${(p) => p.display && `display : ${p.display};`}
  ${(p) => p.repeat && `background-repeat : ${p.repeat};`}
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.circle && "border-radius : 50%;"}
  ${(p) =>
    p.logoInline &&
    `
  position : absolute;
  top: ${p.absoluteTop && p.absoluteTop};
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0px);
  `}
  ${(p) => p.pointer && `cursor : pointer;`}
  @media screen and (max-width: 1024px) {
    &.ProvImages_alt {
      height: 24px !important;
      width: 24px !important;
    }
  }
  &.createnewappointment_image_responsive {
    @media (max-width: 800px) {
      height: 18px;
      width: 18px;
    }
  }
`;

export const CommonDiv = styled.div`
  ${(p) => p.position && `position : ${p.position};`}
  ${(p) => p.display && `display : ${p.display};`}
  height : ${(p) => p.height || "auto"};
  width: ${(p) => p.width || "auto"};

  background-color: ${(p) => p.backColor || "white"};
  color: ${(p) => p.color || "black"};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterVertical) {
      return `display : flex;
    justify-content : center;
    `;
    }
  }}
  left: ${(p) => p.left && p.left};
  display: ${(p) => p.display && p.display};
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  gap: ${(p) => p.gap && p.gap};
  height: ${(p) => (p.height ? p.height : "auto")};
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width || "auto";
    }
  }};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backColor || "white"};
  color: ${(p) => p.color || "black"};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};

  position: ${(p) => p.position && p.position};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  border-color: ${(p) => p.borderColor && p.borderColor};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  right: ${(p) => p.right && p.right};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  visibility: ${(p) => p.visibility && p.visibility};
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  ${(p) => p.borderBox && "box-sizing : border-box;"}
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  min-height: ${(p) => p.minHeight && p.minHeight};
  min-width: ${(p) => p.minWidth && p.minWidth};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  &.mobDocEditPad {
    @media (max-width: 800px) {
      padding: 5px 8px 5px 8px;
    }
  }

  //   &.mobSearchMarTop {
  //   @media (max-width: 800px) {
  //    margin-top:50px
  //   }
  // }
  &.mobMsgBoxMarTop {
    @media (max-width: 800px) {
      padding-top: 55px;
    }
  }
  &.mobMsgTopPad {
    @media (max-width: 800px) {
      padding: 4px 16px 8px 16px;
      // margin-top:58px
    }
  }
  &.mobDocEditMar {
    @media (max-width: 800px) {
      margin-top: 16px;
      padding: 0px 16px;
    }
  }
  &.mobDocEditMar1 {
    @media (max-width: 800px) {
      margin-top: 16px;
      padding: 0px 0px;
    }
  }
  &.Chat_width_alt {
    @media (max-width: 820px) {
      padding: 0px 0px 0px 3px !important;
    }
  }
  &.mobChatListPad {
    @media (max-width: 800px) {
      padding: 0px 16px !important;
      margin-top: 115px;
    }
  }
  &.mobDocEditBottomPad {
    @media (max-width: 800px) {
      padding: 24px 16px;
      display: flex;
      justify-content: space-between;
      width: 100% !important;
    }
  }
  &.mobMsgFloat {
    @media (max-width: 800px) {
      position: absolute;
      top: 24px;
      left: 10%;
      right: 0;
      background: ${(p) => p.background || "#F7F7F7"};
      z-index: 1000; /* Ensure it appears above other elements */
      display: ${(p) => (p.display === "none" ? "none" : "block")};
      width: 60%;
      height: 44px;
      align-items: center;
      padding-top: 5px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  &.mobViewMsgFloat {
    @media (max-width: 800px) {
      position: absolute;
      top: 63px;
      left: 6%;
      right: 0;
      background: #f7f7f7;
      z-index: 1000;
      width: 75%;
      height: 35px;
      align-items: center;
      padding-left: 8px;
      border-radius: 10px;
      justify-content: space-between;
    }
  }

  &.unread_alt {
    @media (max-width: 820px) {
      margin-left: 192px !important;
    }
  }

  @media (max-width: 820px) {
    width: ${(p) => p.resWidth && p.resWidth};
    padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
    padding: ${(p) => p.respadding && p.respadding};
    @media (max-width: 800px) {
      &.mobMsgRemovePadMar {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 15px;
        padding-left: 12px;
        padding-right: 0px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
      }
    }
  }
  &.imageContainer {
    @media (max-width: 820px) {
      height: 85.6%;
    }
  }
  cursor: ${(p) => p.cursor && p.cursor};

  &.scrollbar_adujust {
    overflowy: auto !important;
    word-wrap: break-word !important;
  }
  &.scrollbar_adujust::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px !important;
  }
  &.addappoinment_responsive {
    @media (max-width: 800px) {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10000;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #808080bd;
      overflow: hidden;
    }
  }
  &.Appoinment_container_responsive {
    @media (max-width: 800px) {
      height: auto;
    }
  }
  &.button_createnewappoinment_responsive {
    @media (max-width: 800px) {
      margin-top: 2px;
    }
  }
  @media (max-width: 800px) {
    &.mobRemovePad {
      padding: 0px;
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  &.edit_profile_phnum drop {
    @media (max-width: 800px) {
      //  width:10%;
    }
  }
  &.formph_responsive {
    @media (max-width: 800px) {
      width: auto;
    }
  }
  &.Phone_description_responsive {
    @media (max-width: 800px) {
      margin-right: 23px;
    }
  }

  &.button_createnewappoinment_responsive {
    @media (max-width: 800px) {
      margin-top: 2px;
    }
  }
  &.changepassword_container_responsive {
    @media (max-width: 800px) {
      margin-left: 16px;
      margin-top: 74px;
    }
  }
  &.Invoiceclose svg {
    color: #718096;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
  &.Invoiceprinter svg {
    color: #718096;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-right: 20px;
  }
  &.Invoicemobclose svg {
    color: #718096;
    cursor: pointer;
    height: 14px;
    width: 14px;
  }
`;
//  background = "#407BFF";
//  display = "block";
//  height = "50px";
//  fontFamily = "Inter";
//  fontSize = "16px";
//  fontWeight = "500";
//  lineHeight = "26px";
//  padding = "12px 10%";
//  borderRadius = "8px";
//  marginLeft = "auto";
//  marginRight = "auto";
//  marginTop = "46px";
export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  color: ${(p) => (p.color ? p.color : "black")};
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : "'Manrope',sans-serif")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  width: ${(p) => (p.width ? p.width : "100%")};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  margin-top: ${(p) => (p.mt ? p.mt : "0px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.align && p.align};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding: ${(p) => p.padding && p.padding};
  wordwrap: ${(p) => p.wordWrap && p.wordWrap};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  ${(p) => p.wordBreak && "word-break: break-word;"}
  ${(p) =>
    p.position &&
    `
  position: ${p.position};  
  ${p.top || `top : ${p.top}`};
  ${p.left || `left : ${p.left}`};
  ${p.bottom || `bottom : ${p.bottom}`};
  ${p.right || `right : ${p.right}`};
  `}
  white-space:${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  &.scrollbar_adujust {
    overflow: auto !important;
    word-wrap: break-word !important;
  }
  &.scrollbar_adujust::-webkit-scrollbar {
    width: 4px !important;
  }
  &.mobTextChangeTop {
    @media (max-width: 800px) {
      font-weight: 600;
      font-size: 20px;
      color: black;
    }
  }
  &.mobTextChangeBot {
    @media (max-width: 800px) {
      font-weight: 400;
      font-size: 16px;
      color: black;
    }
  }
  &.prov_width {
    @media (max-width: 820px) {
      width: 20% !important;
    }
  }

  &.date_width {
    @media (max-width: 820px) {
      width: 33% !important;
      margin-left: 141px !important;
    }
  }

  &.prov_width {
    @media (max-width: 820px) {
      width: 20% !important;
    }
  }

  &.chatmsg_width {
    @media (max-width: 820px) {
      width: 70px !important;
    }
  }
  &.prov_width {
    @media (max-width: 1020px) {
      width: 20% !important;
    }
  }

  &.date_width {
    @media (max-width: 1020px) {
      width: 33% !important;
      margin-left: 141px !important;
    }
  }

  &.prov_width {
    @media (max-width: 1020px) {
      width: 20% !important;
    }
  }

  &.chatmsg_width {
    @media (max-width: 1020px) {
      width: 70px !important;
    }
  }
  &.prov_width {
    @media (max-width: 1300px) {
      width: 20% !important;
    }
  }

  &.date_width {
    @media (max-width: 1300px) {
      width: 47% !important;
      margin-left: 141px !important;
    }
  }

  &.prov_width {
    @media (max-width: 1300px) {
      width: 20% !important;
    }
  }

  &.chatmsg_width {
    @media (max-width: 1300px) {
      width: 70px !important;
    }
  }
  @media (max-width: 800px) {
    &.mobChatNameMar {
      width: 100% !important;
    }
  }

  &.changepassword_width {
    @media (max-width: 800px) {
      width: 80%;
      font-size: 12px;
    }
  }

  &.editprofile_text {
    @media (max-width: 800px) {
      font-size: 20px !important;
    }
  }

  &.edit_profile_descrip {
    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  &.upoaddoc_responsive_text {
    @media (max-width: 800px) {
      font-size: 20px !important;
    }
  }

  &.upoaddoc_responsive_description {
    @media (max-width: 800px) {
      font-size: 16px;
    }
  }
  &.uploaddoc_filename_responsive {
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
`;

export const Text1 = styled.p`
  color: ${(p) => (p.color ? p.color : "black")};

  margin-top: ${(p) => (p.mt ? p.mt : "0px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.align && p.align};
`;

export const Description = styled.p`
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  align-items: ${(p) => p.alignItems && p.alignItems};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  @media (max-width: 800px) {
    &.mobChatMar {
      margin-top: 12px;
      margin-bottom: 16px;
    }
  }
`;
// export const Modal = styled.div`
//   display: ${(p) => (p.show ? "block" : "none")};
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   // background: rgba(0, 0, 0, 0.6);
//   transition: all 0.5s;
// `;

export const ModalContent = styled.div`
  position: fixed;
  top: 25px;
  transform: translateX(100%);
  background: #ffffff;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.06), 0px 16px 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border-left: 8px solid #ff8181;
  animation: modal 1s linear;
  // transition: all 0.1s;
  @keyframes modal {
    0% {
      transform: translateX(250%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

// export const ToastCont = styled.div`
//   padding: 20px;
//   border-radius: 4px;
//   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
// `;

export const SpanCont = styled.span`
  font-family: ${(p) => (p.fontFamily ? p.fontFamily : "'Inter',sans-serif")};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  width: ${(p) => p.width && p.width};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding-left: ${(p) => p.pl && p.pl};
  padding-top: ${(p) => p.pt && p.pt};
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  background-color: ${(p) => p.backColor && p.backColor};
  padding: ${(p) => p.padding && p.padding};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  display: ${(p) => p.display && p.display};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  overflow: ${(p) => p.overFlow && p.overFlow};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  @media (max-width: 800px) {
    background-color: unset;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(P) => P.backgroundColor && P.backgroundColor};
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const TextContent = styled.div`
  display: ${(p) => (p.display ? p.display : "flex")};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : "column")};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
`;

export const CloseIcon = styled.div`
  display: flex;
  padding: ${({ padding }) => padding && padding};
`;

// Normal Components //

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight || "700"};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: flex;
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  color: #232934;
`;

export const PageSubTitle = styled.h4`
  font-weight: 700;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "24px")};
  line-height: 33px;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: flex;
  align-items: center;
  color: #232934;
  padding: ${(p) => p.padding && p.padding};
  color: ${(p) => p.clr && p.clr};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-weight: ${(p) => p.fontweight && p.fontweight};
  @media (max-width: 800px) {
    padding-left: 0px;
    &.mobFontSize {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const Image = styled.img`
  width: ${(p) => (p.width ? p.width : "max-content")};
  height: ${(p) => (p.height ? p.height : "max-content")};
  object-fit: ${(props) => (props.objectFit ? props.objectFit : "contain")};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  &.image_responsive {
    @media (max-width: 800px) {
      width: 78%;
      height: 34.5%;
    }
  }
`;

export const Button = styled.button`
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "8px")};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  color: ${(p) => (p.color ? p.color : "#ffffff")};
  border: ${(p) => (p.border ? p.border : "none")};
  padding: ${(p) => p.padding && p.padding};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  max-width: ${(p) => p.maxwidth && p.maxwidth};
  min-width: ${(p) => p.minwidth && p.minwidth};
  display: ${(p) => p.display && p.display};

  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-right: ${(p) => p.svgmarginRight && p.svgmarginRight};
  }
  ${(p) =>
    p.disabled &&
    `
      cursor: not-allowed;
      `}
  @media (max-width:800px) {
    &.mobCardButTxt {
      min-width: 50%;
    }
    &.mobChatRatius {
      border-radius: 4px;
    }
  }
  &.create_new_appt {
    @media (max-width: 800px) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  &.accessdenied_responsiveness {
    @media (max-width: 800px) {
      margin-top: 4px;
      height: 90px;
      background-color: unset;
      color: #245c66;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      position: relative;
      top: 10%;
      right: 2%;
    }
  }
  &.change_password_button_responsive {
    @media (max-width: 800px) {
      width: 95%;
    }
  }
`;

export const ButtonIcon = styled.span`
  margin-right: 9px;
`;

export const LinK = styled(Link)`
  color: ${(p) => (p.color ? p.color : "#1f28eb")};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  text-decoration: none;
  line-height: ${(p) => p.lineheight && p.lineheight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  svg {
    font-size: ${(p) => (p.fontSize ? p.fontSize : "12px")};
    margin-right: ${(p) => (p.marginRight ? p.marginRight : "11px")};
  }
`;

// Normal Components //

//  Body container //

export const BodyContainer = styled.div`
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  height: ${(p) => p.height && p.height};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-align: ${(p) => p.textAlign && p.textAlign};
  flex: ${(p) => p.Flex && p.Flex};
  width: ${(p) => p.width && p.width};
  @media (max-width: 800px) {
    &.mobBillingPad {
      padding-left: 0px;
      margin-right: 16px;
      margin-left: 16px;
    }

    &.mobHealthMarginRight {
      margin-right: 0px;
      padding-left: 0px;
      margin-left: 16px;
    }
    &.mobRemovePad {
      padding-left: 0px;
      margin-right: 16px;
      margin-left: 16px;
    }
  }
  &.bodycontainer_responsive {
    @media (min-width: 320px) and (max-width: 840px) {
      padding: "8px";
    }
  }
  &.editpro_body_responsive {
    @media (min-width: 320px) and (max-width: 840px) {
      padding-left: 18px;
      width: auto;
    }
  }
`;

export const PastApptBodycont = styled(BodyContainer)`
  margin-top: ${(p) => p.marginTop && p.marginTop};
  @media screen and (max-width: 1000px) {
    height: 101% !important;
  }
  &.Appoinmentlist_responsive {
    @media (min-width: 320px) and (max-width: 840px) {
      display: flex;
      flex-direction: column;
      width: 98%;
    }
  }
`;

//  Body container //

// Table Styled Component //

export const Table = styled.table`
  margin-top: 1px;
  width: 100%;
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  height: ${(p) => (p.height ? p.height : "100px")};
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  @media (max-width: 800px) {
    display: none; // Hide table on smaller screens
  }
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
  @media (max-width: 820px) {
    &.invoicetable::-webkit-scrollbar {
      width: 2px !important;
    }
  }
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "600")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  color: ${(p) => (p.color ? p.color : "#464646")};
  padding: ${(p) => (p.padding ? p.padding : "20px 40px 20px 40px")};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  min-width: ${(p) => p.minWidth && p.minWidth};
  // wid:
`;

export const TableBodyCell = styled.td`
  font-size: 14px;
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")} !important;
  color: ${(p) => (p.color ? p.color : " #000000")};
  padding: ${(p) => (p.padding ? p.padding : "16px 40px 16px 38px")};

  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  cursor: ${({ cursor }) => cursor && "pointer"};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  width: ${(p) => p.width && p.width};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
`;
export const TableRow = styled.tr`
  cursor: ${({ cursor }) => cursor && "pointer"};
  text-align: left;
  background-color: ${(props) => props.background};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  grid-template-columns: ${(p) => p.tempColumns && p.tempColumns};
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  @media (max-width: 800px) {
    display: none; // Hide table rows on smaller screens
  }
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
    //margin: 5px;
    // margin-right:4px;
  }
`;
export const CardView = styled.div`
display: none; 
background-color: #fff;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
margin: 8px 0;
padding: 12px;
width: 100%;

@media (max-width:800px) {
  display: block; 
      box-shadow: 0px 0px 4px 0px rgba(46, 46, 46, 0.10);
      border:1px solid #F4F4F4;
margin: 8px 0px;
margin-right:20px
`;

export const CardHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #464646;
`;

export const CardContent = styled.div`
  font-size: 14px;
  color: #212121;
  margin-top: 0px;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  font-size: 14px;
  color: #20c9ac;
`;
// Table Styled Component //

// Modal //

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: ${(p) => (p.width ? p.width : "100vw")};
  height: ${(p) => (p.height ? p.height : "100vh")};
  display: ${(p) => (p.show ? "block" : "none")};
  background-color: rgba(102, 112, 133, 0.4);
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: ${(p) => (p.top ? p.top : "0")};
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  background-color: rgba(102, 112, 133, 0.4);
  &.scrollbar_adujust::-webkit-scrollbar {
    width: 2px !important;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-bottom: ${(p) => (p.borderLess ? "none" : "1px solid #d8e0f0")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  // box-shadow: ${(p) => p.boxShadow && p.boxShadow};
`;

export const ModalTitle = styled.h4`
  line-height: ${(p) => (p.lineHight ? p.lineHight : "27px")};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  color: ${(p) => (p.color ? p.color : "#232934")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

export const ModalBody = styled.div`
  padding: ${(p) => p.padding && p.padding};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  &.editmycard_responsiveness {
    @media (max-width: 800px) {
      // width:80%;
      // height:80%;
      margin-left: 20px;
    }
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid #d8e0f0;
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  border-top: ${(p) => p.borderTop && p.borderTop};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

// Modal //

// Form //

export const Form = styled.form`
  padding: ${(p) => p.padding && p.padding};
`;

export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const FormGroup = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  position: relative;
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
  grid-column: ${(p) => p.gridColumn && p.gridColumn};
  padding: ${(p) => p.padding && p.padding};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &.form_responsive {
    @media (max-width: 800px) {
      width: auto;
    }
  }
  &.form_responsive_lastname {
    @media (max-width: 800px) {
      width: 90%;
    }
  }
  &.street_responsive {
    @media (max-width: 800px) {
      margin-right: 23px;
    }
  }
`;

// Form

// Toggle Switch //

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbd5e0;
  transition: 0.4s;
  border-radius: 34px;
  cursor: ${(p) => (p.cursor ? "not-allowed" : "pointer")};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.1);
  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 2px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.4s;
    cursor: ${(p) => (p.cursor ? "not-allowed" : "pointer")};
  }
`;

export const Toggle = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: #407bff;
    cursor: ${(p) => (p.cursor === "true" ? "not-allowed" : "pointer")};
    &:before {
      transform: translateX(8px);
      cursor: ${(p) => (p.cursor === "true" ? "not-allowed" : "pointer")};
    }
  }
`;

// Toggle Switch //
export const Input = styled.input`
  color: ${(p) => p.color || "black"};
  ${(p) => p.width && `width: ${p.width};`}
  ${(p) => p.height && `height: ${p.height};`}
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  ${(p) => p.position && `position: ${p.position};`}
`;

export const Span = styled.span`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const Anchor = styled.a`
  text-decoration: none;
`;

//Alert msg Div //

export const AlertModalDialog = styled.div`
  background: ${(props) => (props.color === true ? "#fc6a68" : "#b5f5c8")};
  height: 42px;
  border-radius: 23px;
  text-align: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    margin-right: 10px;
  }
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;

export const FileInput = styled.input`
  display: none;
`;
export const StyledModal = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

export const ModalDialog = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 455px;
  height: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 570px) {
    width: 80%;
    height: auto;
  }
`;
