import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import TopNav from "../TopNav";
import { Div, PageSubTitle } from "../StyledComponents";
import { MenuHead } from "./styles";
import { HiOutlineChevronRight } from "react-icons/hi";

const SettingsNew = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <TopNav title="Settings" />
      <Div
        className="mobRemovePad"
        padding="20px 40px 40px 40px"
        marginTop={open ? "0px" : "79px"}
      >
        <PageSubTitle fontSize="18px">Account</PageSubTitle>

        <Link to="/settings/changepassword">
          <Div
            border="1px solid rgba(46, 46, 46, 0.10)"
            marginTop="8px"
            borderRadius="4px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px 20px"
          >
            <MenuHead>Change Password</MenuHead>
            <Div cursor="pointer" flexCenterVertical>
              <HiOutlineChevronRight />
            </Div>
          </Div>
        </Link>
      </Div>
    </>
  );
};

export default SettingsNew;
