import styled from "styled-components";

export const CloseButton = styled.button`
  z-index: 1200;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
  color: inherit;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 800px) {
    &.mobNotiRemoveMar {
      margin-left: 4px;
    }
  }
`;
export const DataDiv = styled.div`
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
  border-bottom: ${(p) => p.bb && p.bb};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.ml && p.ml};
  margin-top: ${(p) => p.mt && p.mt};
  margin-right: ${(p) => p.mr && p.mr};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow: ${(p) => p.overflow && p.overflow};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.br && p.br};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  min-width: ${(p) => p.minWidth && p.minWidth};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  aspect-ratio: ${(p) => p.aspectRatio && p.aspectRatio};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  // max-height: 418px;
  border: ${({ drop }) => (drop ? "dotted 2px #8DA3F0" : "")};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 800px) {
    max-height: none;
    height: auto;
  }
`;

export const Row = styled.div`
  display: ${(p) => p.dis && p.dis};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  @media (max-width: 800px) {
    height: auto;
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  ${({ flex }) =>
    flex &&
    ` display : flex;
  align-items: center;
  justify-content: center;`};

  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
`;

export const AvatarLetter = styled.span`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  padding-right: ${(p) => p.pr && p.pr};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
`;

export const Text = styled.p`
  margin-bottom: 0px !important;
  align-items: center;
  letter-spacing: 0.0025em;
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  margin-left: ${(p) => p.ml && p.ml};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  padding-top: ${(p) => p.pt && p.pt};
  width: ${(p) => p.width && p.width};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  height: ${(p) => p.height && p.height};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;
export const Checkbox = styled.input`
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 8px;
`;
