import React, { useState, useEffect } from "react";
import { Modal, ModalContainer, Div, Text } from "../../StyledComponents";
import { BsCheck, IoClose, RiError } from "./styles";

const AlertModal = (props) => {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [showAlert, setShowAlert] = useState(props.show);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setShowAlert(props.show);
  }, [props.show]);

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  return (
    <>
      {showAlert && (
        <Modal
          show="flex"
          justifyContent="center"
          style={{
            width: screenSize.width * 1.0,
            height: screenSize.height * 1.0,
          }}
        >
          <ModalContainer
            justifyContent="center"
            alignItems="center"
            top="0%"
            borderRadius="8px"
            backgroundColor={props.status === "success" ? "#d4edda" : "#f8d7da"}
            border="1px solid transparent"
            borderColor={props.status === "success" ? "#c3e6cb" : "#f5c6cb"}
          >
            <Div
              width={props.maxWidth || ""}
              padding={
                props.status === "success" && open
                  ? "15px 65px"
                  : props.status === "success" && !open
                  ? "15px 20px"
                  : props.status === "retry"
                  ? "20px 21px 20px 22px"
                  : "15px 65px"
              }
              display="flex"
              alignItems="center"
              background={props.status === "success" ? "#d4edda" : "#f8d7da"}
              borderColor={props.status === "success" ? "#c3e6cb" : "#f5c6cb"}
            >
              {props.status === "success" ? (
                <BsCheck />
              ) : props.status === "retry" ? (
                <RiError />
              ) : (
                <IoClose />
              )}

              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="16px"
                color={
                  props.status === "success" ? "#155724" : "rgba(176, 0, 32, 1)"
                }
                padding="4px 6px 4px 16px"
                maxWidth="500px"
              >
                {props.msg}
              </Text>
            </Div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default AlertModal;
