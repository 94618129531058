import styled from "styled-components";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BsFillImageFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { VscMention } from "react-icons/vsc";
import { BsXLg } from "react-icons/bs";
import { FlexBetween } from "../StyledComponents";
import { BsDownload } from "react-icons/bs";

export const MessageContiner = styled(FlexBetween)`
  height: calc(100% - 111px);
  background: linear-gradient(
    to right,
    #fff 0%,
    #fff 37%,
    #f4f4f4 37%,
    #f4f4f4 100%
  );

  @media (max-width: 820px) {
  height: calc(100% - 90px);
  }
`;

export const SearchInputText = styled.input`
  background: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding-left: 5px;
  outline-offset: -26px;

  @media (max-width: 1070px) {
    width: 112px;
  }
`;

export const Attach = styled(AiOutlinePaperClip)`
  // margin-top: 15px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  color: #6d5dd3;
  cursor: pointer;
`;

export const AtSymbol = styled(VscMention)`
  // margin-top: 15px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  color: #3f8cff;
  cursor: pointer;
`;

export const TypeText = styled.input`
  height: 30px;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  margin-left: 20px;
  // margin-top: 10px;
  width: 76%;
`;

export const Close = styled(BsXLg)`
  margin-left: auto;
  cursor: pointer;
  color: #407bff;
  margin-right: 34px;
`;

export const ViewImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const DowloadBtn = styled(BsDownload)`
  cursor: pointer;
  color: #407bff;
  font-weight: bold;
  margin-left: 20px;
`;

export const ThumbImage = styled(BsFillImageFill)`
  margin-top: 2.5px;
`;
export const ThumbDoc = styled(BsFillFileEarmarkFill)`
  margin-top: 2.5px;
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const Div2 = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  position: ${(p) => p.position && p.position};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  margin: ${(p) => p.margin && p.margin};
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  &.voice-note-animation {
    width: 100%; /* Adjust the width as needed */
    height: 6px; /* Adjust the height as needed */
    background-color: #5f595c; /* Wave color */
    position: relative;
    overflow: hidden;
  }

  &.voice-note-animation::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      #007bff 50%,
      #007bff 100%
    );
    transform: translateX(-100%);
    animation: wave-animation 1.5s linear infinite;
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  &.paused-animation::before {
    animation-play-state: paused;
  }
`;
