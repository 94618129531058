import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDocumentslist } from "../../StateManagement/Reducers/DocumentsState";

import TopNav from "../TopNav";
import DocumentList from "./DocumentList";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";
import { GetNotificationlabempty } from "../../StateManagement/Reducers/TopNavbarState";

const Document = () => {
  const dispatch = useDispatch();

  const [documenthead, setdocumenthead] = useState("Documents");
  const [open, setOpen] = useState(false);

  const Loading_ = useSelector((state) => state.Document.Loading);
  const Refresh = useSelector((state) => state.Document.Refresh);
  const labnotify = useSelector((state) => state.TopNavbar.Notificationlab);
  useEffect(() => {
    dispatch(getDocumentslist({ filtertype: "All Documents" }));
  }, [dispatch, Refresh]);
  useEffect(() => {
    if (labnotify.length > 0) {
      setdocumenthead("Lab Results");
      dispatch(getDocumentslist({ filtertype: "Lab Results" }));
      dispatch(GetNotificationlabempty(""));
    }
  }, [labnotify]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <TopNav title="Document" />
      {Loading_ ? (
        <Loading />
      ) : (
        <BodyContainer
          marginTop={open ? "0px" : "64px"}
          className="mobRemovePad"
          paddingLeft="40px"
          paddingTop="4px"
        >
          <DocumentList
            documenthead={documenthead}
            setdocumenthead={setdocumenthead}
          />
        </BodyContainer>
      )}
    </>
  );
};

export default Document;
