import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetReEmailRes,
  setReEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
  Span,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
  Atag,
} from "../StyledComponents";
import getFilePath from "../../../Config/path";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";
import vozoLogo from "../../../Assets/images/Logo.png";
import ResetImage from "../../../Assets/images/reset.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const File_URL = getFilePath();
  const [open, setOpen] = useState(false);

  const emailResponse = useSelector((state) => state.PreLogin.ReEmailResp);
  const text = useSelector((state) => state.PreLogin.logo);

  const HandleDispatch = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    if (email !== "") {
      dispatch(GetReEmailRes({ type: { email: email } }));
      setModalAlerShow(true);
      setStatus("success");
      setModalMsg("Your details are being verified.");
    } else {
      setMailErr(true);
      setErrMsg("Please enter the Email address");
    }
  };

  const HandleNavigate = (e) => {
    e.preventDefault();
    dispatch(setReEmailRes({}));
    Navigate("/Login");
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    setMailErr(false);
    // setemail(e.target.value);
    // const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // if (regex.test(e.target.value)) {
    //   setMailErr(false);
    // } else {
    //   setMailErr(true);
    //   setErrMsg("Invalid Email");
    // }
  };

  const handleBlur = () => {
    const value = email.trim();
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (value === "") {
      setMailErr(false);
      setErrMsg("");
    } else if (!regex.test(value)) {
      setMailErr(true);
      setErrMsg("Invalid Email");
    } else {
      setMailErr(false);
      setErrMsg("");
    }
  };

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);

      Navigate("/ReVerifyAccount", {
        state: { type: { email }, reset: true },
      });
    } else if (emailResponse.status === "Failure") {
      setModalAlerShow(false);

      setMailErr(true);
      setErrMsg("Please enter Registered email");
    }
  }, [emailResponse]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "110vh" : "170vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          <Div width="50%" backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" backColor="#FFFFFF">
            <Div width="100%" display="flex">
              <Div width="475px" textAlign="center" margin="auto">
                {text.length !== 0 && (
                  <ImageContainer marginTop="72px" marginBottom="55px">
                    <Image
                      loading="lazy"
                      src={text !== "failed" ? File_URL + text : vozoLogo}
                      height="41px"
                      width="173px"
                    />
                  </ImageContainer>
                )}
                <TitleImage
                  src={ResetImage}
                  alt="Email Verified"
                  width="124px"
                  height="124px"
                  marginTop="10%"
                ></TitleImage>
                <Title
                  color="#000000"
                  fontWeight="800"
                  fontSize="32px"
                  lineHeight="44px"
                  marginBottom="12px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Forget Password
                </Title>
                <Description
                  fontSize="20px"
                  lineHeight="27px"
                  alignItems="center"
                  color=" #667085"
                  marginBottom="32px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Provide your email &amp; we’ll be sending you a verification
                  code to reset your password.
                </Description>
                <Form>
                  <FormGroup marginBottom="41px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="6px"
                      textAlign="left"
                      fontSize="14px"
                      color="#151515"
                      fontWeight="600"
                      lineHeight="24px"
                      fontFamily="'DM Sans', sans-serif"
                    >
                      Email ID
                    </FormLabel>
                    <Input
                      borderRadius="100px"
                      fontFamily="'DM Sans', sans-serif"
                      color="#7D8592"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => EmailCheck(e)}
                      onBlur={handleBlur}
                    />
                    {mailErr && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        lineHeight="24px"
                        marginTop="5px"
                        fontFamily="inter"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>
                  <FormGroup marginBottom="40px" display="grid" width="100%">
                    <Button
                      borderRadius="100px"
                      background="#245C66"
                      hoverBackColor="#16424A"
                      fontFamily="'DM Sans', sans-serif"
                      padding="18px 125px 18px 125px"
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="22px"
                      letterSpacing="1.1px"
                      disabled={btndis}
                      onClick={(e) => HandleDispatch(e)}
                    >
                      Send Verification Code
                    </Button>
                  </FormGroup>

                  <Span>Back to</Span>
                  <Atag
                    color="#1F28EB"
                    borderBottom="1px solid #1F28EB"
                    margin="0px 0px 0px 10px"
                    onClick={(e) => HandleNavigate(e)}
                  >
                    Login
                  </Atag>
                </Form>
              </Div>
            </Div>
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41px"
                width="173px"
              />
            </ImageContainer>
          )}
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div backColor="#FFFFFF" padding="0 20px 0 20px">
            <Div width="100%" display="flex">
              <Div resWidth="100%" textAlign="center" margin="auto">
                <TitleImage
                  src={ResetImage}
                  alt="Email Verified"
                  width="100px"
                  height="80px"
                  marginTop="3%"
                ></TitleImage>
                <Title
                  color="#000000"
                  fontWeight="800"
                  fontSize="24px"
                  lineHeight="44px"
                  marginBottom="6px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Forget Password
                </Title>
                <Description
                  fontSize="14px"
                  lineHeight="27px"
                  alignItems="center"
                  color=" #667085"
                  marginBottom="16px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Provide your email &amp; we’ll be sending you a verification
                  code to reset your password.
                </Description>
                <Form>
                  <FormGroup marginBottom="16px" display="grid" width="100%">
                    <FormLabel
                      marginBottom="6px"
                      textAlign="left"
                      fontSize="14px"
                      color="#151515"
                      fontWeight="400"
                      lineHeight="24px"
                      fontFamily="'DM Sans', sans-serif"
                    >
                      Email ID
                    </FormLabel>
                    <Input
                      borderRadius="100px"
                      height="40px"
                      fontFamily="'DM Sans', sans-serif"
                      color="#7D8592"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => EmailCheck(e)}
                      onBlur={handleBlur}
                    />
                    {mailErr && (
                      <FormLabel
                        marginBottom="0px"
                        textAlign="left"
                        fontSize="14px"
                        color="red"
                        fontWeight="400"
                        lineHeight="24px"
                        marginTop="5px"
                        fontFamily="inter"
                      >
                        {errMsg}
                      </FormLabel>
                    )}
                  </FormGroup>
                  <FormGroup marginBottom="16px" display="grid" width="100%">
                    <Button
                      borderRadius="100px"
                      background="#245C66"
                      hoverBackColor="#16424A"
                      fontFamily="'DM Sans', sans-serif"
                      padding="9px 20px 9px 20px"
                      fontWeight="600"
                      fontSize="14px"
                      lineHeight="22px"
                      letterSpacing="1.1px"
                      disabled={btndis}
                      onClick={(e) => HandleDispatch(e)}
                    >
                      Send Verification Code
                    </Button>
                  </FormGroup>

                  <Span>Back to</Span>
                  <Atag
                    color="#1F28EB"
                    borderBottom="1px solid #1F28EB"
                    margin="0px 0px 0px 10px"
                    onClick={(e) => HandleNavigate(e)}
                  >
                    Login
                  </Atag>
                </Form>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};

export default ForgetPassword;
