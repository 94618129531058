import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";
import vozoLogo from "../../../Assets/images/Logo.png";
import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
} from "../../StyledComponents";
import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
} from "../StyledComponents";
import getFilePath from "../../../Config/path";

import MailImage from "../../../Assets/images/mailPortal.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const InstantSignIn = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  var params = useParams();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  let Mode = useSelector((state) => state.PreLogin.mode);
  const Mode_alt =
    localStorage.getItem("Mode") &&
    Decrypt_Value(localStorage.getItem("Mode"), "vozoportal");
  const text = useSelector((state) => state.PreLogin.logo);
  const emailResponse = useSelector((state) => state.PreLogin.EmailResp);

  const File_URL = getFilePath();

  const HandleSubmit = (e) => {
    e.preventDefault();
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);

    // let mode = Mode
    //   ? Mode
    //   : localStorage.getItem("Mode")
    //   ? Decrypt_Value(localStorage.getItem("Mode"), "vozoportal")
    //   : "old";
    let mode = params.OldOrNew === "Old" ? "old" : "new";
    localStorage.setItem("Mode", Encrypt_Value(mode, "vozoportal"));
    if (email !== "") {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(email) == true) {
        dispatch(GetEmailRes({ type: { email: email }, mode: mode }));
        setModalAlerShow(true);
        setStatus("success");
        setModalMsg("Your details are being verified.");
      } else {
        setMailErr(true);
        setErrMsg("Invalid Email");
      }
    } else {
      setMailErr(true);
      setErrMsg("Please Enter the Email Address");
    }
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };

  useEffect(() => {
    let mode = Mode !== "" ? Mode : Mode_alt;
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);
      if (emailResponse.output === "Account Already Found!") {
        if (mode === "new") {
          Navigate("/InstantSignIn/New");
        } else {
          Navigate("/Login");
        }
      } else {
        Navigate("/VerifyAccount", {
          state: { type: { email } },
        });
      }
    } else if (emailResponse.status === "Failure") {
      setMailErr(true);
      setModalAlerShow(false);
      if (mode === "old") {
        setErrMsg("Please Enter Registered Email");
      } else if (mode === "new") {
        setErrMsg("This Email already exists");
      }
    }
  }, [emailResponse]);

  useEffect(() => {
    setErrMsg("");
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "120vh" : "170vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          <img
            src={patient_portal_front}
            alt="menu-icon"
            left="20px"
            height="100%"
            width="50%"
            style={{ backgroundColor: "#F8FAFB" }}
          ></img>
          <Div width="50%" backColor="#FFFFFF">
            <Div width="400px" textAlign="center" margin="auto">
              {text.length !== 0 && (
                <ImageContainer marginTop="72px" marginBottom="20px">
                  <Image
                    loading="lazy"
                    src={text !== "failed" ? File_URL + text : vozoLogo}
                    height="41px"
                    width="173px"
                  />
                </ImageContainer>
              )}
              <TitleImage
                src={MailImage}
                alt="Email Verified"
                width="124px"
                height="124px"
                marginTop="10%"
              />
              <Title
                fontFamily="'DM Sans', sans-serif"
                color="#000000"
                fontWeight="800"
                fontSize="32px"
                lineHeight="44px"
                marginBottom="12px"
              >
                Enter Email
              </Title>
              <Description
                fontFamily="'DM Sans', sans-serif"
                fontSize="20px"
                lineHeight="27px"
                alignItems="center"
                color=" #667085"
                marginBottom="32px"
              >
                Enter email id to get your verification code
              </Description>
              <Form>
                <FormGroup marginBottom="41px" display="grid" width="100%">
                  <FormLabel
                    marginBottom="6px"
                    textAlign="left"
                    fontSize="14px"
                    color="#151515"
                    fontWeight="600"
                    lineHeight="24px"
                    fontFamily="'DM Sans', sans-serif"
                  >
                    Email address
                  </FormLabel>
                  <Input
                    borderRadius="100px"
                    color="#7D8592"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => EmailCheck(e)}
                    id="sig_emil-inp_ent"
                  />
                  {mailErr && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>
                <FormGroup marginBottom="32px" display="grid" width="100%">
                  <Button
                    background="#245C66"
                    hoverBackColor="#16424A"
                    padding="18px 140px 18px 140px"
                    borderRadius="100px"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="22px"
                    letterSpacing="1.1px"
                    disabled={btndis}
                    onClick={(e) => HandleSubmit(e)}
                    id="sig_sub-btn_cnt"
                  >
                    Continue
                  </Button>
                </FormGroup>
              </Form>
            </Div>
          </Div>
        </>
      ) : (
        <Div marginTop="7%">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41px"
                width="173px"
              />
            </ImageContainer>
          )}
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="100%" backColor="#FFFFFF">
            <Div width="90%" textAlign="center" margin="auto">
              {open ? (
                <>
                  {text.length !== 0 && (
                    <ImageContainer marginTop="72px" marginBottom="65px">
                      <Image
                        loading="lazy"
                        src={text !== "failed" ? File_URL + text : vozoLogo}
                        height="41px"
                        width="173px"
                      />
                    </ImageContainer>
                  )}{" "}
                </>
              ) : (
                ""
              )}
              <TitleImage
                src={MailImage}
                alt="Email Verified"
                width="102px"
                height="80.45px"
                marginTop={open ? "10%" : "5%"}
                marginBottom={open ? "32px" : "16px"}
              />
              <Title
                fontFamily="'DM Sans', sans-serif"
                color="#151515"
                fontWeight="600"
                fontSize={open ? "32px" : "24px"}
                lineHeight="44px"
                marginBottom={open ? "12px" : "0px"}
              >
                Enter Email
              </Title>
              <Description
                fontFamily="'DM Sans', sans-serif"
                fontSize={open ? "20px" : "14px"}
                lineHeight="27px"
                alignItems="center"
                color=" #667085"
                marginBottom={open ? "32px" : "16px"}
              >
                Enter email id to get your verification code
              </Description>
              <Form>
                <FormGroup
                  marginBottom={open ? "41px" : "24px"}
                  display="grid"
                  width="100%"
                >
                  <FormLabel
                    fontFamily="'DM Sans', sans-serif"
                    marginBottom="6px"
                    textAlign="left"
                    fontSize="14px"
                    color="#151515"
                    fontWeight="400"
                    lineHeight="24px"
                  >
                    Email address
                  </FormLabel>
                  <Input
                    borderRadius="100px"
                    color="#7D8592"
                    height="40px"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => EmailCheck(e)}
                    id="sig_emil-inp_ent"
                  />
                  {mailErr && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop="5px"
                      fontFamily="inter"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>
                <FormGroup marginBottom="16px" display="grid" width="100%">
                  <Button
                    background="#245C66"
                    hoverBackColor="#16424A"
                    // className="save_active"
                    padding={
                      open ? "18px 140px 18px 140px" : "9px 100px 9px 100px"
                    }
                    borderRadius={open ? "100px" : "100px"}
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="22px"
                    letterSpacing="1.1px"
                    disabled={btndis}
                    onClick={(e) => HandleSubmit(e)}
                    id="sig_sub-btn_cnt"
                  >
                    Continue
                  </Button>
                </FormGroup>
              </Form>
            </Div>
          </Div>
        </Div>
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </Container>
  );
};

export default InstantSignIn;
