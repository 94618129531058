import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MainContainerLeft = styled.div`
  width: 250px;
  height: 100%;
  background: #fafafa;
  position: fixed;
  top: 0;
  z-index: 1001;
`;
export const MainContainerLeft1 = styled.div`
  width: 40px;
  background: #fafafa;
  position: ${(p) => p.position && p.position};
  top: 0;
  z-index: ${(p) => (p.zIndex ? p.zIndex : "1001")};
`;
export const MainContainerLeft2 = styled.div`
  width: 75%;
  height: 100%;
  background: white;
  position: fixed;
  top: 0px;
  left: 0px;
  border-radius: 8px;
  box-shadow: 10px 4px 12px 0px rgba(0, 0, 0, 0.08);
  z-index: 1001;
`;
export const MainContainerRight = styled.div`
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "250px")};
  height: 100%;
  width: 100%;
`;
