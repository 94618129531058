import { createSlice } from "@reduxjs/toolkit";

export const MessengerSlice = createSlice({
  name: "Messenger",
  initialState: {
    ProviderList: [],
    MessageList: [],
    Loading: false,
    MessageIs: false,
    ImageUrl: "",
    previewdownload: "",
    PinChat: [],
    topicon: false,
  },
  reducers: {
    GetMsgList: (state) => {
      state.Loading = true;
    },
    SetGetMsgList: (state, action) => {
      state.ProviderList = action.payload;
      state.Loading = false;
    },
    getMessagerList: (state) => {
      state.MessageIs = true;
    },
    setMessageList: (state, action) => {
      state.MessageList = action.payload;
    },
    getMessagerSend: (message) => {
      return message;
    },
    setMessageSend: (state, action) => {
      state.MessageList = action.payload;
    },
    getUploadImageUrl: (filedata) => {
      return filedata;
    },
    SetUploadImageUrl: (state, action) => {
      state.ImageUrl = action.payload;
    },
    getPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },
    getUnreadCount: (state) => {
      return state;
    },
    getPinchat: (message) => {
      return message;
    },
    setPinchat: (state, action) => {
      state.PinChat = action.payload;
    },
    gettopicon: (state, action) => {
      state.topicon = action.payload;
    },
  },
});

export const {
  GetMsgList,
  SetGetMsgList,
  getMessagerList,
  setMessageList,
  getMessagerSend,
  SetUploadImageUrl,
  getUploadImageUrl,
  getPreviewDownload,
  setPreviewDownload,
  getUnreadCount,
  getPinchat,
  setPinchat,
  gettopicon,
} = MessengerSlice.actions;

export default MessengerSlice.reducer;
