import styled from "styled-components";
export const BackButton = styled.p`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  padding: 0;

  svg {
    margin-right: 0px;
  }
`;
export const Image = styled.img`
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  color: ${(p) => p.color && p.color};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  cursor: ${(p) => p.cursor && p.cursor};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};

  &.tablerpin_right {
    right: 40px;
    @media (max-width: 820px) {
      right: 50px;
    }
  }
`;
export const InputBox = styled.input`
  margin-left: 12px;
  padding: 8px 12px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  outline: none;
  font-family: "Inter", sans-serif;
  &.mobChatInputMar {
    @media (max-width: 820px) {
      margin-left: 0px;
      padding-left: 0px;
    }
  }
`;
