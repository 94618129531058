import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdCreditCard } from "react-icons/md";
import { toast } from "react-toastify";

import { GetSingleInvoiceData } from "../../../../StateManagement/Reducers/BillingState";
import moment from "moment";
import InvoicePopup from "./InvoicePopup";
import MakePayment from "../../../StyledComponents/MakePayment";
import EmptyData from "../../../StyledComponents/EmptyData";
import Error from "../../../../Modules/StyledComponents/Toastify/Error";

import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableHeadCell,
  TableBodyCell,
  Button,
  CardContent,
  CardView,
  CardFooter,
} from "../../../StyledComponents";
import { Div } from "../../Styles";
import { AvatarFrame, AvatarTxt, TxtSpan, StyledImage } from "../styles";
import StripeAlert from "../../../StyledComponents/StripeAlert";
import getFilePath from "../../../../Config/path";

const InvoiceBalance = ({ InvoiceList }) => {
  const dispatch = useDispatch();
  const FileURLProfile = getFilePath();

  const [invoiceModal, setInvoiceModal] = useState(false);
  const [MakePay, setMakePay] = useState(false);
  const [InvoiceBalanceAmt, setInvoiceBalanceAmt] = useState(0);
  const [OneInvoiceDetail, setOneInvoiceDetail] = useState([]);
  const [invId, setInvId] = useState("");
  const [btnDisable, setBtnDis] = useState(false);
  const [buttonDisable, setButtonDis] = useState(false);
  const CardDetails = useSelector((state) => state.Billing.CardDetail);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const DefaultCardDetails = CardDetails.map(
    (card) => card.def === "1" && card.id
  );

  const ViewInvoice = (InvoiceID) => {
    setButtonDis(true);
    setTimeout(() => {
      setButtonDis(false);
    }, 1000);
    dispatch(GetSingleInvoiceData(InvoiceID));
    setInvId(InvoiceID);
    setInvoiceModal(true);
  };

  const InvoicePayment = (InvoiceID) => {
    if (stripeAllowed === false) {
      setModalAlerShow(true);
    } else {
      setBtnDis(true);
      setTimeout(() => {
        setBtnDis(false);
      }, 1000);
      if (CardDetails.length > 0) {
        let OneInvoice = InvoiceList.filter(
          (Invoiceitm) => Invoiceitm.invoice_id === InvoiceID
        );
        setOneInvoiceDetail(OneInvoice);
        setInvoiceBalanceAmt(OneInvoice[0].total_balance);
        setMakePay((prevState) => !prevState);
      } else {
        toast(<Error msg={`Add Your Card Details then Make a Payment`} />, {
          containerId: "B",
          className: "error_badge",
        });
      }
    }
  };
  const handleModalClick = () => {
    setModalAlerShow(false);
  };
  useEffect(() => {
    dispatch(GetSingleInvoiceData());
  }, []);
  return (
    <Div>
      <Table>
        <TableHead>
          <TableRow
            display="grid"
            tempColumns="25% 15% 15% 15% 30%"
            background="#F7F7F7"
          >
            <TableHeadCell padding="16px 0px 16px 38px">Provider</TableHeadCell>
            <TableHeadCell padding="16px 0px 16px 38px">
              Generated On
            </TableHeadCell>
            <TableHeadCell padding="16px 0px 16px 38px">Invoice</TableHeadCell>
            <TableHeadCell padding="16px 0px 16px 38px">Balance</TableHeadCell>
            <TableHeadCell padding="16px 0px 16px 38px">Action</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {InvoiceList.length !== 0 ? (
            <>
              {InvoiceList.map((row, ind) => (
                <TableRow
                  display="grid"
                  tempColumns="25% 15% 15% 15% 30%"
                  key={ind}
                >
                  <TableBodyCell padding="16px 0px 16px 38px" bodered>
                    <Div
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <AvatarFrame>
                        {row.Providerimage ? (
                          <StyledImage
                            src={`${FileURLProfile}${row.Providerimage}`}
                            alt={`Avatar ${ind}`}
                            width="40px"
                            height="30px"
                            borderRadius="50%"
                          />
                        ) : (
                          <AvatarTxt>{row.invo_fname_split}</AvatarTxt>
                        )}
                      </AvatarFrame>
                      <TxtSpan>{row.providerName}</TxtSpan>
                    </Div>
                  </TableBodyCell>

                  <TableBodyCell
                    marginTop="8px"
                    bodered
                    padding="16px 0px 16px 38px"
                  >
                    <TxtSpan>
                      {row.invoice_date
                        ? moment(row.invoice_date).format("MM/DD/YYYY")
                        : ""}
                    </TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell
                    marginTop="8px"
                    bodered
                    padding="16px 0px 16px 38px"
                  >
                    <TxtSpan color="#407BFF">{row.invoice_id}</TxtSpan>
                  </TableBodyCell>
                  <TableBodyCell
                    marginTop="8px"
                    bodered
                    padding="16px 0px 16px 38px"
                  >
                    <TxtSpan>
                      {parseInt(row.total_balance) !== 0
                        ? "$ " + row.total_balance
                        : "$ 0.00"}
                    </TxtSpan>
                  </TableBodyCell>

                  <TableBodyCell bodered padding="16px 0px 16px 38px">
                    <Div
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      width="100%"
                    >
                      <Button
                        width="40%"
                        maxwidth="92px"
                        color="#2C7BE5"
                        background="#FFF"
                        border="1px solid #2C7BE5"
                        borderRadius="4px"
                        height="34px"
                        fontWeight="600"
                        marginRight="16px"
                        hoverBackColor="rgba(244, 246, 249, 0.75)"
                        className="close_active"
                        disabled={buttonDisable}
                        onClick={() => ViewInvoice(row)}
                      >
                        View
                      </Button>
                      <Button
                        width="40%"
                        maxwidth="95px"
                        color={
                          parseInt(row.total_balance) === 0
                            ? "#CED5E0"
                            : "#2C7BE5"
                        }
                        border={
                          parseInt(row.total_balance) === 0
                            ? "1px solid #CED5E0"
                            : "1px solid #2C7BE5"
                        }
                        borderRadius="4px"
                        hoverBackColor="rgba(244, 246, 249, 0.75)"
                        background="#FFFFFF"
                        height="34px"
                        fontWeight="600"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => InvoicePayment(row.invoice_id)}
                        disabled={
                          parseInt(row.total_balance) === 0 || btnDisable
                        }
                        className="close_active"
                        cursor={
                          parseInt(row.total_balance) === 0 && "not-allowed"
                        }
                      >
                        <MdCreditCard style={{ marginRight: 9 }} />
                        Pay
                      </Button>
                    </Div>
                  </TableBodyCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableBodyCell colSpan={5} textAlign="center">
                <EmptyData />
              </TableBodyCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* Card View (Mobile) */}
      {InvoiceList.length > 0 ? (
        InvoiceList.map((row, ind) => (
          <CardView key={ind}>
            {/* <CardHeader>{row.vendor}</CardHeader> */}
            <CardContent>
              <Div display="flex" justifyContent="space-between">
                <Div display="flex">
                  <Div display="flex" alignItems="center">
                    <AvatarFrame>
                      {row.Providerimage ? (
                        <StyledImage
                          src={`${FileURLProfile}${row.Providerimage}`}
                          alt={`Avatar ${ind}`}
                          width="40px"
                          height="40px"
                          borderRadius="10px"
                        />
                      ) : (
                        <AvatarTxt>{row.invo_fname_split}</AvatarTxt>
                      )}
                    </AvatarFrame>
                  </Div>

                  <Div>
                    <TxtSpan>{row.providerName}</TxtSpan>
                    <Div style={{ marginTop: "5px" }}>
                      <TxtSpan color="#407BFF">{row.invoice_id}</TxtSpan>
                      <TxtSpan color="rgba(146, 147, 148, 1)">
                        -
                        {row.invoice_date
                          ? moment(row.invoice_date).format("MM/DD/YYYY")
                          : ""}
                      </TxtSpan>
                    </Div>
                  </Div>
                </Div>
                <Div>
                  <TxtSpan>
                    {parseInt(row.total_balance) !== 0
                      ? "$ " + parseInt(row.total_balance)
                      : "$ 0.00"}
                  </TxtSpan>
                </Div>
              </Div>
              <Div></Div>
            </CardContent>
            <CardFooter>
              <Div
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                width="100%"
                className="mobCardBut"
              >
                <Button
                  width="50%"
                  color="#2C7BE5"
                  background="#FFF"
                  border="1px solid #2C7BE5"
                  borderRadius="100px"
                  height="40px"
                  fontWeight="600"
                  marginRight="16px"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  className="close_active mobCardButTxt"
                  disabled={buttonDisable}
                  onClick={() => ViewInvoice(row)}
                >
                  View
                </Button>
                <Button
                  width="50%"
                  color={
                    parseInt(row.total_balance) === 0 ? "#CED5E0" : "#2C7BE5"
                  }
                  border={
                    parseInt(row.total_balance) === 0
                      ? "1px solid #CED5E0"
                      : "1px solid #2C7BE5"
                  }
                  borderRadius="100px"
                  hoverBackColor="rgba(244, 246, 249, 0.75)"
                  background="#FFFFFF"
                  height="40px"
                  fontWeight="600"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => InvoicePayment(row.invoice_id)}
                  disabled={parseInt(row.total_balance) === 0 || btnDisable}
                  className="close_active"
                  cursor={parseInt(row.total_balance) === 0 && "not-allowed"}
                >
                  <MdCreditCard style={{ marginRight: 9 }} />
                  Pay
                </Button>
              </Div>
            </CardFooter>
          </CardView>
        ))
      ) : window.innerWidth < 800 ? (
        <EmptyData isMob={true} />
      ) : null}

      {MakePay && (
        <MakePayment
          show={MakePay}
          ModalHide={() => setMakePay(false)}
          makepayment="no"
          InvoiceList={OneInvoiceDetail}
          defaultcardcheck={DefaultCardDetails[0]}
          CardDetails={CardDetails}
          patient_balance={InvoiceBalanceAmt}
        />
      )}

      {invoiceModal && (
        <InvoicePopup
          show={invoiceModal}
          Data={invId}
          cardDetails={CardDetails}
          defaultcardcheck={DefaultCardDetails[0]}
          onHide={() => setInvoiceModal(false)}
        />
      )}
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </Div>
  );
};

export default InvoiceBalance;
