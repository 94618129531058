import styled from "styled-components";
import visa from "../../../Assets/images/visaindex0.png";
import master from "../../../Assets/images/masterindex0.png";
import discover from "../../../Assets/images/discoverindex0.png";
import diners from "../../../Assets/images/dinersindex0.png";
import amex from "../../../Assets/images/amexindex0.png";
import visa1 from "../../../Assets/images/visaindex1.png";
import master1 from "../../../Assets/images/masterindex1.png";
import discover1 from "../../../Assets/images/discoverindex1.png";
import diners1 from "../../../Assets/images/dinersindex1.png";
import amex1 from "../../../Assets/images/amexindex1.png";

const backgroundUrl = (cardType, i) => {
  let cardType1 = cardType.replace(/ /g, "").toLowerCase();
  switch (cardType1) {
    case "visa":
      if (i !== 0) {
        return visa;
      } else {
        return visa1;
      }
      break;
    case "americanexpress":
    case "americanexpresscorporate":
      if (i !== 0) {
        return amex;
      } else {
        return amex1;
      }
      break;
    case "mastercard":
    case "mastercard2":
      if (i !== 0) {
        return master;
      } else {
        return master1;
      }
      break;
    case "discover":
    case "disco2":
    case "disco3":
      if (i !== 0) {
        return discover;
      } else {
        return discover1;
      }
      break;
    case "dinersclub":
      if (i !== 0) {
        return diners;
      } else {
        return diners1;
      }
      break;
    default:
      return "none";
  }
};

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  display: block;
  background-color: rgba(102, 112, 133, 0.4);
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardRadioBox = styled.input`
  height: 30px;
  width: 30px;
  border: 0.5px solid #d8e0f0;
  cursor: pointer;
 ${(props) =>
   props.checked &&
   `
  &:checked::after {
    content: "";
    background: white;
    border: 5px solid #407BFF;
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-top: 6px;
    margin-left: 1px;
  }
    `}
@media screen and (max-width: 800px) {
     height: 20px;
  width: 20px;
  margin-right:10px;
 ${(props) =>
   props.checked &&
   `
  &:checked::after {
    content: "";
    background: white;
    border: 5px solid #407BFF;
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-top: 1px;
    margin-left: 1px;
  }
  }
     `}
`;

export const H1 = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color && props.color};
`;

export const TableData = styled.span`
  font-family: "Inter";
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.color && props.color};
  margin-right: ${(props) => props.marginRight && props.marginRight};
`;

export const Flex = styled.div`
  height: ${(props) => props.height && props.height};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  display: ${(props) => (props.display ? props.display : "flex")};
  justify-content: space-between;
`;

export const CardNum = styled.span`
  width: 130px;
  height: 13.87px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 2px;
  color: ${(props) => (props.selected === 1 ? "#FFFFFF" : "#2D3748")};
  opacity: 0.9;
  margin-left: 10px;
  @media screen and (max-width: 500px) {
    letter-spacing: 1px;
    font-size: 8px;
  }
`;

export const Tabs = styled.div`
  width: ${(props) => (props.width ? props.width : "160px")};
  padding: ${(props) => (props.padding ? props.padding : "8px 15px 8px 12px")};
  height: 48px;
  cursor: pointer;
  margin-bottom: 8px;
  font-family: "inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  margin-right: 20px;
  border: ${(props) =>
    props.selected ? "1px solid #2c7be5" : "1px solid #d8e0f0"};
  border-radius: 6px;
`;

export const CardBrand = styled.img.attrs((props) => ({
  src: backgroundUrl(props.img, props.selected),
}))`
  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  border: ${(props) => props.border && props.border};
  cursor: pointer;
  object-fit: contain;
  margin-top: -5px;
  min-width: 33px;
  max-width: 40px;
  z-index: 1;
  @media screen and (max-width: 500px) {
    min-width: 28px;
  }
`;
export const CardNumber = styled.div`
  height: 17px;
  width: ${(props) => (props.width ? props.width : "82px")};
  margin: ${(props) => (props.margin ? props.margin : "8px 18px 8px 9px")};
  font-family: "inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.94px;
  color: "#212121";
  cursor: pointer;
`;

export const SelectCardContainer = styled.div`
  width: ${(p) => (p.width ? p.width : "50%")};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
`;

export const CardContainer = styled.div`
  width: ${(p) => (p.width ? p.width : "164px")};
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  background: ${(props) =>
    props.selected === 0
      ? "#FFFFFF"
      : "linear-gradient(104deg, #194bfb 6.07%, #3a49f9 93.22%)"};
  border-radius: 8px;
  border: 1px solid #3f8cff;
  position: relative;
`;

export const OuterCircle = styled.div`
  width: 141px;
  aspect-ratio: 1;
  position: absolute;
  display: inline-block;
  inset: 0;
  top: -24px;
  left: 99px;
  background: ${(props) =>
    props.selected === 0
      ? "linear-gradient(96.77deg, #9bbaff -58.89%, rgba(255, 255, 255, 0) 40.03%)"
      : "linear-gradient(96.77deg, #FFFFFF -58.89%, rgba(255, 255, 255, 0) 40.03%)"};
  border-radius: 50%;
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - 19px),
    #000 calc(100% - 18px)
  );
`;

export const InnerCircle = styled.div`
  content: "";
  width: 129px;
  position: absolute;
  inset: 0;
  display: block;
  top: 3px;
  left: 132px;
  background: ${(props) =>
    props.selected === 0
      ? "linear-gradient(96.77deg, #9bbaff -58.89%, rgba(255, 255, 255, 0) 40.03%)"
      : "linear-gradient(96.77deg, #FFFFFF -58.89%, rgba(255, 255, 255, 0) 40.03%)"};
  border-radius: 50%;
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - 19px),
    #000 calc(100% - 18px)
  );
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  flex-direction: ${(p) => p.direction && p.direction};
  justify-content: ${(p) => p.justify && p.justify};
  margin: ${(p) => p.margin && p.margin};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  align-items: ${(p) => p.alignItems && p.alignItems};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
`;

export const CardHoldTxt = styled.span`
  height: 10.9px;
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected === 0 ? "#2D3748" : "#FFFFFF")};
  opacity: 0.8;
`;

export const TotalAmountSpan = styled.span`
  width: 65px;
  height: 11px;
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #a7b0bd;
  margin-top: 12px;
  margin-left: 12px;
`;

export const HoldName = styled.span`
  height: 11px;
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: ${(props) => (props.selected === 0 ? "#2D3748" : "#FFFFFF")};
  opacity: 0.9;
`;

export const TotalAmountContainer = styled.div`
  width: ${(p) => (p.width ? p.width : "164px")};

  height: 62px;
  border: 1px solid #407bff;
  border-radius: 6px;
  margin-top: ${(props) => (props.mt ? props.mt : "16px")};
`;

export const TotalAmountInput = styled.input`
  width: 100px;
  height: 19px;
  background: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 8px;
  margin-left: 12px;
  border: none;

  &:focus {
    outline: none;
  }
  @media screen and (max-width: 300px) {
    width: 80px;
  }
`;

export const InvoiceContainer = styled.div`
  overflow: auto;
  max-height: 160px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "10px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "15px"};
`;

export const ModalDialog = styled.div`
  width: ${(props) => (props.width ? props.width : "398px")};
  background: #ffffff;
  position: relative;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.195504);
  border-radius: 12px;
  transition: transform 3s;
  animation: ${(p) =>
    p.animation ? "show_1 0.1s ease-in-out" : "show_2 0.1s ease-in-out"};
  @keyframes show_1 {
    from {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes show_2 {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  }
`;
