import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  GetMakepayment,
  GetInvoiceList,
  GetPaymentHistory,
  setStripefailed,
} from "../../../StateManagement/Reducers/BillingState";

import {
  Image,
  Button,
  ModalHeader,
  ModalBody,
  ModalTitle,
  Modal,
  Text,
  ModalContainer,
} from "../../StyledComponents";

import {
  CardRadioBox,
  H1,
  TableData,
  Flex,
  CardNum,
  Tabs,
  CardBrand,
  CardNumber,
  SelectCardContainer,
  CardContainer,
  OuterCircle,
  InnerCircle,
  Div,
  CardHoldTxt,
  TotalAmountSpan,
  HoldName,
  TotalAmountContainer,
  TotalAmountInput,
  InvoiceContainer,
  ModalDialog,
} from "./styles";

import { BsXLg } from "react-icons/bs";
import ChipPin from "../../../Assets/images/Group.png";
import StripeAlert from "../StripeAlert";

const MakePayment = (props) => {
  //------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const [CardShowData, setCardShowData] = useState("");
  const [RadioBoxCheck, setRadioBoxCheck] = useState("");
  const [ModalAnimat] = useState(true);
  const invoicelist = props.InvoiceList;
  const invoicelistmargin = props.InvoiceList.length > 5 ? "10px" : "0px";
  const CardDetails = props.CardDetails ? props.CardDetails : [];
  const [btnDisable, setBtnDis] = useState(false);
  const [cardError, setcardError] = useState(false);
  const [stripeFail, setStripeFail] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [openwidth, setOpenwidth] = useState(false);

  const Refresh = useSelector((state) => state.Billing.Refresh);
  const stripeFails = useSelector((state) => state.Billing.stripeFailed);
  //------------------------------ State And Variables End------------------------ //

  //------------------------------ Functions Start------------------------ //

  const CardSelectAction = (row) => {
    setcardError(false);
    setCardShowData(row);
    setRadioBoxCheck(row.id);
  };

  const AddPayment = (e) => {
    e.preventDefault();
    if (RadioBoxCheck !== "" && RadioBoxCheck !== false) {
      setcardError(false);
    } else {
      setcardError(true);
      return false;
    }
    setBtnDis(true);
    if (invoicelist.length === 1) {
      dispatch(
        GetMakepayment({
          amount: props.patient_balance,
          card_Id: RadioBoxCheck,
          invoice_id: invoicelist[0].invoice_id,
          makepayment: "no",
        })
      );
    } else if (invoicelist.length > 1) {
      dispatch(
        GetMakepayment({
          card_Id: RadioBoxCheck,
          invoice_data: invoicelist,
          makepayment: props.makepayment,
          balance: props.patient_balance,
        })
      );
    }
  };
  const handleModalClick = () => {
    setModalAlerShow(false);
    dispatch(setStripefailed(false));
  };
  //------------------------------ Functions End------------------------ //

  useEffect(() => {
    if (Refresh === 5) {
      props.ModalHide();
      dispatch(GetInvoiceList());
      dispatch(GetPaymentHistory());
    }

    if (stripeFails === true) {
      setStripeFail(true);
      setModalAlerShow(true);
    } else {
      setStripeFail(false);
      setModalAlerShow(false);
    }
    setRadioBoxCheck(props.defaultcardcheck);
  }, [dispatch, Refresh, props, stripeFails]);
  useEffect(() => {
    setcardError(false);
  }, []);
  useEffect(() => {
    const handleMediaChange = () => {
      const width = window.innerWidth;

      if (width < 500) {
        setOpen(false);
        setOpenwidth(false);
      } else if (width >= 500 && width <= 800) {
        setOpen(false);
        setOpenwidth(true);
      } else {
        setOpen(true);
        setOpenwidth(true);
      }
    };
    window.addEventListener("resize", handleMediaChange);

    handleMediaChange();
    return () => {
      window.removeEventListener("resize", handleMediaChange);
    };
  }, []);
  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.show]);
  return (
    <>
      <Modal width="100%" height="100%" show={props.show}>
        <ModalContainer justifyContent="center">
          <ModalDialog
            width={openwidth ? "398px" : open ? "398px" : "85%"}
            animation={ModalAnimat}
          >
            <ModalHeader
              padding={
                openwidth
                  ? "20px 20px 20px 21px"
                  : open
                  ? "20px 20px 20px 121px"
                  : "20px 20px 16px 20px"
              }
              borderLess
              justifyContent="space-between"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0A1629"
              >
                Make a Payment
              </ModalTitle>

              <BsXLg
                style={{
                  color: "#407BFF",
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={() => {
                  props.ModalHide();
                  dispatch(setStripefailed(false));
                }}
              />
            </ModalHeader>
            <ModalBody>
              <Div padding={open ? "0px 20px" : "0px 20px"}>
                <H1 color="#000000">Invoice</H1>

                <InvoiceContainer
                  marginTop={openwidth ? "10px" : "8px"}
                  marginBottom={openwidth ? "15px" : "0px"}
                >
                  {invoicelist.map((row, ind) => (
                    <Flex key={ind} marginTop="2px" height="30px">
                      <TableData color="#407bff" fontWeight="500">
                        {row.invoice_id}
                      </TableData>
                      <TableData
                        color="#212121"
                        fontWeight="600"
                        marginRight={invoicelistmargin}
                      >
                        $ {row.total_balance}
                      </TableData>
                    </Flex>
                  ))}
                </InvoiceContainer>

                <Flex marginTop={openwidth ? "2px" : "2px"}>
                  <H1>Choose Method </H1>
                </Flex>
                {openwidth ? (
                  <Flex marginTop="12px">
                    <SelectCardContainer>
                      {CardDetails.map((row, ind) => (
                        <Tabs
                          width={open ? "160px" : "100%"}
                          padding={
                            open ? "8px 15px 8px 12px" : "8px 8px 8px 8px"
                          }
                          onClick={() => CardSelectAction(row)}
                          selected={RadioBoxCheck === row.id ? true : false}
                          key={ind}
                        >
                          <Div alignItems="center" display="flex">
                            <CardBrand
                              height={openwidth ? "32px" : "28px"}
                              width={openwidth ? "32px" : "28px"}
                              borderRadius="50%"
                              border="0.5px solid #d8e0f0"
                              img={row.cardType}
                              selected={ind + 1}
                            />
                            <CardNumber
                              width={openwidth ? "210px" : "100%"}
                              margin={
                                open ? "8px 18px 8px 9px" : "8px 2px 8px 8px"
                              }
                              selected={ind + 1}
                              name="card"
                            >
                              {row.number.slice(-7)}
                            </CardNumber>
                            <CardRadioBox
                              checked={RadioBoxCheck === row.id ? true : false}
                              name="radio"
                              type="radio"
                            />
                          </Div>
                        </Tabs>
                      ))}
                    </SelectCardContainer>

                    <SelectCardContainer paddingLeft={open ? "10px" : "4px"}>
                      {CardShowData !== "" ? (
                        <CardContainer
                          width={open ? "164px" : "96%"}
                          selected={CardShowData.def === "1" ? 1 : 0}
                        >
                          <Div
                            margin="0px 10px 0px 10px"
                            display="flex"
                            height="25px"
                            justify="space-between"
                          >
                            <Image
                              loading="lazy"
                              height="17px"
                              width="27px"
                              src={ChipPin}
                            />

                            <CardBrand
                              height="33px"
                              img={CardShowData.cardType}
                              selected={CardShowData.def === "1" ? 0 : 1}
                            />
                          </Div>

                          <CardNum selected={CardShowData.def === "1" ? 1 : 0}>
                            {CardShowData.number}
                          </CardNum>

                          <Div
                            display="flex"
                            margin="5px 10px 0px 10px"
                            alignItems="center"
                            justify="space-between"
                            marginTop="5px"
                          >
                            <CardHoldTxt
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              Card Holder
                            </CardHoldTxt>
                            <CardHoldTxt
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              Valid
                            </CardHoldTxt>
                          </Div>

                          <Div
                            display="flex"
                            margin="3px 10px 0px 10px"
                            alignItems="center"
                            justify="space-between"
                            marginTop="5px"
                          >
                            <HoldName
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              {CardShowData.name}
                            </HoldName>
                            <HoldName
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              {CardShowData.valid}
                            </HoldName>
                          </Div>

                          <OuterCircle
                            selected={CardShowData.def === "1" ? 1 : 0}
                          />
                          <InnerCircle
                            selected={CardShowData.def === "1" ? 1 : 0}
                          />
                        </CardContainer>
                      ) : (
                        CardDetails.map((row, ind) =>
                          row.def === "1" ? (
                            <CardContainer
                              key={ind}
                              selected={1}
                              width={open ? "164px" : "96%"}
                            >
                              <Div
                                margin="0px 10px 0px 10px"
                                display="flex"
                                height="25px"
                                justify="space-between"
                              >
                                <Image
                                  loading="lazy"
                                  height="17px"
                                  width="27px"
                                  src={ChipPin}
                                />

                                <CardBrand
                                  height="33px"
                                  img={row.cardType}
                                  selected={0}
                                />
                              </Div>

                              <CardNum selected={1}>{row.number}</CardNum>

                              <Div
                                display="flex"
                                margin="5px 10px 0px 10px"
                                alignItems="center"
                                justify="space-between"
                                marginTop="5px"
                              >
                                <CardHoldTxt selected={1}>
                                  Card Holder
                                </CardHoldTxt>
                                <CardHoldTxt selected={1}>Valid</CardHoldTxt>
                              </Div>

                              <Div
                                display="flex"
                                margin="3px 10px 0px 10px"
                                alignItems="center"
                                justify="space-between"
                                marginTop="5px"
                              >
                                <HoldName selected={1}>{row.name}</HoldName>
                                <HoldName selected={1}>{row.valid}</HoldName>
                              </Div>

                              <OuterCircle selected={1} />
                              <InnerCircle selected={1} />
                            </CardContainer>
                          ) : (
                            ""
                          )
                        )
                      )}
                      <TotalAmountContainer
                        width={open ? "164px" : "96%"}
                        mt={
                          RadioBoxCheck !== "" && RadioBoxCheck !== false
                            ? "16px"
                            : "auto"
                        }
                      >
                        <TotalAmountSpan>Amount</TotalAmountSpan>
                        <TotalAmountInput
                          value={"$ " + props.patient_balance}
                          disabled={props.makepayment === "yes" && true}
                        ></TotalAmountInput>
                      </TotalAmountContainer>
                    </SelectCardContainer>
                  </Flex>
                ) : (
                  <Flex display="inherit" marginTop="12px">
                    <SelectCardContainer width="100%">
                      {CardDetails.map((row, ind) => (
                        <Tabs
                          width={open ? "160px" : "100%"}
                          padding={
                            open ? "8px 15px 8px 12px" : "8px 8px 8px 8px"
                          }
                          onClick={() => CardSelectAction(row)}
                          selected={RadioBoxCheck === row.id ? true : false}
                          key={ind}
                        >
                          <Div
                            alignItems="center"
                            display="flex"
                            justify="space-between"
                          >
                            <Div display="flex" alignItems="center">
                              <CardBrand
                                height={openwidth ? "32px" : "28px"}
                                width={openwidth ? "32px" : "28px"}
                                borderRadius="50%"
                                border="0.5px solid #d8e0f0"
                                img={row.cardType}
                                selected={ind + 1}
                              />
                              <CardNumber
                                width={openwidth ? "82px" : "102px"}
                                margin={
                                  open ? "8px 18px 8px 9px" : "8px 2px 8px 8px"
                                }
                                selected={ind + 1}
                                name="card"
                              >
                                {row.number.slice(-7)}
                              </CardNumber>
                            </Div>
                            <CardRadioBox
                              checked={RadioBoxCheck === row.id ? true : false}
                              name="radio"
                              type="radio"
                            />
                          </Div>
                        </Tabs>
                      ))}
                    </SelectCardContainer>

                    <SelectCardContainer
                      width="100%"
                      paddingLeft={open ? "10px" : "4px"}
                    >
                      {CardShowData !== "" ? (
                        <CardContainer
                          width={open ? "164px" : "100%"}
                          selected={CardShowData.def === "1" ? 1 : 0}
                        >
                          <Div
                            margin="0px 10px 0px 10px"
                            display="flex"
                            height="25px"
                            justify="space-between"
                          >
                            <Image
                              loading="lazy"
                              height="17px"
                              width="27px"
                              src={ChipPin}
                            />

                            <CardBrand
                              height="33px"
                              img={CardShowData.cardType}
                              selected={CardShowData.def === "1" ? 0 : 1}
                            />
                          </Div>

                          <CardNum selected={CardShowData.def === "1" ? 1 : 0}>
                            {CardShowData.number}
                          </CardNum>

                          <Div
                            display="flex"
                            margin="5px 10px 0px 10px"
                            alignItems="center"
                            justify="space-between"
                            marginTop="5px"
                          >
                            <CardHoldTxt
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              Card Holder
                            </CardHoldTxt>
                            <CardHoldTxt
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              Valid
                            </CardHoldTxt>
                          </Div>

                          <Div
                            display="flex"
                            margin="3px 10px 0px 10px"
                            alignItems="center"
                            justify="space-between"
                            marginTop="5px"
                          >
                            <HoldName
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              {CardShowData.name}
                            </HoldName>
                            <HoldName
                              selected={CardShowData.def === "1" ? 1 : 0}
                            >
                              {CardShowData.valid}
                            </HoldName>
                          </Div>

                          <OuterCircle
                            selected={CardShowData.def === "1" ? 1 : 0}
                          />
                          <InnerCircle
                            selected={CardShowData.def === "1" ? 1 : 0}
                          />
                        </CardContainer>
                      ) : (
                        CardDetails.map((row, ind) =>
                          row.def === "1" ? (
                            <CardContainer
                              key={ind}
                              selected={1}
                              width={open ? "164px" : "100%"}
                            >
                              <Div
                                margin="0px 10px 0px 10px"
                                display="flex"
                                height="25px"
                                justify="space-between"
                              >
                                <Image
                                  loading="lazy"
                                  height="17px"
                                  width="27px"
                                  src={ChipPin}
                                />

                                <CardBrand
                                  height="33px"
                                  img={row.cardType}
                                  selected={0}
                                />
                              </Div>

                              <CardNum selected={1}>{row.number}</CardNum>

                              <Div
                                display="flex"
                                margin="5px 10px 0px 10px"
                                alignItems="center"
                                justify="space-between"
                                marginTop="5px"
                              >
                                <CardHoldTxt selected={1}>
                                  Card Holder
                                </CardHoldTxt>
                                <CardHoldTxt selected={1}>Valid</CardHoldTxt>
                              </Div>

                              <Div
                                display="flex"
                                margin="3px 10px 0px 10px"
                                alignItems="center"
                                justify="space-between"
                                marginTop="5px"
                              >
                                <HoldName selected={1}>{row.name}</HoldName>
                                <HoldName selected={1}>{row.valid}</HoldName>
                              </Div>

                              <OuterCircle selected={1} />
                              <InnerCircle selected={1} />
                            </CardContainer>
                          ) : (
                            ""
                          )
                        )
                      )}
                      <TotalAmountContainer
                        width={open ? "164px" : "100%"}
                        mt={
                          RadioBoxCheck !== "" && RadioBoxCheck !== false
                            ? "16px"
                            : "auto"
                        }
                      >
                        <TotalAmountSpan>Amount</TotalAmountSpan>
                        <TotalAmountInput
                          value={"$ " + props.patient_balance}
                          disabled={props.makepayment === "yes" && true}
                        ></TotalAmountInput>
                      </TotalAmountContainer>
                    </SelectCardContainer>
                  </Flex>
                )}

                {cardError && (
                  <Text
                    color="#F65160"
                    margin="2px 0px 0px 0px"
                    fontSize="12px"
                    width="100%"
                    textAlign="left"
                  >
                    Select any One Card
                  </Text>
                )}
                <Button
                  marginTop="20px"
                  width={open ? "358px" : "100%"}
                  marginBottom="20px"
                  height="32px"
                  background="#2c7be5"
                  hoverBackColor="#005FB2"
                  color="#fff"
                  fontFamily="'Work Sans', sans-serif"
                  fontWeight="500"
                  fontSize="14px"
                  borderRadius="4px"
                  cursor="pointer"
                  className="save_active"
                  onClick={AddPayment}
                  disabled={btnDisable}
                  id="dbrd_mkPay-btn_pyNw"
                >
                  Pay Now
                </Button>
              </Div>
            </ModalBody>
          </ModalDialog>
        </ModalContainer>
      </Modal>
      {ModalAlerShow && (
        <StripeAlert
          show={ModalAlerShow}
          handleModalClick={handleModalClick}
          stripeFail={stripeFail}
        />
      )}
    </>
  );
};

export default MakePayment;
