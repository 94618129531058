import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { BsXLg, BsDownload } from "react-icons/bs";
import Loading from "../../../StyledComponents/Loading";
import { useReactToPrint } from "react-to-print";
import GeneratePdf from "./reportGenerator";
import { AiOutlinePrinter } from "react-icons/ai";
import getFilePath from "../../../../Config/path";
import {
  Modal,
  ModalContainer,
  ModalHeader,
  SpanCont,
  Div,
  ModalBody,
  Image,
  PageTitle,
  Text,
  TableHead,
  TableHeadCell,
  TableBody,
  Button,
} from "../../../StyledComponents";
import {
  ModalDiag,
  PaidStatus,
  Table,
  TableRow,
  TableBodyCell,
  MobPaidStatus,
} from "../styles";
import MakePayment from "../../../StyledComponents/MakePayment";
import StripeAlert from "../../../StyledComponents/StripeAlert";
import moment from "moment";

const InvoicePopup = (props) => {
  const componentRef = useRef();
  const [MakePay, setMakePay] = useState(false);
  const [OneInvoiceList, setOneInvoiceList] = useState([]);
  const [openwidth, setOpenwidth] = useState(false);
  const [openless420, setOpenless420] = useState(false);

  const File_URL = getFilePath();
  const InvoiceData = useSelector((state) => state.Billing.singleInvoice);
  const Loadings = useSelector((state) => state.Billing.Loading);
  const Ext = useSelector((state) => state.Billing.ext);
  const Base = useSelector((state) => state.Billing.base64);
  const statelogo = useSelector((state) => state.Billing.stateLogo);
  const symbol = useSelector((state) => state.Billing.symbol);
  const [cardDetails, setCardDetails] = useState([]);
  const stripeAllowed = useSelector((state) => state.Billing.stripeAllowed);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDownload = () => {
    GeneratePdf(
      InvoiceData,
      props.Data.invoice_id,
      props.Data.invoice_date,
      props.Data.providerName,
      props.Data.facilityname,
      props.Data.ProviderStreet,
      props.Data.ProviderCity,
      props.Data.ProviderState,
      props.Data.ProviderTaxid,
      props.Data.ProviderEmail,
      props.Data.PatName,
      props.Data.PatCell,
      props.Data.PatStreet,
      props.Data.PatCity,
      props.Data.PatState,
      props.Data.Patzip,
      props.Data.PatEmail,
      props.Data.total_amount,
      props.Data.total_balance,
      Ext,
      Base,
      statelogo,
      symbol
    );
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePayment = () => {
    if (stripeAllowed === false) {
      setModalAlerShow(true);
    } else {
      setMakePay(true);
    }
  };

  const handleModalClick = () => {
    setModalAlerShow(false);
  };

  useEffect(() => {
    let a = [];
    a.push(props.Data);
    setOneInvoiceList(a);
    setCardDetails(props.cardDetails);
  }, [props, InvoiceData]);
  useEffect(() => {
    const handleMediaChange = () => {
      const width = window.innerWidth;
      if (width < 350) {
        setOpen(false);
        setOpenwidth(true);
        setOpenless420(true);
      } else if (width >= 420 && width <= 800) {
        setOpen(false);
        setOpenwidth(false);
        setOpenless420(false);
      } else if (width >= 350 && width <= 420) {
        setOpen(false);
        setOpenwidth(false);
        setOpenless420(true);
      } else {
        setOpen(true);
        setOpenwidth(false);
        setOpenless420(false);
      }
    };
    window.addEventListener("resize", handleMediaChange);

    handleMediaChange();
    return () => {
      window.removeEventListener("resize", handleMediaChange);
    };
  }, []);
  return (
    <>
      {open ? (
        <Modal width="330vw" height="330vh" show={props.show}>
          <ModalContainer
            top="0px"
            borderRadius="0px"
            alignItems="center"
            justifyContent="center"
          >
            {Loadings ? (
              <Loading height="100vh" />
            ) : (
              <ModalDiag width="241vw" animation={true}>
                <ModalHeader borderLess>
                  <Div
                    justifyContent="space-between"
                    display="flex"
                    padding="12px"
                    width="100%"
                    height="100%"
                    boxShadow="0px 1px 8px rgba(20, 46, 110, 0.1)"
                  >
                    <Div padding="16px" className="Invoiceclose">
                      <BsXLg
                        onClick={() => props.onHide()}
                        id="bil_inv-lst_cls"
                      />
                      <SpanCont
                        fontFamily="inter"
                        color="#2D3748;"
                        fontWeight="600"
                        fontSize="20px"
                        lineHeight="24.2px"
                        marginLeft="36px"
                      >
                        Invoice for&nbsp;{props.Data.PatName}
                      </SpanCont>
                    </Div>

                    <Div display="flex">
                      <Div
                        alignItems="center"
                        display="flex"
                        className="Invoiceprinter"
                        style={{ borderRight: "1px solid #EBEAED" }}
                      >
                        <AiOutlinePrinter onClick={HandlePrint} />
                        <BsDownload onClick={handleDownload} />
                      </Div>
                      <Div
                        marginLeft="20px"
                        alignItems="center"
                        display="flex"
                        marginRight="28px"
                      >
                        <Button
                          color="#ffffff"
                          background="#26BF71"
                          border="1px solid #26BF71"
                          borderRadius="4px"
                          padding="7px 11.5px"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="16px"
                          fontFamily="Inter,sans-serif"
                          onClick={handlePayment}
                          disabled={props.Data.total_balance === "0.00" && true}
                        >
                          Add Payment
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </ModalHeader>
                <ModalBody backgroundColor="#f7f7f7" padding="26px 0px">
                  <Div
                    height="auto"
                    backColor="#ffff"
                    boxShadow=" 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05)"
                    borderRadius="6px"
                    width="800px"
                    margin="0px auto"
                    ref={componentRef}
                  >
                    <Div
                      backColor="#f7f7f7"
                      padding="24px 40px 25px 40px"
                      display="flex"
                      justifyContent={statelogo !== "0" && "space-between"}
                      alignItems="center"
                    >
                      {statelogo !== "0" && (
                        <Image
                          loading="lazy"
                          id="canvasportal"
                          width="50px"
                          src={File_URL + Ext}
                        />
                      )}
                      {props.Data.total_balance === "0.00" && (
                        <PaidStatus>PAID</PaidStatus>
                      )}
                      <Div
                        display="block"
                        backColor="none"
                        margin={!(statelogo !== "0") && "0px 0px 0px auto"}
                      >
                        <PageTitle
                          fontWeight="500"
                          fontSize="22px"
                          lineHeight="26.63px"
                          fontFamily="inter"
                        >
                          Invoice
                        </PageTitle>
                        <Div
                          display="flex"
                          backColor="none"
                          margin="16px 0px 0px 0px"
                        >
                          <Div
                            borderRight=" 1px solid #000000"
                            backColor="none"
                            padding="0px 10px 0px 0px"
                          >
                            <Text
                              textAlign="left"
                              mb="16px"
                              fontWeight="400"
                              fontSize="16px"
                              lineHeight="19.36px"
                              mt="0px"
                              fontFamily="inter"
                            >
                              Invoice Number
                            </Text>
                            <Text
                              textAlign="left"
                              fontWeight="400"
                              fontSize="16px"
                              lineHeight="19.36px"
                              fontFamily="inter"
                              mt="0px"
                              mb="0px"
                            >
                              Issue Date
                            </Text>
                          </Div>
                          <Div backColor="none" padding="0px 0px 0px 10px">
                            <Text
                              textAlign="left"
                              mb="16px"
                              fontWeight="400"
                              fontSize="16px"
                              lineHeight="19.36px"
                              mt="0px"
                              fontFamily="'inter',sans-serif"
                            >
                              {props.Data.invoice_id}
                            </Text>
                            <Text
                              textAlign="left"
                              fontWeight="400"
                              fontSize="16px"
                              lineHeight="19.36px"
                              mt="0px"
                              mb="0px"
                              fontFamily="'inter',sans-serif"
                            >
                              {props.Data.invoice_date
                                ? moment(props.Data.invoice_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </Text>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    <Div
                      padding="24px 40px 36px 40px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Div>
                        <PageTitle
                          fontWeight="500"
                          fontSize="16px"
                          lineHeight="19.36px"
                          fontFamily="inter"
                        >
                          Bill From
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="8px"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="7px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.facilityname}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="8px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.ProviderStreet}&nbsp;
                          {props.Data.ProviderCity}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          fontFamily="inter"
                          mb="0px"
                          color="#718096"
                        >
                          {props.Data.ProviderState}
                        </Text>
                      </Div>
                      <Div width="232.3px">
                        <PageTitle
                          fontWeight="500"
                          fontSize="16px"
                          lineHeight="19.36px"
                          fontFamily="inter"
                        >
                          Bill to
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="8px"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="7px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.PatName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="8px"
                          color="#718096"
                          fontFamily="inter"
                        >
                          {props.Data.PatStreet}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="8px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.PatCity}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="0px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.PatState} &nbsp; {props.Data.Patzip}
                        </Text>
                      </Div>
                    </Div>

                    <Div
                      padding="0px 40px 37px 40px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Div>
                        <PageTitle
                          fontWeight="500"
                          fontSize="16px"
                          lineHeight="19.36px"
                          fontFamily="inter"
                        >
                          Provider
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="8px"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="7px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.providerName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="8px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          Tax ID : {props.Data.ProviderTaxid}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          fontFamily="inter"
                          mb="0px"
                          color="#718096"
                        >
                          {props.Data.ProviderEmail}
                        </Text>
                      </Div>
                      <Div width="232.3px">
                        <PageTitle
                          fontWeight="500"
                          fontSize="16px"
                          lineHeight="19.36px"
                          fontFamily="inter"
                        >
                          Client
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="8px"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="7px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.PatName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="8px"
                          color="#718096"
                          fontFamily="inter"
                        >
                          {props.Data.PatCell}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="16.94px"
                          mt="0px"
                          mb="0px"
                          fontFamily="inter"
                          color="#718096"
                        >
                          {props.Data.PatEmail}
                        </Text>
                      </Div>
                    </Div>

                    <Table padding="0px 0px 20px 0px">
                      <TableHead
                        background="#FAFAFA"
                        scroll={InvoiceData.length > 2 ? true : false}
                      >
                        <TableRow>
                          <TableHeadCell
                            fontFamily="inter"
                            fontWeight="500"
                            lineHeight="16.94px"
                            fontSize="14px"
                          >
                            Date
                          </TableHeadCell>
                          <TableHeadCell
                            fontFamily="inter"
                            fontWeight="500"
                            lineHeight="16.94px"
                            fontSize="14px"
                          >
                            Description
                          </TableHeadCell>

                          <TableHeadCell
                            fontFamily="inter"
                            fontWeight="500"
                            lineHeight="16.94px"
                            fontSize="14px"
                          >
                            Balance
                          </TableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        scroll={InvoiceData.length > 2 ? true : false}
                        height={InvoiceData.length > 2 ? "120px" : ""}
                      >
                        {InvoiceData.map((row, i) => (
                          <TableRow
                            key={i}
                            scroll={InvoiceData.length > 2 ? true : false}
                            bbtom={true}
                          >
                            <TableBodyCell
                              fontFamily="inter"
                              fontWeight="400"
                              lineHeight="16.94px"
                              fontSize="14px"
                              color=" #2D3748"
                              padding="16px 40px 16px 40px"
                              bodered
                            >
                              {row.date
                                ? moment(row.date).format("MM/DD/YYYY hh:mm A")
                                : ""}
                            </TableBodyCell>
                            <TableBodyCell
                              fontFamily="inter"
                              fontWeight="400"
                              lineHeight="16.94px"
                              fontSize="14px"
                              color=" #2D3748"
                              padding="16px 40px 16px 40px"
                              bodered
                            >
                              {row.serviceName}
                            </TableBodyCell>

                            <TableBodyCell
                              fontFamily="inter"
                              fontWeight="400"
                              lineHeight="16.94px"
                              fontSize="14px"
                              color=" #2D3748"
                              bodered
                              padding={
                                InvoiceData.length > 2
                                  ? "16px 40px 16px 43px"
                                  : "16px 40px 16px 40px"
                              }
                            >
                              {symbol}
                              {row.fee}
                            </TableBodyCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Div
                      display="flex"
                      justifyContent="flex-end"
                      padding="24px 20px 16px 20px"
                      margin="0px 40px 0px 20px"
                    >
                      <SpanCont
                        lineHeight="19.36px"
                        fontWeight="400"
                        fontFamily="inter"
                        color="#2D3748"
                        width="80%"
                        textAlign="right"
                      >
                        Total:
                      </SpanCont>
                      <SpanCont
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontWeight="500"
                        fontFamily="inter"
                        color=" #000000"
                        width="20%"
                        textAlign="right"
                      >
                        {symbol}
                        {props.Data.total_amount}
                      </SpanCont>
                    </Div>
                    <Div
                      display="flex"
                      justifyContent="flex-end"
                      padding="0px 20px 132px 20px"
                      margin="0px 40px 20px 20px"
                    >
                      <SpanCont
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily="inter"
                        color="#2D3748"
                        width="80%"
                        textAlign="right"
                      >
                        Make Payments to:
                      </SpanCont>
                      <SpanCont
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="19.36px"
                        fontFamily="inter"
                        color="#2D3748"
                        width="20%"
                        textAlign="right"
                      >
                        {props.Data.facilityname}
                      </SpanCont>
                    </Div>
                  </Div>
                </ModalBody>
              </ModalDiag>
            )}
          </ModalContainer>
        </Modal>
      ) : (
        <Modal width="330vw" height="330vh" show={props.show}>
          <ModalContainer
            className="scrollbar_adujust"
            borderRadius="0px"
            justifyContent="center"
          >
            {Loadings && InvoiceData.length === 0 ? (
              <Loading height="100vh" />
            ) : (
              <ModalDiag width="241vw" height="100%" animation={true}>
                <ModalHeader borderLess>
                  <Div
                    justifyContent="space-between"
                    display="flex"
                    padding="12px"
                    width="100%"
                    height="100%"
                    boxShadow="0px 1px 8px rgba(20, 46, 110, 0.1)"
                  >
                    <Div
                      padding="8px 8px 8px 0px"
                      alignItems="center"
                      display="flex"
                      className="Invoicemobclose"
                    >
                      <BsXLg
                        onClick={() => props.onHide()}
                        id="bil_inv-lst_cls"
                      />
                      <SpanCont
                        fontFamily="'DM Sans', sans-serif"
                        color="#000000"
                        fontWeight="600"
                        fontSize="12px"
                        lineHeight="12px"
                        marginLeft="12px"
                      >
                        Invoice for&nbsp;{props.Data.PatName}
                      </SpanCont>
                    </Div>

                    <Div display="flex">
                      <Div
                        alignItems="center"
                        display="flex"
                        style={{ borderRight: open ? "1px solid #EBEAED" : "" }}
                      >
                        <AiOutlinePrinter
                          style={{
                            color: "#718096",
                            cursor: "pointer",
                            height: openless420 ? "16px" : "21px",
                            width: openless420 ? "16px" : "21px",
                            marginRight: "16px",
                          }}
                          onClick={HandlePrint}
                        />
                        <BsDownload
                          style={{
                            color: "#718096",
                            cursor: "pointer",
                            height: openless420 ? "16px" : "21px",
                            width: openless420 ? "16px" : "21px",
                            marginRight: "16px",
                          }}
                          onClick={handleDownload}
                        />
                      </Div>
                      <Div
                        marginLeft="0px"
                        alignItems="center"
                        display="flex"
                        marginRight="8px"
                      >
                        <Button
                          color="#ffffff"
                          background="#26BF71"
                          border="1px solid #26BF71"
                          borderRadius="4px"
                          padding="4px 7px"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="16px"
                          fontFamily="'DM Sans', sans-serif"
                          width="80px"
                          onClick={handlePayment}
                          disabled={props.Data.total_balance === "0.00" && true}
                        >
                          Add Payment
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </ModalHeader>
                <ModalBody
                  backgroundColor="#f7f7f7"
                  padding="0px 0px"
                  height="100%"
                >
                  <Div
                    height="100%"
                    backColor="#ffff"
                    boxShadow=" 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05)"
                    borderRadius="6px"
                    width="100%"
                    margin="0px auto"
                    ref={componentRef}
                  >
                    <Div
                      backColor="#f7f7f7"
                      padding="7px 16px 7px 16px"
                      display="flex"
                      justifyContent={statelogo !== "0" && "space-between"}
                      alignItems="center"
                    >
                      <Div
                        backColor="#f7f7f7"
                        display={open ? "" : openwidth ? "flex" : "contents"}
                        flexDirection={open ? "" : openwidth ? "column" : ""}
                      >
                        {statelogo !== "0" && (
                          <Image
                            loading="lazy"
                            id="canvasportal"
                            width="56px"
                            src={File_URL + Ext}
                          />
                        )}
                        {props.Data.total_balance === "0.00" && (
                          <MobPaidStatus>PAID</MobPaidStatus>
                        )}
                      </Div>
                      <Div
                        display="block"
                        backColor="none"
                        margin={!(statelogo !== "0") && "0px 0px 0px auto"}
                      >
                        <PageTitle
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="18.23px"
                          fontFamily="'DM Sans', sans-serif"
                          color=" #3E3F40"
                        >
                          Invoice
                        </PageTitle>
                        <Div
                          display="flex"
                          backColor="none"
                          margin="4px 0px 0px 0px"
                        >
                          <Div
                            borderRight=" 1px solid #000000"
                            backColor="none"
                            padding="0px 4px 0px 0px"
                          >
                            <Text
                              textAlign="left"
                              mb="4px"
                              fontWeight="400"
                              fontSize="12px"
                              lineHeight="15.62px"
                              mt="4px"
                              fontFamily="'DM Sans', sans-serif"
                              color=" #3E3F40"
                            >
                              Invoice Number
                            </Text>
                            <Text
                              textAlign="left"
                              fontWeight="400"
                              fontSize="12px"
                              lineHeight="15.62px"
                              fontFamily="inter"
                              mt="0px"
                              mb="4px"
                              color=" #3E3F40"
                            >
                              Issue Date
                            </Text>
                          </Div>
                          <Div backColor="none" padding="0px 0px 0px 6px">
                            <Text
                              textAlign="left"
                              mb="4px"
                              fontWeight="400"
                              fontSize="12px"
                              lineHeight="15.62px"
                              mt="4px"
                              fontFamily="'DM Sans', sans-serif"
                              color=" #3E3F40"
                            >
                              {props.Data.invoice_id}
                            </Text>
                            <Text
                              textAlign="left"
                              fontWeight="400"
                              fontSize="12px"
                              lineHeight="15.62px"
                              mt="0px"
                              mb="4px"
                              fontFamily="'DM Sans', sans-serif"
                              color=" #3E3F40"
                            >
                              {props.Data.invoice_date
                                ? moment(props.Data.invoice_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </Text>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    <Div
                      padding="24px 16px 16px 16px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Div width="60%" padding=" 0 5px 0 0">
                        <PageTitle
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="15.62px"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          Bill From
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="4px"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.facilityname}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.ProviderStreet}&nbsp;
                          {props.Data.ProviderCity}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          fontFamily="'DM Sans', sans-serif"
                          mb="0px"
                          color="#747575"
                        >
                          {props.Data.ProviderState}
                        </Text>
                      </Div>
                      <Div width="40%">
                        <PageTitle
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="15.62px"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          Bill to
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="4px"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.PatName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="4px"
                          color="#747575"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          {props.Data.PatStreet}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.PatCity}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="0px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.PatState} &nbsp; {props.Data.Patzip}
                        </Text>
                      </Div>
                    </Div>

                    <Div
                      padding="0px 16px 16px 16px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Div width="60%" padding=" 0 5px 0 0">
                        <PageTitle
                          fontWeight="500"
                          fontSize="12px"
                          lineHeight="15.62px"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          Provider
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="4px"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.providerName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          Tax ID : {props.Data.ProviderTaxid}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          fontFamily="'DM Sans', sans-serif"
                          mb="0px"
                          color="#747575"
                          wordBreak="break-word"
                        >
                          {props.Data.ProviderEmail}
                        </Text>
                      </Div>
                      <Div width="40%">
                        <PageTitle
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="15px"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          Client
                        </PageTitle>

                        <Text
                          textAlign="left"
                          mb="4px"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="4px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                        >
                          {props.Data.PatName}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="4px"
                          color="#747575"
                          fontFamily="'DM Sans', sans-serif"
                        >
                          {props.Data.PatCell}
                        </Text>
                        <Text
                          textAlign="left"
                          fontWeight="500"
                          fontSize="10px"
                          lineHeight="13.02px"
                          mt="0px"
                          mb="0px"
                          fontFamily="'DM Sans', sans-serif"
                          color="#747575"
                          wordBreak="break-all"
                        >
                          {props.Data.PatEmail}
                        </Text>
                      </Div>
                    </Div>

                    <Table padding="0px 0px 20px 0px">
                      <TableHead
                        background="#FAFAFA"
                        scroll={InvoiceData.length > 2 ? true : false}
                      >
                        <TableRow>
                          <TableHeadCell
                            padding="6px 8px 6px 16px"
                            fontFamily="'DM Sans', sans-serif"
                            fontWeight="500"
                            lineHeight="13.02px"
                            fontSize="10px"
                            color="#000000"
                          >
                            Date
                          </TableHeadCell>
                          <TableHeadCell
                            padding="6px 8px 6px 8px"
                            fontFamily="'DM Sans', sans-serif"
                            fontWeight="500"
                            lineHeight="13.02px"
                            fontSize="10px"
                            color="#000000"
                          >
                            Description
                          </TableHeadCell>

                          <TableHeadCell
                            padding="6px 8px 6px 16px"
                            fontFamily="'DM Sans', sans-serif"
                            fontWeight="500"
                            lineHeight="13.02px"
                            fontSize="10px"
                            color="#000000"
                          >
                            Balance
                          </TableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        className="invoicetable"
                        scroll={InvoiceData.length > 2 ? true : false}
                        height={InvoiceData.length > 2 ? "120px" : ""}
                      >
                        {InvoiceData.map((row, i) => (
                          <TableRow
                            key={i}
                            scroll={InvoiceData.length > 2 ? true : false}
                            bbtom={true}
                          >
                            <TableBodyCell
                              fontFamily="'DM Sans', sans-serif"
                              fontWeight="500"
                              lineHeight="13.02px"
                              fontSize="10px"
                              color=" #747575"
                              padding="6px 8px 6px 16px"
                              bodered
                            >
                              {row.date
                                ? moment(row.date).format("MM/DD/YYYY hh:mm A")
                                : ""}
                            </TableBodyCell>
                            <TableBodyCell
                              fontFamily="'DM Sans', sans-serif"
                              fontWeight="500"
                              lineHeight="13.02px"
                              fontSize="10px"
                              color=" #747575"
                              padding="6px 8px 6px 8px"
                              bodered
                            >
                              {row.serviceName}
                            </TableBodyCell>

                            <TableBodyCell
                              fontFamily="'DM Sans', sans-serif"
                              fontWeight="500"
                              lineHeight="13.02px"
                              fontSize="10px"
                              color=" #747575"
                              bodered
                              padding={
                                InvoiceData.length > 2
                                  ? "6px 8px 6px 16px"
                                  : "6px 16px 6px 16px"
                              }
                            >
                              {symbol}
                              {row.fee}
                            </TableBodyCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Div
                      display="flex"
                      justifyContent="flex-end"
                      padding="10px 20px 10px 20px"
                    >
                      <SpanCont
                        lineHeight="13.02px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily="'DM Sans', sans-serif"
                        color="#747575"
                        width="70%"
                        textAlign="right"
                      >
                        Total:
                      </SpanCont>
                      <SpanCont
                        lineHeight="15.62px"
                        fontSize="12px"
                        fontWeight="400"
                        fontFamily="'DM Sans', sans-serif"
                        color=" #3E3F40"
                        width="30%"
                        textAlign="right"
                      >
                        {symbol}
                        {props.Data.total_amount}
                      </SpanCont>
                    </Div>
                    <Div
                      display="flex"
                      justifyContent="flex-end"
                      padding="0px 20px 16px 20px"
                      // margin="0px 40px 20px 20px"
                    >
                      <SpanCont
                        fontWeight="400"
                        fontSize="10px"
                        lineHeight="13.02px"
                        fontFamily="'DM Sans', sans-serif"
                        color="#747575"
                        width="70%"
                        textAlign="right"
                      >
                        Make Payments to:
                      </SpanCont>
                      <SpanCont
                        fontWeight="500"
                        fontSize="10px"
                        lineHeight="13.02px"
                        fontFamily="'DM Sans', sans-serif"
                        color="#747575"
                        width="30%"
                        textAlign="right"
                      >
                        {props.Data.facilityname}
                      </SpanCont>
                    </Div>
                  </Div>
                </ModalBody>
              </ModalDiag>
            )}
          </ModalContainer>
        </Modal>
      )}

      {MakePay && (
        <MakePayment
          show={MakePay}
          ModalHide={() => setMakePay(false)}
          makepayment="no"
          InvoiceList={OneInvoiceList}
          defaultcardcheck={props.defaultcardcheck}
          CardDetails={cardDetails}
          patient_balance={props.Data.total_balance}
        />
      )}
      {ModalAlerShow && (
        <StripeAlert show={ModalAlerShow} handleModalClick={handleModalClick} />
      )}
    </>
  );
};

export default InvoicePopup;
