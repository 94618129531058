import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { GetList } from "../../StateManagement/Reducers/HealthProfileState";

import TopNav from "../TopNav";
import Lists from "./Lists";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";
import {
  SubHeading,
  DropDown,
  DropDownListContainer,
  DropDownList,
  ListItem,
  Div,
} from "./styles";

import { AiOutlineDown } from "react-icons/ai";

const HealthProfile = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const inputNotRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [dropDown, setDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    state
      ? state.type.type === "medication"
        ? "Medication"
        : state.type.type === "medical_problem"
        ? "Conditions"
        : "Lab Orders"
      : "Conditions"
  );
  const [type, setType] = useState(
    state
      ? state.type.type === "medication"
        ? "medication"
        : state.type.type === "medical_problem"
        ? "medical_problem"
        : "lab"
      : "medical_problem"
  );

  let accountType =
    localStorage.getItem("account_type") &&
    Decrypt_Value(localStorage.getItem("account_type"), "vozoportal");

  const Items =
    accountType === -1
      ? ["Conditions", "Medication"]
      : ["Conditions", "Lab Orders", "Medication"];

  const LoadingRes = useSelector((state) => state.HealthProfile.HealthLoading);

  const Toggling = () => setDropDown((prevState) => !prevState);

  const ClickedItem = (item) => () => {
    setSelectedItem(item);
    setDropDown((prevState) => !prevState);

    if (item === "Conditions") {
      setType("medical_problem");
    } else if (item === "Medication") {
      setType("medication");
    } else {
      setType("lab");
    }
  };

  const handleClick1 = (e) => {
    if (inputNotRef.current && !inputNotRef.current.contains(e.target)) {
      setDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mouseup", handleClick1);

    return () => {
      document.addEventListener("mouseup", handleClick1);
    };
  }, []);

  useEffect(() => {
    dispatch(GetList(type));
  }, [type]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <TopNav title="Health Profile" />
      {LoadingRes ? (
        <Loading />
      ) : (
        <BodyContainer
          marginTop={open ? "0px" : "79px"}
          className="mobHealthMarginRight"
          paddingLeft="40px"
          paddingTop="12px"
        >
          <SubHeading>Health Profile List</SubHeading>
          <DropDown className="Healthprofiledropdown" ref={inputNotRef}>
            <Div onClick={Toggling}>
              {`${selectedItem} List` || "Conditions List"}
              <AiOutlineDown />
            </Div>

            {dropDown && (
              <DropDownListContainer>
                <DropDownList>
                  {Items.map((Item, ind) => (
                    <ListItem
                      onClick={ClickedItem(Item)}
                      key={ind}
                      id={`hltpr_li-hpl${ind}`}
                    >
                      {Item}
                    </ListItem>
                  ))}
                </DropDownList>
              </DropDownListContainer>
            )}
          </DropDown>

          <Lists list={selectedItem} />
        </BodyContainer>
      )}
    </>
  );
};

export default HealthProfile;
