import React, { useEffect, useState } from "react";
import { Div } from "./Styles";
import { Container } from "./StyledComponents";
import LoginRight from "./LoginRight";
import { useSelector } from "react-redux";
import patient_portal_front from "../../Assets/images/patient_portal_front.png";
import { ImageContainer, Image } from "./StyledComponents";
import vozoLogo from "../../Assets/images/Logo.png";
import getFilePath from "../../Config/path";

const Login = () => {
  const [open, setOpen] = useState(false);
  const File_URL = getFilePath();

  const text = useSelector((state) => state.PreLogin.logo);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "100vh" : "170vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          <Div width="50%" bg_color="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" bg_color="#FFFFFF">
            <LoginRight />
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41px"
                width="173px"
                objectFit="contain"
              />
            </ImageContainer>
          )}
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div bg_color="#FFFFFF">
            <LoginRight open={open} />
          </Div>
        </Div>
      )}
    </Container>
  );
};

export default Login;
