import styled from "styled-components";
import { NavLink } from "react-router-dom";
import vozoicon from "../../Assets/images/Vozo_Icon.png";

export const LogoContainer = styled.div`
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "26px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "26px")};
  display: flex;
`;

export const Vozoiconpic = styled.img.attrs({
  src: vozoicon,
  alt: "image not found",
})`
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "auto")};
  margin-right: auto;
  width: 174px;
  height: 30px;
  &.topnavicon {
    @media screen and (min-width: 100px) and (max-width: 800px) {
      width: 120px;
      height: 19px;
    }
  }
`;

export const Nav = styled.nav`
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "21px")};
  padding: ${(p) => (p.padding ? p.padding : "0px 12px 0px 12px")};
`;

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  display: flex;
  align-itme: center;
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  padding: ${(p) => (p.padding ? p.padding : "14px 0px 14px 15px !important")};
  &.active {
    background: #c3d7db77;
  }
`;

export const Navicon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  color: ${(p) => p.color && p.color};
`;

export const Hrline = styled.hr`
  border-top: 1px solid #eeeeef;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: none;
`;

export const Ul = styled.ul``;

export const Li = styled.li`
  padding: ${(p) => p.padding && p.padding};
`;
