import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetPassRes } from "../../../StateManagement/Reducers/PreLoginState";
import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
} from "../../StyledComponents";
import {
  Title,
  Container,
  Input,
  TitleImage,
  ImageContainer,
} from "../StyledComponents";
import getFilePath from "../../../Config/path";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import vozoLogo from "../../../Assets/images/Logo.png";
import password_icon from "../../../Assets/images/password_setup_icon.png";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";

const SetupPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [btndis, setbtndis] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [open, setOpen] = useState(false);

  const File_URL = getFilePath();

  const text = useSelector((state) => state.PreLogin.logo);
  const [email] = useState(state ? state.type.email : "");
  const passRes = useSelector((state) => state.PreLogin.passWordRes);

  const Password = (e) => {
    setMissMatch(false);
    setPwd1(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const ConformPassword = (e) => {
    setMissMatch(false);
    setPwd2(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
    } else {
      setMissMatch(true);
      setErrMsg(
        "passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const handleSave = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();

    if (pwd1 === "") {
      setPassErr(true);
      setErrMsg("Please enter the password");
    }

    if (pwd2 === "") {
      setMissMatch(true);
      setErrMsg("Please enter the password");
    }

    if (passErr || misMatch) return false;

    if (pwd1 === pwd2) {
      dispatch(
        GetPassRes({
          type: { new_password: pwd1, con_password: pwd2, email: email },
        })
      );
    } else {
      setMissMatch(true);
      setErrMsg("passWord Mismatched");
    }
  };

  useEffect(() => {
    if (passRes === "Success") {
      Navigate("/Login");
    } else if (passRes === "Failure") {
      setMissMatch(true);
      setErrMsg("PassWord saving failed");
    }
  }, [passRes]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "100vh" : "250vh"}
      minWidth={open ? "1024px" : "100%"}
    >
      {open ? (
        <>
          <Div width="50%" backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" backColor="#FFFFFF" overflowY="auto">
            <Div width="500px" textAlign="center" margin="0px auto">
              {text.length !== 0 && (
                <ImageContainer marginTop="72px" marginBottom="65px">
                  <Image
                    loading="lazy"
                    src={text !== "failed" ? File_URL + text : vozoLogo}
                    height="41px"
                    width="173px"
                  />
                </ImageContainer>
              )}
              <TitleImage
                src={password_icon}
                alt="Setup password"
                width="124px"
                height="124px"
                marginTop="10%"
              />
              <Title
                fontFamily="'DM Sans', sans-serif"
                color="#000000"
                fontWeight="700"
                fontSize="36px"
                lineHeight="44px"
                marginBottom="12px"
              >
                Set Your Password
              </Title>
              <Description
                fontFamily="'DM Sans', sans-serif"
                alignItems="center"
                color=" #667085"
                marginBottom="32px"
                fontSize="20px"
                lineHeight="27px"
              >
                Use the email your practitioner has on file to sign in—no
                password necessary.
              </Description>
              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  Appointment
                  cursor="not-allowed"
                  background="#F9F8F8"
                  height="48px"
                  color="#667085"
                >
                  <FaRegUser style={{ marginRight: 8 }} /> {email}
                </Button>
              </FormGroup>
              <Form>
                <FormGroup marginBottom="19px" display="grid" width="100%">
                  <FormLabel
                    marginBottom="6px"
                    textAlign="left"
                    color="#151515"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="1px"
                    fontFamily="'DM Sans', sans-serif"
                  >
                    Password
                  </FormLabel>
                  <Input
                    borderRadius="100px"
                    placeholder="Enter password"
                    autoComplete="off"
                    type={PasswordShow ? "password" : "text"}
                    value={pwd1}
                    onChange={(e) => Password(e)}
                  />
                  {PasswordShow ? (
                    <FaRegEye
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "43px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "43px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                    />
                  )}
                  {passErr && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      fontFamily="inter"
                      lineHeight="24px"
                      marginTop="5px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>
                <FormGroup marginBottom="19px" display="grid" width="100%">
                  <FormLabel
                    marginBottom="6px"
                    textAlign="left"
                    color="#151515"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="1px"
                    fontFamily="'DM Sans', sans-serif"
                  >
                    Confirm Password
                  </FormLabel>
                  <Input
                    borderRadius="100px"
                    placeholder="Re-enter password"
                    autoComplete="off"
                    type={PasswordShow1 ? "password" : "text"}
                    value={pwd2}
                    onChange={(e) => ConformPassword(e)}
                  />
                  {PasswordShow1 ? (
                    <FaRegEye
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "43px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() =>
                        setPasswordShow1((Prevstate) => !Prevstate)
                      }
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "43px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() =>
                        setPasswordShow1((Prevstate) => !Prevstate)
                      }
                    />
                  )}
                  {misMatch && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      fontFamily="inter"
                      lineHeight="24px"
                      marginTop="5px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>

                <FormGroup marginBottom="32px" display="grid" width="100%">
                  <Button
                    fontFamily="'DM Sans', sans-serif"
                    borderRadius="100px"
                    background="#245C66"
                    hoverBackColor="#16424A"
                    height="48px"
                    disabled={btndis}
                    onClick={(e) => handleSave(e)}
                  >
                    Save password
                  </Button>
                </FormGroup>
              </Form>
            </Div>
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41px"
                width="173px"
                objectFit="contain"
              />
            </ImageContainer>
          )}
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>{" "}
          <Div width="100%" backColor="#FFFFFF" overflowY="auto">
            <Div width="96%" textAlign="center" margin="0px auto">
              <TitleImage
                src={password_icon}
                alt="Setup password"
                width="80px"
                height="100px"
                marginTop="3%"
                marginBottom="2px"
              />
              <Title
                color="#000000"
                fontFamily="'DM Sans', sans-serif"
                fontWeight="700"
                fontSize="24px"
                lineHeight="44px"
                marginBottom="5px"
                padding={open ? "" : "0 10px 0 15px"}
              >
                Set Your Password
              </Title>
              <Description
                alignItems="center"
                fontFamily="'DM Sans', sans-serif"
                color=" #667085"
                marginBottom={open ? "32px" : "16px"}
                fontSize={open ? "20px" : "14px"}
                lineHeight="27px"
                padding={open ? "" : "0 20px 0 20px"}
              >
                Use the email your practitioner has on file to sign in—no
                password necessary.
              </Description>
              <FormGroup
                marginBottom={open ? "32px" : "16px"}
                display="grid"
                width="100%"
                padding={open ? "" : "0 10px 0 15px"}
              >
                <Button
                  Appointment
                  cursor="not-allowed"
                  background="#F9F8F8"
                  height="40px"
                  color="#667085"
                  borderRadius="100px"
                >
                  <FaRegUser style={{ marginRight: 8 }} /> {email}
                </Button>
              </FormGroup>
              <Form
                padding={open ? "" : "0 10px 0 15px"}
                fontFamily="'DM Sans', sans-serif"
              >
                <FormGroup marginBottom="19px" display="grid" width="100%">
                  <FormLabel
                    marginBottom="4px"
                    textAlign="left"
                    color={open ? "#7D8592" : "#151515"}
                    fontWeight={open ? "600" : "400"}
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="1px"
                    fontFamily="'DM Sans', sans-serif"
                  >
                    Password
                  </FormLabel>
                  <Input
                    height="40px"
                    borderRadius="100px"
                    placeholder="Enter password"
                    autoComplete="off"
                    type={PasswordShow ? "password" : "text"}
                    value={pwd1}
                    onChange={(e) => Password(e)}
                  />
                  {PasswordShow ? (
                    <FaRegEye
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "40px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "40px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                    />
                  )}
                  {passErr && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      fontFamily="inter"
                      lineHeight="24px"
                      marginTop="5px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>
                <FormGroup marginBottom="19px" display="grid" width="100%">
                  <FormLabel
                    marginBottom="4px"
                    textAlign="left"
                    color={open ? "#7D8592" : "#151515"}
                    fontWeight={open ? "600" : "400"}
                    fontSize="14px"
                    lineHeight="24px"
                    letterSpacing="1px"
                    fontFamily="'DM Sans', sans-serif"
                  >
                    Confirm Password
                  </FormLabel>
                  <Input
                    height="40px"
                    borderRadius="100px"
                    placeholder="Re-enter password"
                    autoComplete="off"
                    type={PasswordShow1 ? "password" : "text"}
                    value={pwd2}
                    onChange={(e) => ConformPassword(e)}
                  />
                  {PasswordShow1 ? (
                    <FaRegEye
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "40px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() =>
                        setPasswordShow1((Prevstate) => !Prevstate)
                      }
                    />
                  ) : (
                    <FaRegEyeSlash
                      style={{
                        position: "absolute",
                        right: 15,
                        top: "40px",
                        cursor: "pointer",
                        color: "#7d8592",
                        fontSize: 20,
                      }}
                      onClick={() =>
                        setPasswordShow1((Prevstate) => !Prevstate)
                      }
                    />
                  )}
                  {misMatch && (
                    <FormLabel
                      marginBottom="0px"
                      textAlign="left"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      fontFamily="inter"
                      lineHeight="24px"
                      marginTop="5px"
                    >
                      {errMsg}
                    </FormLabel>
                  )}
                </FormGroup>

                <FormGroup marginBottom="32px" display="grid" width="100%">
                  <Button
                    fontFamily="'DM Sans', sans-serif"
                    borderRadius={open ? "8px" : "100px"}
                    background="#245C66"
                    hoverBackColor="#16424A"
                    // className="save_active"
                    fontSize={open ? "18px" : "14px"}
                    height="40px"
                    disabled={btndis}
                    onClick={(e) => handleSave(e)}
                  >
                    Save password
                  </Button>
                </FormGroup>
              </Form>
            </Div>
          </Div>
        </Div>
      )}
    </Container>
  );
};

export default SetupPassword;
