import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { BsXLg } from "react-icons/bs";

import { Navicon, StyledLink, Nav, Ul, Li } from "./Styles";
import { Div } from "../StyledComponents";

const LeftMobNav = ({ setNavopen }) => {
  const { pathname } = useLocation();
  const navRef = useRef(null);
  const handleNavclose = () => {
    setNavopen(false);
  };
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavopen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Nav ref={navRef} marginTop="16px" padding="0px 0px 0px 0px">
        <Ul>
          <Li padding="0px 0px 10px 0px">
            <Div onClick={handleNavclose}>
              <BsXLg
                style={{
                  color: "#718096",
                  cursor: "pointer",
                  height: "24px",
                  width: "18px",
                  marginLeft: "16px",
                }}
                onClick={() => handleNavclose()}
                id="bil_inv-lst_cls"
              />
            </Div>
          </Li>
          <Li padding="0px 0px 10px 0px">
            {pathname === "/dashboard" ? (
              <StyledLink
                to="/dashboard"
                exact="true"
                onClick={handleNavclose}
                fontSize="25px"
                padding="8px 0px 8px 0px !important"
              >
                <Navicon color="#245C66" marginLeft="16px" id="dashboard_img" />
                <Navicon color="#245C66">Dashboard</Navicon>
              </StyledLink>
            ) : (
              <StyledLink
                to="/"
                exact="true"
                onClick={handleNavclose}
                fontSize="14px"
                padding="8px 0px 8px 0px !important"
              >
                <Navicon color="#245C66" marginLeft="16px" id="dashboard_img" />
                <Navicon color="#245C66" id="nav_lnk-spn_dbrd">
                  Dashboard
                </Navicon>
              </StyledLink>
            )}
          </Li>
          <Li padding="0px 0px 10px 0px">
            <StyledLink
              to="/appointment/past_appointment"
              className={pathname.includes("/appointment/") && "active"}
              exact="true"
              onClick={handleNavclose}
              fontSize="14px"
              padding="8px 0px 8px 0px !important"
            >
              <Navicon color="#245C66" marginLeft="16px" id="appointment_img" />
              <Navicon color="#245C66">Appointment</Navicon>
            </StyledLink>
          </Li>
          <Li padding="0px 0px 10px 0px">
            <StyledLink
              to="/billing"
              exact="true"
              onClick={handleNavclose}
              fontSize="14px"
              padding="8px 0px 8px 0px !important"
            >
              <Navicon color="#245C66" marginLeft="16px" id="billing_img" />
              <Navicon color="#245C66" id="nav_lnk-spn_bil">
                Billing
              </Navicon>
            </StyledLink>
          </Li>

          <Li padding="0px 0px 10px 0px">
            <StyledLink
              to="/documents"
              exact="true"
              onClick={handleNavclose}
              fontSize="14px"
              padding="8px 0px 8px 0px !important"
            >
              <Navicon color="#245C66" marginLeft="16px" id="document_img" />
              <Navicon color="#245C66">Documents</Navicon>
            </StyledLink>
          </Li>
          <Li padding="0px 0px 10px 0px">
            <StyledLink
              to="/HealthProfile"
              exact="true"
              onClick={handleNavclose}
              fontSize="14px"
              padding="8px 0px 8px 0px !important"
            >
              <Navicon
                color="#245C66"
                marginLeft="16px"
                id="healthprofile_img"
              />
              <Navicon color="#245C66" id="nav_lnk-spn_hltpr">
                HealthProfile
              </Navicon>
            </StyledLink>
          </Li>

          <Li padding="0px 0px 10px 0px">
            <StyledLink
              to="/settings"
              exact="true"
              onClick={handleNavclose}
              fontSize="14px"
              padding="8px 0px 8px 0px !important"
            >
              <Navicon color="#245C66" marginLeft="16px" id="settings_img" />
              <Navicon color="#245C66">Settings</Navicon>
            </StyledLink>
          </Li>
        </Ul>
      </Nav>
    </>
  );
};

export default LeftMobNav;
