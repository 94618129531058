import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
// import {
//   GetReEmailRes,
//   setReEmailRes,
// } from "../../../StateManagement/Reducers/PreLoginState";

import checkEmail from "../../../Assets/images/email_check_icon.png";

import {
  Button,
  Span,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
  Description,
} from "../../StyledComponents";
import getFilePath from "../../../Config/path";

import {
  Container,
  Input,
  TitleImage,
  Title,
  ImageContainer,
  ResendButton,
} from "../StyledComponents";
import { FormGroupInline } from "./Styles";
import {
  GetEmailRes,
  setEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";
import { LogoContainer, Vozoiconpic } from "../../LeftNav/Styles";
import patient_portal_front from "../../../Assets/images/patient_portal_front.png";
import vozoLogo from "../../../Assets/images/Logo.png";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";

const InstantLink = () => {
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [btndis, setbtndis] = useState(false);
  const [email] = useState(state ? state.type.email : "");
  const [otpErr, setOtpErr] = useState(false);
  const [Otp, setOtp] = useState(new Array(4).fill(""));
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalStatus, setModalStatus] = useState("");

  const File_URL = getFilePath();

  const otp =
    localStorage.getItem("OTP") &&
    Decrypt_Value(localStorage.getItem("OTP"), "vozoportal");

  let mode =
    localStorage.getItem("Mode") &&
    Decrypt_Value(localStorage.getItem("Mode"), "vozoportal");
  const text = useSelector((state) => state.PreLogin.logo);

  const handleVerify = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();

    let isEmptyObj = Object.keys(mode).length;
    if (isEmptyObj === 0) {
      mode = "old";
    }

    if (parseInt(otp) === parseInt(Otp.join(""))) {
      if (mode === "old") {
        Navigate("/SetupPassword", { state: { type: { email } } });
      } else if (mode === "new") {
        Navigate("/SignUp", { state: { type: { email } } });
      }
      localStorage.removeItem("OTP");
    } else {
      setOtpErr(true);
      // localStorage.removeItem("OTP");
    }
  };

  const handleOtp = (e, i) => {
    setOtpErr(false);
    if (isNaN(e.value)) return;
    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };

  const ResendOtp = () => {
    dispatch(GetEmailRes({ type: { email: email }, mode: mode }));
    setModalMessage("Verification code has been resent to your email.");
    setModalStatus("success");
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  useEffect(() => {
    dispatch(setEmailRes({ status: "", output: "" }));
  }, []);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };
    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <Container
      Flex={open ? "Flex" : ""}
      height={open ? "100vh" : "170vh"}
      minWidth={open ? "1024px" : "350px"}
    >
      {open ? (
        <>
          <Div width="50%" bg_color="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div width="50%" bg_color="#FFFFFF">
            <Div width="100%">
              <Div width="500px" textAlign="center" margin="auto">
                {text.length !== 0 && (
                  <ImageContainer marginTop="72px" marginBottom="20px">
                    <Image
                      loading="lazy"
                      src={text !== "failed" ? File_URL + text : vozoLogo}
                      height="41px"
                      width="173px"
                    />
                  </ImageContainer>
                )}
                <TitleImage
                  src={checkEmail}
                  alt="Email Verified"
                  width="124px"
                  height="124px"
                  marginBottom="35px"
                  marginTop="10%"
                ></TitleImage>
                <Title
                  color="#000000"
                  fontWeight="800"
                  fontSize="36px"
                  lineHeight="44px"
                  marginBottom="8px"
                >
                  Verify your Account
                </Title>
                <Description
                  color=" #667085"
                  alignItems="center"
                  fontSize="20px"
                  lineHeight="30px"
                  marginBottom="40px"
                >
                  Check you email, we just sent you a verification code to
                  &nbsp;
                  <Span color="#1F28EB" fontWeight="600">
                    {email}
                  </Span>
                </Description>
                <Form>
                  <FormGroupInline>
                    {Otp.map((row, i) => {
                      return (
                        <FormGroup
                          marginBottom="24px"
                          display="inline-grid"
                          width="25%"
                          key={i}
                        >
                          <Input
                            key={i}
                            width="80px"
                            height="80px"
                            fontSize="36px"
                            lineHeight="44px"
                            fontWeight="700"
                            fontFamily="'Urbanist'"
                            maxLength="1"
                            textAlign="center"
                            maxlength="1"
                            value={row}
                            onChange={(e) => handleOtp(e.target, i)}
                            onFocus={(e) => e.target.select()}
                            onKeyUp={(e) => KeyDown(e, i)}
                            onPaste={(e) => FieldPaste(e, i)}
                          />
                        </FormGroup>
                      );
                    })}
                    {otpErr && (
                      <FormGroup
                        marginBottom="24px"
                        display="inline-grid"
                        width="100%"
                        gridColumn="1/5"
                      >
                        <FormLabel
                          marginBottom="6px"
                          textAlign="center"
                          fontSize="14px"
                          color="red"
                          fontWeight="400"
                          lineHeight="24px"
                          fontFamily="inter"
                        >
                          otp is inValid
                        </FormLabel>
                      </FormGroup>
                    )}
                  </FormGroupInline>
                  <FormGroup marginBottom="32px" display="grid" width="100%">
                    <Button
                      borderRadius="100px"
                      background="#245C66"
                      hoverBackColor="#16424A"
                      padding="18px 140px 18px 140px"
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="22px"
                      letterSpacing="1.1px"
                      disabled={btndis}
                      onClick={(e) => handleVerify(e)}
                    >
                      Verify Account
                    </Button>
                  </FormGroup>
                </Form>
                <FormGroup
                  textAlign="center"
                  marginBottom="63px"
                  display="inline-block"
                >
                  Don’t receive verification code?
                  <ResendButton onClick={() => ResendOtp()}>
                    Resend Code
                  </ResendButton>
                </FormGroup>
              </Div>
            </Div>
          </Div>
        </>
      ) : (
        <Div marginTop="10%">
          {text.length !== 0 && (
            <ImageContainer marginTop="72px" marginBottom="20px">
              <Image
                loading="lazy"
                src={text !== "failed" ? File_URL + text : vozoLogo}
                height="41px"
                width="173px"
                objectFit="contain"
              />
            </ImageContainer>
          )}
          <Div backColor="#F8FAFB" position="relative">
            <img
              src={patient_portal_front}
              alt="menu-icon"
              top="20px"
              left="20px"
              height="100%"
              width="100%"
            ></img>
          </Div>
          <Div bg_color="#FFFFFF">
            <Div width="100%">
              <Div
                resWidth="100%"
                respadding="0 20px 0 20px"
                textAlign="center"
                margin="auto"
              >
                <TitleImage
                  src={checkEmail}
                  alt="Email Verified"
                  width="100px"
                  height="80px"
                  marginBottom="14px"
                  marginTop="3%"
                ></TitleImage>
                <Title
                  color="#000000"
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="44px"
                  marginBottom="8px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Verify your Account
                </Title>
                <Description
                  color=" #667085"
                  alignItems="center"
                  fontSize="14px"
                  lineHeight="30px"
                  marginBottom="16px"
                  fontFamily="'DM Sans', sans-serif"
                >
                  Check you email, we just sent you a verification code to
                  &nbsp;
                  <Span color="#1F28EB" fontWeight="600">
                    {email}
                  </Span>
                </Description>
                <Form>
                  <FormGroupInline>
                    {Otp.map((row, i) => {
                      return (
                        <FormGroup
                          marginBottom="24px"
                          display="inline-grid"
                          width="25%"
                          key={i}
                        >
                          <Input
                            key={i}
                            width="64px"
                            height="64px"
                            fontSize="36px"
                            lineHeight="44px"
                            fontWeight="700"
                            fontFamily="'Urbanist'"
                            maxLength="1"
                            textAlign="center"
                            maxlength="1"
                            value={row}
                            onChange={(e) => handleOtp(e.target, i)}
                            onFocus={(e) => e.target.select()}
                            onKeyUp={(e) => KeyDown(e, i)}
                            onPaste={(e) => FieldPaste(e, i)}
                          />
                        </FormGroup>
                      );
                    })}
                    {otpErr && (
                      <FormGroup
                        marginBottom="24px"
                        display="inline-grid"
                        width="100%"
                        gridColumn="1/5"
                      >
                        <FormLabel
                          marginBottom="6px"
                          textAlign="center"
                          fontSize="14px"
                          color="red"
                          fontWeight="400"
                          lineHeight="24px"
                          fontFamily="inter"
                        >
                          otp is inValid
                        </FormLabel>
                      </FormGroup>
                    )}
                  </FormGroupInline>
                  <FormGroup marginBottom="16px" display="grid" width="100%">
                    <Button
                      borderRadius="100px"
                      background="#245C66"
                      hoverBackColor="#16424A"
                      padding="9px 80px 9px 80px"
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="22px"
                      letterSpacing="1.1px"
                      disabled={btndis}
                      onClick={(e) => handleVerify(e)}
                      fontFamily="'DM Sans', sans-serif"
                    >
                      Verify Account
                    </Button>
                  </FormGroup>
                </Form>
                <FormGroup
                  textAlign="center"
                  marginBottom="63px"
                  display="inline-block"
                  fontSize="14px"
                >
                  Don’t receive verification code?
                  <ResendButton fontSize="14px" onClick={() => ResendOtp()}>
                    Resend Code
                  </ResendButton>
                </FormGroup>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
      <AlertModal show={showModal} status={modalStatus} msg={modalMessage} />
    </Container>
  );
};

export default InstantLink;
