import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  GetPatGenderDetails,
  GetPatMaritalStatus,
  GetPatLangDetails,
  GetPatRaceDetails,
  GetPatientState,
} from "../../StateManagement/Reducers/SettingState";

import TopNav from "../TopNav";
import EditProfile from "./EditProfile";
import Loading from "../StyledComponents/Loading";

import { BodyContainer } from "../StyledComponents";

const Settings = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const Refresh = useSelector((state) => state.Setting.Refresh);
  const ProfileLoading = useSelector((state) => state.Setting.Loading);
  const TopLoading = useSelector((state) => state.TopNavbar.UserLoading);

  useEffect(() => {
    dispatch(GetPatGenderDetails());
    dispatch(GetPatMaritalStatus());
    dispatch(GetPatLangDetails());
    dispatch(GetPatRaceDetails());
    dispatch(GetPatientState());
  }, [dispatch, Refresh]);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 800px)");
    const handleMediaChange = () => {
      setOpen(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleMediaChange);
    handleMediaChange();
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);
  return (
    <>
      <TopNav title="Edit Profile" />
      {TopLoading && ProfileLoading ? (
        <Loading />
      ) : (
        <BodyContainer
          marginTop={open ? "0px" : "64px"}
          paddingLeft="40px"
          paddingTop="13px"
          className="editpro_body_responsive"
        >
          <EditProfile />
        </BodyContainer>
      )}
    </>
  );
};

export default Settings;
